import React, { useEffect, useState } from "react";
import { Alert, Button, Col, FloatingLabel, Form, Row, Tab, Tabs ,Spinner} from "react-bootstrap";
import { BsCheck, BsTrashFill } from "react-icons/bs";
import Wizard from "../../Components/Wizard";
import './Forms.scss';
import '../vs-mobil/Forms.scss';
// import axios from "axios";
import { api } from '../../index';
  
function Immatriculation() {

    const [PD, setPD] = useState(<div> <span class="text-1"> Télécharger </span><span class="text-2"> votre Pièce d'identité</span> </div>);
    const [PieceIdentite, setPieceIdentite] = useState();
    const [deletepd, setdeletepd] = useState(false);
    const [validatedImma, setvalidatedImma] = useState(false);


    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [file4, setFile4] = useState(null);
    const [file5, setFile5] = useState(null);

    const [NationalityList, setNationalityList] = useState([]);
    const [FunctionList, setFunctionList] = useState([]);
    const [BirthPlaceList, setBirthPlaceList] = useState([]);
    const [DocumentTypeList , setDocumentTypeList ] = useState([]);
    const [ListRegimeId , setListRegimeId ] = useState([]);
    const [Villes , setVilles ] = useState([]);
    const [Address, setAddress] = useState("");
    const [BirthPlaceId, setBirthPlaceId] = useState("");
    const [RequestReference, setRequestReference] = useState("");
    const [Nationality, setNationality] = useState("");
    const [DocumentNumber, setDocumentNumber] = useState("");
    const [Salary, setSalary] = useState("");
    const [HiringDate, setHiringDate] = useState("");
    const [BirthDate, setBirthDate] = useState("");
    const [CountryRegionId, setCountryRegionId] = useState("");
    const [MotherName, setMotherName] = useState("");
    const [Email, setEmail] = useState("");
    const [Name, setName] = useState("");
    const [DocumentTypeId, setDocumentTypeId] = useState("");
    const [City, setCity] = useState("");
    const [PersonMaritalStatus, setPersonMaritalStatus] = useState("");
    const [Gender, setGender] = useState("");
    const [RegimeId,setRegimeId]=useState("");
    const [EmployeeExternalNum, setEmployeeExternalNum] = useState("");
    const [Function, setFunction] = useState("");
    const [Phone, setPhone] = useState("");
    const [success, setsuccess] = useState(false);
    const [Message, setMessage] = useState("");
    const [Errors, setErrors] = useState([]);
    const [AlertError, setAlertError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const items = JSON.parse(localStorage.getItem('data'));
    useEffect(() => {

        api.get('nationalities').then(async (response) => {
            setNationalityList(response.data.data);
        });
        api.get('positionTypes').then(async (response) => {
            setFunctionList(response.data.data);
        });
        api.get('birthPlaces').then(async (response) => {
            setBirthPlaceList(response.data.data);
        });
        api.get('documentTypes').then(async (response) => {
            setDocumentTypeList(response.data.data);
        });
        api.get('addressCities').then(async (response) => {
            setVilles(response.data.data);
        });
        api.get('regimes').then(async (response) => {
            setListRegimeId(response.data.data);
        });

    }, []);
    //----File input 

    const handleFile1Change = (event) => {
        setFile1(event.target.files[0]);
        
    };
    const handleFile2Change = (event) => {
        setFile2(event.target.files[0]);
        
    };
    const handleFile3Change = (event) => {
        setFile3(event.target.files[0]);
        
    };
    const handleFile4Change = (event) => {
        setFile4(event.target.files[0]);
        
    };
    const handleFile5Change = (event) => {
        setFile5(event.target.files[0]);
        
    };
   //piece d identite 
        const piece_identite = (event) =>{
            let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => {
                    setPieceIdentite(e.target.result)
                };
                setPD(event.target.files[0]?event.target.files[0].name :PD)
                setdeletepd(event.target.files[0]?true :false);
        }
        const file_pd = () => {
                document.getElementById("file_pd").click() 
        };
        const deletePD = () => {
                setPD(<div> <span class="text-1"> Télécharger </span><span class="text-2"> votre Pièce d'identité</span> </div>);
                setdeletepd(false);
        };
        const [formData, setFormData] = useState(new FormData());

    
        const immatriculation = (event) => {
            if (isSubmitting) {
            
                return;
              }
              setIsSubmitting(true);

            const form = event.currentTarget;
                if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                }
                setvalidatedImma(true);
               
                formData.append('CustomerAccount', items.user.customer["CustomerAccount"]);
                formData.append('Address', Address);
                formData.append('BirthPlaceId', BirthPlaceId);
                formData.append('RequestReference', RequestReference);
                formData.append('Nationality', Nationality);
                formData.append('DocumentNumber', DocumentNumber);
                formData.append('Salary', Salary);
                formData.append('HiringDate', HiringDate);
                formData.append('BirthDate', BirthDate);
                formData.append('CountryRegionId', CountryRegionId);
                formData.append('MotherName', MotherName);
                formData.append('Email', Email);
                formData.append('Name', Name);
                formData.append('DocumentTypeId', DocumentTypeId);
                formData.append('City', City);
                formData.append('PersonMaritalStatus', PersonMaritalStatus);
                formData.append('Function', Function);
                formData.append('Phone', Phone);
                formData.append('PieceIdentite', PieceIdentite);
                formData.append('Gender', Gender);
                formData.append('RegimeId', RegimeId);
                formData.append('EmployeeExternalNum', EmployeeExternalNum);
                formData.append("file1", file1);
                formData.append("file2", file2);
                formData.append("file3", file3);
                formData.append("file4", file4);
                formData.append("file5", file5);
                //Debug ----> 
                const formDataObject = {};
                formData.forEach((value, key) => {
                formDataObject[key] = value;
                });

                console.log("FormData Object:");
                console.log(formDataObject);


                //-- write the other appends  please her 
                api.post('requestemployeeregistration/add', formData,{
        headers: {
            'Content-Type': 'multipart/form-data',
        }}).then(async (response) => { 
                    if(response.data.success){
                        setsuccess(true);
                        setIsSubmitting(false);

                    }
                      
                }, (error) => {
                    if(error.response.data.errors) setErrors(error.response.data.errors);
                    if(error.response.data.data) setAlertError(error.response.data.data.error);
                    setIsSubmitting(false);

                });
           
        }
    return (
            <>
  
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 mt-4 Employeur-tabs w-100"
                    >
                        <Tab eventKey="home" title="immatriculation" >
                            <div  className={success?"hide":""}>
                                <Form  className="mt-4" noValidate validated={validatedImma} >
                                    <Alert  variant="danger" className={AlertError ? "" : "hide"}>
                                        {AlertError}
                                    </Alert>
                                    <Row className="form-row">
                                        <h5 className="mb-2 c-primary">Informations personnelles </h5>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Nom" className="uppercase">
                                                    <Form.Control  type="text"   onChange={(e) => setName(e.target.value)} required/>
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Nom de la mère " className="uppercase">
                                                    <Form.Control  type="text"     onChange={(e) => setMotherName(e.target.value)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Date de naissance " className="uppercase">
                                                    <Form.Control  type="date"    onChange={(e) => setBirthDate(e.target.value)}  required />
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Lieu de naissance" className="uppercase">
                                                    <Form.Select aria-label="Default select example" onChange={(e) => setBirthPlaceId(e.target.value)} required >
                                                        <option></option>
                                                        {BirthPlaceList.map((index)=> (
                                                            <option value={index.BirthPlaceId}>{index.Description}</option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Genre" className="uppercase">
                                                    <Form.Select aria-label="Default select example" onChange={(e) => setGender(e.target.value)} required >
                                                        <option></option>
                                                        <option value="Male">Homme</option>
                                                        <option value="Female">Femme</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Nationalité" className="uppercase">
                                                    <Form.Select aria-label="Default select example" onChange={(e) => setNationality(e.target.value)} required >
                                                        <option></option>
                                                        {NationalityList.map((index)=> (
                                                            <option value={index.SSMNationality}>{index.SSMNationality}</option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Situation familiale" className="uppercase">
                                                    <Form.Select aria-label="Default select example" onChange={(e) => setPersonMaritalStatus(e.target.value)} required >
                                                        <option></option>
                                                        <option value="Single">Célibataire</option>
                                                        <option value="Married">Marié(e)</option>
                                                        <option value="Divorced">Divorcé(e)</option>
                                                        <option value="Widowhood">Veuf (ve)</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <h5 className="mb-2 c-primary">Identité</h5>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Type de pièce d’identité " className="uppercase">
                                                    <Form.Select aria-label="Default select example" onChange={(e) => setDocumentTypeId(e.target.value)} required >
                                                        <option></option>
                                                        {DocumentTypeList.map((index)=> (
                                                            <option value={index.DocumentTypeId}>{index.Description}</option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Numéro de la pièce d’identité " className="uppercase">
                                                    <Form.Control  type="text"     onChange={(e) => setDocumentNumber(e.target.value)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <h5 className="mb-2 c-primary">Contact</h5>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Adresse" className="uppercase">
                                                    <Form.Control  type="text"    onChange={(e) => setAddress(e.target.value)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Ville" className="uppercase">
                                                    <Form.Select aria-label="Default select example" onChange={(e) => setCity(e.target.value)}>
                                                        <option></option>
                                                        {Villes.map((index)=> (
                                                            <option value={index.Name}>{index.Description}</option>
                                                        ))}
                                                    </Form.Select>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Téléphone " className="uppercase">
                                                    <Form.Control  type="text"    onChange={(e) => setPhone(e.target.value)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="E-mail" className="uppercase">
                                                    <Form.Control  type="email"    onChange={(e) => setEmail(e.target.value)} />
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <h5 className="mb-2 c-primary">Informations d'embauche</h5>
                                        <Col sm={12} md={6}  className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Matricule externe" className="uppercase">
                                                    <Form.Control  type="text"    onChange={(e) => setEmployeeExternalNum(e.target.value)} />
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                        <Form.Group className="mb-3">
                                            <FloatingLabel  label="Régime" className="uppercase">
                                                <Form.Select onChange={(e) => setRegimeId(e.target.value)} required>
                                                        <option></option>
                                                        {ListRegimeId.map((index)=> (
                                                            <option value={index.RegimeId}>{index.Description}</option>
                                                        ))}  
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                 </Form.Control.Feedback>
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Date d’embauche" className="uppercase">
                                                    <Form.Control  type="date"    onChange={(e) => setHiringDate(e.target.value)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Salaire" className="uppercase">
                                                    <Form.Control  type="text"    onChange={(e) => setSalary(e.target.value)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Fonction" className="uppercase">
                                                    <Form.Select onChange={(e) => setFunction(e.target.value)} required>
                                                            <option></option>
                                                            {FunctionList.map((index)=> (
                                                                <option value={index.PositionTypeId}>{index.Description !=""? index.Description:index.PositionTypeId}</option>
                                                            ))}  
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                                ce champ est obligatoire
                                                    </Form.Control.Feedback>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        {/* <Col sm={12} md={6}>
                                            <div class="wrap file" onClick={file_pd}>
                                                <div class="valign-middle">
                                                    <div class="form-group">
                                                        <label for="file" class="sr-only">
                                                            {PD} <BsTrashFill className={!deletepd?"hide":""} onClick={deletePD}/>
                                                        </label>
                                                        <div class="t"><span class="btn1">
                                                            <div> <span class="text-1"></span><span class="text-2"> votre C.V</span> </div>
                                                            <div> <span class="text-3">Ou telechargez votre C.V depuis votre </span><span class="text-4"> appareil</span></div>
                                                        </span><span class="file-selected"></span>
                                                             <input type="file" name="cvfile" className="cvfiles" id="file_pd" onChange={piece_identite} required/>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        {Errors ? Errors.PieceIdentite  ? 
                                            <div className="required">{Errors.PieceIdentite[0]}</div>
                                        :"":""}
                                       
                                        </Col> */}
                                       
                                        <Col sm={12} md={6}>
                                        <div className="my-dropzone">

                                        <div class="custom-file">
                                        <div> <span class="text-1"> Télécharger </span><span class="text-2"> votre CIN ou pièce d'identité </span>
                                        
                                        
                                        <input type="file" name="file1" onChange={handleFile1Change}  />
                                        {Errors ? Errors.file1 ? 
                                            <div className="required">{Errors.file1[0]}</div>
                                        :"":""}
                                         </div>

                                        </div>
                                        </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                        <div className="my-dropzone">

                                        <div class="custom-file">
                                        <div> <span class="text-1"> Télécharger </span><span class="text-2"> votre Photo  </span> </div>
                                        <input type="file" className="customFileUpload" name="file2" onChange={handleFile2Change}  />
                                        {Errors ? Errors.file2 ? 
                                            <div className="required">{Errors.file2[0]}</div>
                                        :"":""}
                                        </div>
                                        </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                        <div className="my-dropzone">

                                        <div class="custom-file">
                                        <div> <span class="text-1"> Télécharger </span><span class="text-2"> votre Certificat de travail</span> </div>
                                        <input type="file" className="customFileUpload" name="file3" onChange={handleFile3Change} />
                                            
                                        </div>
                                        </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                        <div className="my-dropzone">

                                        <div class="custom-file">
                                        <div> <span class="text-1"> Télécharger </span><span class="text-2"> Bulletin de salaire</span> </div>
                                        <input type="file" className="customFileUpload" name="file5" onChange={handleFile5Change} />

                                        </div>
                                        </div>
                                        </Col>
                                        <Col sm={12} md={12}>
                                        <div className="my-dropzone">

                                        <div class="custom-file">
                                        <div> <span class="text-1"> Télécharger </span><span class="text-2"> Contrat de travail (prestataire de services privés)</span> </div>
                                        <input type="file" className="customFileUpload" name="file4" onChange={handleFile4Change} />

                                        </div>
                                        </div>
                                        </Col>
                                    </Row>    
                                    <Button
        variant="success"
        className="succes mt-4 uppercase xs br-30 align-right mb-4"
        onClick={immatriculation}
        disabled={isSubmitting} // Disable the button during submission
      >
        {isSubmitting ? (
          <>
            <Spinner animation="border" size="sm" role="status" style={{ marginRight: "5px" }} />
            <span>Loading...</span>
          </>
        ) : (
          <>
            <img variant="top" src="./assets/verify-white.svg" style={{ paddingRight: 5 }} /> Valider
          </>
        )}
      </Button>
                                </Form>
                            </div>
                            <div className={success?"right-grid align-center pt-2 mt-4":"hide"}>
                                        <h1 className="subtitle-form sm"> Votre immatriculation est ajouté avec succès.</h1>
                                        <img src="./assets/success.svg" className="mt-4" />
                            </div>
                        </Tab>
                    </Tabs>
            </>
    );
}
export default Immatriculation;