// import axios from "axios";
import { api } from '../index';
import React, { useState } from "react";
import { Alert, Button, Card, Col, FloatingLabel, Form, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Header from "../Components/Header";
import VerticalHeader from "../Components/VerticalHeader";
import HorizontalHeader from "../Components/HorizontalHeader";
import './css/Dashboard.scss';
  
function Configurations() {

  const [passwordCurrentShown, setPasswordCurrentShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmationShown, setPasswordConfirmationShown] = useState(false);
  const [Cureentpassword, setCurentpassword] = useState("");
  const [password, setpassword] = useState("");
  const [passwordConfirm, setpasswordConfirm] = useState("");
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState()
  const [show, setShow] = useState(false);
  
  const back = () =>  {
    
    window.location.href="/configurations"
  }
  const togglePasswordCurrentVisiblity = () => {
    setPasswordCurrentShown(passwordCurrentShown ? false : true);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordConfirmationVisiblity = () => {
    setPasswordConfirmationShown(passwordConfirmationShown ? false : true);
  };

  const updatePassword = (event) => {
    const objErrors = {};
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        setValidated(true);
    
        api.post('change-password', {
            current_password:Cureentpassword,password:password,password_confirmation:passwordConfirm
        }).then(async (response) => {
            if(response.data.success){
                setShow(true)
            }
        }, (error) => {
            setErrors(error.response.data.data.error);
        });

  };
   
  return (
    <>
     <Header/>
        <Card className="mb-4 p-2 br-15 card-content">
            <VerticalHeader />

                    <div className="parent m-2 w-100">
                    <div className="align-right back">
                          <Button variant="light" onClick={back} className="mt-4 uppercase back-btn small align-left">
                              <img src="./assets/back.svg" /> Retour
                          </Button>
                    </div>
                       
                        <h1 className="title-form">Mes Configurations</h1>
                        <span className="subtitle-form sm">Accéder à mon compte, modifier  mot de passe, ajouter de nouveaux utilisateurs. </span>
                        <Form noValidate validated={validated} className="mt-4">
                                <Row className="mt-4">
                                    <span className="uppercase mb-4 f-l c-primary-1 f-w">SÉCURITÉ</span>
                                    {show ? 
                                        <Alert  variant="success" className="mb-2">
                                            Votre mot de passe aura été changé avec succès
                                        </Alert>
                                    :" "}
                                    <Col className="mb-2" sm={6} md={4}> 
                                        <Form.Group  controlId="formBasicPassword">
                                            <FloatingLabel controlId="floatingPassword" label="Ancien mot de passe">
                                                <a className="eye config" onClick={togglePasswordCurrentVisiblity}>{!passwordCurrentShown? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                                <Form.Control  type={passwordCurrentShown ? "text" : "password"}  placeholder="Password" onChange={(e) => setCurentpassword(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid" className="err">
                                                    ce champ est obligatoire
                                                 </Form.Control.Feedback>
                                            </FloatingLabel>        
                                        </Form.Group>
                                    </Col>
                                    <Col className="mb-2"  sm={6} md={4}>
                                        <Form.Group  controlId="formBasicPassword">
                                            <FloatingLabel controlId="floatingPassword" label="Nouveau mot de passe">
                                                <a className="eye config" onClick={togglePasswordVisiblity}>{!passwordShown? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                                <Form.Control  type={passwordShown ? "text" : "password"} placeholder="Password" onChange={(e) => setpassword(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid" className="err">
                                                    ce champ est obligatoire
                                                 </Form.Control.Feedback>
                                            </FloatingLabel>        
                                        </Form.Group>
                                    </Col>
                                    <Col className="mb-2"  sm={6} md={4}>
                                        <Form.Group  controlId="formBasicPassword">
                                            <FloatingLabel controlId="floatingPassword" label="Confirmation du nouveau mot de passe">
                                                <a className="eye config" onClick={togglePasswordConfirmationVisiblity}>{!passwordConfirmationShown? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                                <Form.Control  type={passwordConfirmationShown ? "text" : "password"}  placeholder="Password"  onChange={(e) => setpasswordConfirm(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid" className="err">
                                                    ce champ est obligatoire
                                                 </Form.Control.Feedback>
                                            </FloatingLabel>        
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="success" onClick={updatePassword} className="succes mt-4 uppercase xs align-right">
                                                        Valider <img variant="top" src="./assets/inscription.png" className="pl-5" />
                                </Button> 
                        </Form>
                    </div>
                    <HorizontalHeader/>
            </Card>
    </>
  );
}
export default Configurations;