import React, { useState } from "react";
import { Alert, Button, Col, FloatingLabel, Form, Row, Tab, Tabs } from "react-bootstrap";
import { BsCheck, BsTrashFill } from "react-icons/bs";
import Wizard from "../../Components/Wizard";
import '../Forms/Forms.scss';
import '../vs-mobil/Forms.scss';
// import axios from "axios";
  
function SuspensionView({SuspensionRowSelected}) {
    return (
            <>
  
                    <Tabs
                        defaultActiveKey="suspension"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 mt-4 Employeur-tabs w-100"
                    >
                        <Tab eventKey="suspension" title="suspension">
                            <div >
                                 <Form className="mt-4" >
                                    <Row className="form-row">
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Date d’effet *" className="uppercase">
                                                    <Form.Control value={SuspensionRowSelected.FromDate ?? ""} type="text"  disabled  className="bg-light" />
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Date de réactivation " className="uppercase">
                                                    <Form.Control  type="text" value={SuspensionRowSelected.ToDate ?? ""}   disabled  className="bg-light" />
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Motif" className="uppercase">
                                                    <Form.Control as="textarea" value={SuspensionRowSelected.Description ?? ""}   disabled  className="bg-light textArea-height" />
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                    
                                      
                                        <Col sm={12} md={6} className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Motif du rejet" className="uppercase">
                                                    <Form.Control  as="textarea" value = {SuspensionRowSelected.Notes} disabled  className="bg-light textArea-height"/>                                                   
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Col>
                                        
                                    
                                    </Row> 
                                 </Form> 
                            </div>  
                        </Tab>
                    </Tabs>
            </>
    );
}
export default SuspensionView;