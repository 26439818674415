// import axios from "axios";
import React, { useState } from "react";
import { Alert, Button, Col, Form } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import './Connexion.scss';
import { api } from '../../index';

 
function Forgot({LoginShow}) {

    const [login, setLogin] = useState("");
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState("")
    const [show, setShow] = useState(false);

    const Forgot = (event) => {
        const objErrors = {};
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            }
            setValidated(true);
        
        api.post('forgot-password', {
            login
            }).then(async (response) => {
            if(response.data.success){
                setShow(true)
                //window.location.href = "/";
            }
            }, (error) => {
            //    objErrors.erreur = 'Merci de verifier votre adresse mail';
               setErrors('Merci de verifier votre adresse login');
            });
     
      };
 
  return (
    <>
                    <Col className={show ? "hide m-0" :"m-0"}>
                        <div className="right-grid align-center">
                         <h1 className="title-form mt-4"> Mot de passe / <br/> identifiant oublié</h1>
                                    <p className="subtitle-form sm"> Vos accès vous serons envoyés par mail si votre compte existe .</p>
                            <Form noValidate validated={validated} className="mt-4 pad-form">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <FloatingLabel controlId="floatingPassword" label="LOGIN">
                                         <Form.Control type="text"   onChange={(e) => setLogin(e.target.value)} required />
                                    </FloatingLabel> 
                                    <Form.Control.Feedback type="invalid" className="err">
                                           ce champ est obligatoire
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {errors ? 
                                        <Alert  variant="danger" className="mb-2 err">
                                            {errors}
                                        </Alert>
                                    :" "}
                                <div className="mt-4">
                                    <Button  onClick={LoginShow} className="mt-4 uppercase small align-left back-btn">
                                       <img src="./assets/back.svg" className="pr-2"/> Retour
                                    </Button>
                                    <Button variant="success" onClick={Forgot} className="mt-4 uppercase btn valider xs align-right">
                                        Valider
                                    </Button>
                                </div>
                            </Form>
                         </div>
                    </Col>
                     <Col className={show ? "" :"hide "} style={{marginTop:"15%"}}>
                     <div className="right-grid align-center pt-2">
                         <h1 className="title-form"> Reinitialisation</h1>
                         <p className="subtitle-form sm"> Merci de verifier votre mail.</p>
                         <img src="./assets/success.svg" className="mt-10" />
                     </div>
                     <div className="align-center">
                         <Button  className="valider mt-4 uppercase btn  xs" href="/">
                                         Continuer <img src="./assets/verified.svg" />
                         </Button>
                     </div>
                 </Col>
     </>

  );
}
export default Forgot;