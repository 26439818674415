import React from "react";
import { Button, Table } from "react-bootstrap";
import Pdf from "react-to-pdf";
import './css/PDF.scss';

const ref = React.createRef();

function PaymentReceipt() {

  return (
    <div>
      <Pdf targetRef={ref} filename="code-examplee.pdf">
        {({ toPdf }) => <Button variant="light" className=" mt-4 uppercase xs align-right download br-30" onClick={toPdf}>Imprimers</Button>} 
      </Pdf>
        <div ref={ref} className="pdf p-2 m-0">
            <div className="flex">
                <div className="m-0 f-w pr-15">
                    {/** <img  src="./assets/cnss-logo.svg" width="100%" /> */}  
                </div>
                <div className="f-w f-xs align-center">
                        <div className="uppercase">Republique de djibouti</div>
                        <div >Unité-Egalité-Paix</div>
                        <div>Ministre du Travail</div>
                        <div>charger de la </div>
                        <div>Reforme de l administration</div>
                </div>
              
            </div>
            <div className="flex">
                <div className="m-0 paragraph">
                        <div> <span className="f-w" >N° de caisse : </span>Recettes des Cotisations </div>
                </div>
                <div className="m-0 padding pr-13">
                    <div><span className="f-w" >Matricule :  </span>Matricule : </div>
                    <div><span className="f-w" >Djibouti , le :  </span> Matricule :</div>
                </div>
            </div>
            <div className="flex mt-3 ">
                <div className="m-0 paragraph pr-20 ">
                    <table>
                             <tr className="payment">
                                <td><span className="f-w">N° Employeur : </span></td>
                                <td>00000000000000</td>
                             </tr>
                             <tr  className="payment">
                                <td><span className="f-w">Nom ou Raison sociale : </span></td>
                                <td>Entreprise test</td>
                             </tr>
                             <tr  className="payment">
                                <td><span className="f-w">Date de réglement : </span></td>
                                <td>12/06/2022</td>
                             </tr>
                             <tr  className="payment">
                                <td><span className="f-w">Mode de paiement : </span></td>
                                <td>Cheque</td>
                             </tr>
                             <tr  className="payment">
                                <td><span className="f-w">Type de compte de contrepartie : </span></td>
                                <td>Bank</td>
                             </tr>
                    </table>
                </div>
                <div className="m-0 paragraph pr-13 ">
                    <table>
                             <tr className="payment">
                                <td><span className="f-w">Cotisation Total Due : </span></td>
                                <td>123344444</td>
                             </tr>
                             <tr  className="payment">
                                <td><span className="f-w">reglement : </span></td>
                                <td>444444444</td>
                             </tr>
                             <tr  className="payment">
                                <td><span className="f-w">Référence de paiemnet : </span></td>
                                <td>44444444</td>
                             </tr>
                             <tr  className="payment">
                                <td><span className="f-w">Compte de contrepartie : </span></td>
                                <td><span className="uppercase">JBISC</span></td>
                             </tr>
                    </table>
                </div>
            </div> 
            <div className="flex mt-3">
                <Table className="width-80 m-0"   bordered>
                    <thead>
                        <tr className="f-xs  declarer">
                            <th>Type</th>
                            <th>Période</th>
                            <th>Montant</th>
                            <th> NB de salariés</th>
                            <th>M.Salariale de Base</th>
                            <th>M.Salariale de Brut</th>
                            <th>Echéance</th>
                            <th>N° Appel</th>
                         </tr>
                    </thead>
                    <tbody>                    
                            <tr className="declarer">
                                <td>.......</td>
                                <td>........</td>
                                <td> DU ...... <br/>AU ...... </td>
                                <td>........</td>
                                <td>........</td>
                                <td>.........</td>
                                <td>..........</td>
                                <td>.........</td>
                            </tr>
                    </tbody>
                </Table>
            </div>   
        </div>
    </div>
  );
}

export default PaymentReceipt;
