import React, { useState } from "react";
import { Alert, Button, Col, FloatingLabel, Form, Row, Tab, Tabs , Spinner} from "react-bootstrap";
import { BsCheck, BsTrashFill } from "react-icons/bs";
import Wizard from "../../Components/Wizard";
import './Forms.scss';
import '../vs-mobil/Forms.scss';
// import axios from "axios";
import { api } from '../../index';
  
function Suspension() {

    const [PieceIdentite, setPieceIdentite] = useState();
    const [DJ, setDJ] = useState(<div> <span class="text-1">Télécharger </span><span class="text-2"> votre document justificatif</span> </div>);
    const [deletedj, setdeletedj] = useState(false);
    const [validated, setValidated] = useState(false);

    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [Description, setDescription] = useState("");
    const [success, setsuccess] = useState(false);
    const [Errors, setErrors] = useState("");
    const [Message, setMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const items = JSON.parse(localStorage.getItem('data'));

   //documents justificatifs
        const documents_j = (event) =>{
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => {
                      setPieceIdentite(e.target.result)
                };
                setDJ(event.target.files[0]?event.target.files[0].name :DJ)
                setdeletedj(event.target.files[0]?true :false);
        }
        const file_dj = () => {
               document.getElementById("file_dj").click() 
        };
      
        const deleteDJ = () => {
                setDJ(<div> <span class="text-1">Télécharger </span><span class="text-2"> votre document justificatif</span> </div>);
                setdeletedj(false);
        };

        const suspension = (event) => {
             // Prevent double submission
            if (isSubmitting) {
                return;
            }
            setIsSubmitting(true);

            if(PieceIdentite){
                const form = event.currentTarget;
                if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                        setIsSubmitting(false);

                }
                setValidated(true);
                api.post('request/add', {
                        FromDate,ToDate,Description,CustomerAccount:items.user.customer["CustomerAccount"],PieceIdentite
                }).then(async (response) => { 
                        if(response.data.data){
                            setsuccess(true);
                            setIsSubmitting(false);

                        }
                }, (error) => {
                         setErrors(error.response.data.data.error);
                         setIsSubmitting(false);

                });
            }else{
                setMessage("ce champ est obligatoire")
                setIsSubmitting(false);
            }
        }
    return (
            <>
  
                    <Tabs
                        defaultActiveKey="suspension"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 mt-4 Employeur-tabs w-100"
                    >
                        <Tab eventKey="suspension" title="suspension">
                            <div  className={success?"hide":""}>
                                 <Form className="mt-4" noValidate validated={validated} >
                                    <Alert  variant="danger" className={Errors ? "" : "hide"}>
                                        {Errors}
                                    </Alert>
                                            <Row className="form-row">
                                                <Col sm={12} md={6}>
                                                    <Form.Group className="mb-3">
                                                        <FloatingLabel  label="Date d’effet *" className="uppercase">
                                                            <Form.Control defaultValue={FromDate} type="date" min="2023-01-18" onChange={(e) => setFromDate(e.target.value)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                    ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <Form.Group className="mb-3">
                                                        <FloatingLabel  label="Date de réactivation " className="uppercase">
                                                            <Form.Control  type="date" defaultValue={ToDate} onChange={(e) => setToDate(e.target.value)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                    ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <Form.Group className="mb-3">
                                                        <FloatingLabel  label="Motif" className="uppercase">
                                                            <Form.Control as="textarea" className="textArea-height" defaultValue={Description} onChange={(e) => setDescription(e.target.value)}  required />
                                                            <Form.Control.Feedback type="invalid">
                                                                    ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6}></Col>
                                                <Col sm={12} md={6} className="m-0">
                                                    <div class="wrap file" onClick={file_dj}>
                                                        <div class="valign-middle">
                                                            <div class="form-group">
                                                                <label for="file" class="sr-only">
                                                                    {DJ} <BsTrashFill className={!deletedj?"hide":""} onClick={deleteDJ}/>
                                                                </label>
                                                                <div class="t"><span class="btn1">
                                                                    <div> <span class="text-1"> </span><span class="text-2"> votre C.V</span> </div>
                                                                    <div> <span class="text-3">Ou telechargez votre C.V depuis votre </span><span class="text-4"> appareil</span></div>
                                                                </span><span class="file-selected"></span><input type="file" name="cvfile" className="cvfiles" id="file_dj" onChange={documents_j}/></div>
                                                            </div>
                                                        </div>
                                                </div>
                                                </Col>
                                            
                                            </Row>    
                                            <Button
                                                variant="success"
                                                onClick={suspension}
                                                className="succes mt-4 uppercase br-30 xs align-right"
                                                disabled={isSubmitting} // Disable the button during submission
                                            >
                                                {isSubmitting ? (
                                                <>
                                                    <Spinner animation="border" size="sm" role="status" style={{ marginRight: "5px" }} />
                                                    <span>Loading...</span>
                                                </>
                                                ) : (
                                                <>
                                                    <img variant="top" src="./assets/verify-white.svg" style={{ paddingRight: 5 }} /> Envoyer
                                                </>
                                                )}
                                            </Button>
                                        
                                 </Form> 
                            </div> 
                            <div className={success?"right-grid align-center pt-2 mt-4":"hide"}>
                                 <h1 className="subtitle-form sm"> Votre suspension est ajouté avec succès.</h1>
                                 <img src="./assets/success.svg" className="mt-4" />
                            </div>   
                        </Tab>
                    </Tabs>
            </>
    );
}
export default Suspension;