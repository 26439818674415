// import axios from "axios";
import { api } from '../../index';
import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, FloatingLabel, Form,  Modal,  Row, Tab, Table, Tabs ,Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsCheckLg } from "react-icons/bs";
import Header from "../../Components/Header";
import VerticalHeader from "../../Components/VerticalHeader";
import HorizontalHeader from "../../Components/HorizontalHeader";
import '../css/Dashboard.scss';
import '../Employeur.scss';

function Employeur() {

    
    const items = JSON.parse(localStorage.getItem('data'));

    const [adresses, setAdresses] = useState([])
    const [informations, setInformations] = useState([])
    const [documents, setdocuments] = useState([])
    const [ContactSelected, setContactSelected] = useState([])
    const [EditCmodal, setEditCmodal] = useState(false)
    const [DeleteCmodal, setDeleteCmodal] = useState(false)
    const [AddressDescription, setAddressDescription] = useState();
    const [AddressCity, setAddressCity] = useState();
    const [AddressStreet, setAddressStreet] = useState();
    const [AddressCountryRegionId, setAddressCountryRegionId] = useState();
    const [AddressLocationRoles, setAddressLocationRoles] = useState();
    const [Type, setType] = useState("");
    const [Description, setContactDescription] = useState("");
    const [TypeEmpl, setTypeEmpl] = useState("");
    const [Locator, setLocator] = useState("");
    const [ItemDeleted, setItemDeleted] = useState();

    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [fullscreenCon, setFullscreenCon] = useState(true);
    const [showCon, setShowCon] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [Villes, setVilles] = useState([]);
    const [ListEmployeurs, setListEmployeurs] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [Errors, setErrors] = useState([]);

    useEffect(() => {
        api.get(`customer/${items.user.customer["CustomerAccount"]}/addresses`).then(async (response) => {
               setAdresses(response.data.data);
        });
        api.get(`customer/${items.user.customer["PartyNumber"]}/partycontacts`).then(async (response) => {
               setInformations(response.data.data);
        })
        api.get(`customer/${items.user.customer["PartyNumber"]}/ssmdocuments`).then(async (response) => {
              setdocuments(response.data.data);
        })
        api.get('employertypes').then(async (response) => {
            setListEmployeurs(response.data.data);
            
            response.data.data.forEach(element => {
                if(element["EmployerTypeId"] ===items.user.customer["SSMEmployerTypeId"]){
                    setTypeEmpl(element.Description)
                    return;
                }
            });
        })
        api.get('addressCities').then(async (response) => {
            setVilles(response.data.data);
        })
      },[]);

        const back = () => {
            window.location.href="/Accueil"
        }
        function AddContact(breakpoint) {
            setFullscreenCon(breakpoint);
            setShowCon(true);
        }
        function AddAdress(breakpoint) {
            setFullscreen(breakpoint);
            setShow(true);
        }

        const [validated, setValidated] = useState(false);
        const adresse = (event) => {
            const form = event.currentTarget;
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    setValidated(true);
                    
                    api.post('createcustomeraddress', {
                    CustomerAccountNumber: items.user["SSMCustomerAccount"],AddressDescription,AddressCity,AddressStreet,AddressCountryRegionId,AddressLocationRoles
                        }).then(async (response) => {
                        if(response.data.success){
                            api.get(`customer/${items.user.customer["CustomerAccount"]}/addresses`).then(async (response) => {
                                setAdresses(response.data.data);
                            });
                            setShow(false);
                        }
                        }, (error) => {
                        setErrors(error.response.data.errors);
                        });
         
        };

        const [validatedC, setValidatedC] = useState(false);
        const contacts = (event) => {
            if (isSubmitting) {
            
                return;
              }
              setIsSubmitting(true);
            const form = event.currentTarget;
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                setValidatedC(true);
                
                api.post('createpartycontact', {
                    PartyNumber: items.user.customer["PartyNumber"],Type,Description,Locator
                    }).then(async (response) => {
                    if(response.data.success){
                        setShowCon(false)
                        api.get(`customer/${items.user.customer["PartyNumber"]}/partycontacts`).then(async (response) => {
                            setInformations(response.data.data);
                            setIsSubmitting(false);

                    })
                    }
                    }, (error) => {
                    setErrors(error.response.data.errors);
                    setIsSubmitting(false);

                    });
            
        };
        const ContactSelectedE = (event) =>{
            setEditCmodal(true)
            informations.forEach(element => {
                if(element["_id"] == event.currentTarget.id){
                    setContactSelected(element)
                    setType(element.Type)
                    setContactDescription(element.Description)
                    setLocator(element.Locator)
                }
            });
        }
        const ContactSelectedD = (event) =>{
             setDeleteCmodal(true)
             setItemDeleted(event.currentTarget.id)
        }
        const EditContact = () =>{
            api.post("editpartycontact",{
                id: ContactSelected["_id"],
                Locator: Locator ?? ContactSelected.Locator,
                Type: Type ?? ContactSelected.Type,
                Description: Description ?? ContactSelected.Description
            }).then(async (response) => {
                if(response.data.success){
                    setEditCmodal(false)
                    api.get(`customer/${items.user.customer["PartyNumber"]}/partycontacts`).then(async (response) => {
                        setInformations(response.data.data);
                 })
                }
                }, (error) => {
                setErrors(error.response.data.errors);
                });
        }
        const confirmDelete = () =>{
            api.delete(`deletepartycontact/${ItemDeleted}`).then(async (response) => {
                if(response.data.success){
                    setDeleteCmodal(false)
                    api.get(`customer/${items.user.customer["PartyNumber"]}/partycontacts`).then(async (response) => {
                        setInformations(response.data.data);
                 })
                }
                }, (error) => {
                setErrors(error.response.data.errors);
                });
        }
        const handleInputSearch = (e) => {
            setSearchVal(e.target.value);
       }
     
       let dataDocIds = [];
       documents.filter((index) => {
              dataDocIds.push(index.PartyNumber.toLowerCase());
       });
     
       const searchIn= dataDocIds.filter((index) => {
           return index.includes(searchVal.toLowerCase());
       });
       let dataDocsArray = [];
       documents.forEach(index => {
           if (searchIn.includes(index.PartyNumber.toLowerCase())) {
               dataDocsArray.push(index)
           }
       })
       //Adresses
    
       let dataAdresses = [];
   
       adresses.filter((index) => {
        dataAdresses.push(index.FormattedAddress ? index.FormattedAddress.toLowerCase() :"");
       });
       const searchInA = dataAdresses.filter((index) => {
           return index.includes(searchVal.toLowerCase());
       });
     
       let dataAdressArray = [];
       adresses.forEach(index => {
           if (searchInA.includes(index.FormattedAddress ? index.FormattedAddress.toLowerCase() :"")) {
             dataAdressArray.push(index)
        
           }
       })

           //Contact
   
           let dataContact = [];
       
           informations.filter((index) => {
            dataContact.push(index.Locator.toLowerCase());
           });
           
           const searchInC= dataContact.filter((index) => {
               return index.includes(searchVal.toLowerCase());
           });
         
           let datacontactsArray = [];
           informations.forEach(index => {
               if (searchInC.includes(index.Locator.toLowerCase())) {
                datacontactsArray.push(index)
            
               }
           })

     const columns = [
        {
            name: 'Type de document',
            selector: row => row.type_doc,
            sortable: true,
        },
        {
            name: 'Numéro',
            selector: row => row.num,
            sortable: true,
        },
        {
            name: "Date d'effectivité",
            selector: row => row.date_effectivite,
            sortable: true,
        },
        {
            name: "Date d'expiration",
            selector: row => row.date_exp,
            sortable: true,
        }
      ];
    
    let data = [];
    dataDocsArray.map((index) => {
          data.push({
            type_doc: index.DocumentTypeId,
            num : index.PartyNumber,
            date_effectivite: index.ValidFrom === "2154-12-31" || index.ValidFrom === "1900-01-01"  ? "-":index.ValidFrom,
            date_exp : index.ExpirationDate === "2154-12-31" || index.ExpirationDate === "1900-01-01"  ? "-":index.ExpirationDate,
          
          });
        });

        const columnsAdress = [
            {
                name: 'Nom ou description',
                selector: row => row.description,
                sortable: true,
            },
            {
                name: 'Adresse',
                selector: row => row.adress,
                sortable: true,
            },
            {
                name: "Objectif",
                selector: row => row.objectif,
                sortable: true,
            },
            {
                name: "Principale",
                selector: row => row.principale,
                sortable: true,
            }
          ];
        
        let dataAdress = [];
        dataAdressArray.map((index) => {
            dataAdress.push({
                description: index.AddressDescription,
                adress : index.FormattedAddress,
                objectif:index.AddressLocationRoles,
                principale : <div className="container-radio" >
                                  {index["IsPrimary"] === "Yes" ?<Badge pill className="p-2 badge-succes "><BsCheckLg/> Oui </Badge>:""}
                             </div>,
              
              });
            });
    

    const columnsContacts = [
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: "Numero / Adresse de contact",
            selector: row => row.num,
            sortable: true,
        },
        {
            name: "Principale",
            selector: row => row.principale,
            sortable: true,
        }
    ];

        let dataContacts = [];
        datacontactsArray.map((index) => {
            dataContacts.push({
                id:index._id,
                description: index.Description,
                type : index.Type,
                num:index.Locator,
                principale :<div className="container-radio m-0">
                                     {index["IsPrimary"] === "Yes"?<Badge pill className="p-2 badge-succes m-0"><BsCheckLg/> Oui </Badge>:""}
                                    <div className="btns-bar">
                                            <Button variant="outline-success" className="edit br-15" id={index["_id"]} onClick={ContactSelectedE}><span className="Icon"></span></Button>
                                            <Button variant="outline-success" className="delete br-15" id={index["_id"]} onClick={ContactSelectedD}> <span className="Icon"></span></Button>
                                    </div>
                            </div>,
            
            });
            });



  return (
    <>

     <Header/>
        <Card className="p-2 br-15 card-content">
            <VerticalHeader />
            <div className="parent m-2 w-100">
                    <div className="align-right back">
                          <Button variant="light" onClick={back} className="mt-4 uppercase back-btn employeur small align-left">
                              <img src="./assets/back.svg" /> Retour
                          </Button>
                    </div>
                    <h1 className="title-form">Ma fiche employeur </h1>
                    <span className="subtitle-form sm">Accéder aux informations générales de votre compte : informations, adresses, modes de contact, documents. </span>
                    <Tabs
                          defaultActiveKey="home"
                          transition={false}
                          id="noanim-tab-example"
                          className="mb-3 mt-4 Employeur-tabs w-100"
                        >
                            <Tab eventKey="home" title="COTISANTS">
                                <Form className="mt-4"> 
                                        <Row className="form-row">
                                        <h5 className="mb-3 c-primary">Informations d'affiliation</h5>
                                            <Col sm={6} md={4}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="type" className="uppercase">
                                                        <Form.Control value={TypeEmpl} type="text"  disabled  className="bg-light"/>
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="N° d'affiliation" className="uppercase">
                                                        <Form.Control  type="number"  value={items&&items.user.customer["CustomerAccount"]} disabled className="bg-light" />
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="Nom/Raison sociale" className="uppercase">
                                                        <Form.Control value={items.user.customer["NameAlias"] ? items.user.customer["NameAlias"] : items.user.customer["OrganizationName"]} type="text"  disabled  className="bg-light" />
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                        </Row> 
                                        <Row className="form-row">
                                            <Col sm={6} md={4}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="Activité" className="uppercase">
                                                        <Form.Control value={items ? items.user.customer.segment["SegmentDescription"] : " "} type="text"  disabled className="bg-light" />
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="Sous-activité" className="uppercase">
                                                        <Form.Control value={items ? items.user.customer.sub_segment["SubsegmentDescription"] : " "}   type="text"disabled  className="bg-light"/>
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="Nombre d’assurés" className="uppercase">
                                                        <Form.Control value={items&&items.user.customer["OrganizationNumberOfEmployees"]} type="text" disabled  className="bg-light"/>
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="form-row">
                                            <Col sm={6} md={4} className="m-0">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="DATE D'AFFILIATION" className="uppercase">
                                                        <Form.Control value={items ? items.user.customer["SSMAffiliationDate"] : " "} type="text" disabled  className="bg-light"/>
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4} className="m-0">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="Date de création" className="uppercase">
                                                        <Form.Control
                                                                 value={items && items.user.customer["SSMSubjectionDate"] === "1900-01-01" ? "-" 
                                                                        :items.user.customer["SSMSubjectionDate"]} type="text"  disabled className="bg-light" />
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="form-row">
                                            <h5 className="mb-3 c-primary">Informations bancaires</h5>
                                            <Col sm={6} md={4} className="m-0">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="N° compte bancaire" className="uppercase">
                                                        <Form.Control value={items&&items.user.customer["SSMBankAccountNum"]} type="text"  disabled  className="bg-light" />
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4} className="m-0">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <FloatingLabel controlId="floatingPassword" label="Banque" className="uppercase">
                                                        <Form.Control value={items&&items.user.customer["PaymentBankAccount"]} type="text" placeholder="Enter email" disabled className="bg-light" />
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                        </Row>     
                                        <Button variant="success"  className="mt-4 uppercase xs align-right succes hide">
                                                    Valider <img variant="top" src="./assets/inscription.png" className="pl-5" />
                                        </Button>
                                    </Form>
                            </Tab>
                            <Tab eventKey="profile" title="ADRESSE">
                                <Form className="bar-top flex">
                                    <div className="m-0 first">
                                            <Form.Control
                                            type="search"
                                            placeholder="Rechercher"
                                            className="me-2 w-50 br-15  m-0 w-100 mb-2"
                                            aria-label="Rechercher"
                                            onChange={handleInputSearch}
                                            value={searchVal}
                                            />
                                        </div>
                                        <div className="btns-bar">
                                            <Button variant="outline-success" className="add br-15" onClick={() => AddAdress()}> <span className="Icon"></span> Ajouter</Button>
                                        </div>
                                </Form>
                                <Row className="mt-2 scrol-x-t">
                                    <DataTable
                                        columns={columnsAdress}
                                        data={dataAdress}
                                        pagination
                                        highlightOnHover
                                        fixedHeader
                                        fixedHeaderScrollHeight="500px"
                                        
                                    />
                                    
                                </Row>  
                                <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} className="modal-style">
                                    <Modal.Header closeButton className="m-0">
                                          <Modal.Title className="m-0">Ajouter une adresse </Modal.Title>
                                    </Modal.Header>
                                        <Modal.Body className="m-0">
                                            <Row className="form-row">
                                            <Form noValidate validated={validated} className="mt-4">
                                                <Col sm={12} >
                                                    <Form.Group >
                                                        <FloatingLabel  label="Description"  className="uppercase mb-3">
                                                            <Form.Control  type="text" onChange={(e) => setAddressDescription(e.target.value)}   required  />
                                                            <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group className="mb-3">
                                                        <FloatingLabel  label="Ville" className="uppercase">
                                                            <Form.Select aria-label="Default select example" onChange={(e) => setAddressCity(e.target.value)}>
                                                                <option></option>
                                                                {Villes.map((index)=> (
                                                                    <option value={index.Name}>{index.Description}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} >
                                                    <Form.Group  >
                                                        <FloatingLabel  label="Quartier" className="uppercase mb-3">
                                                            <Form.Control type="text" onChange={(e) => setAddressStreet(e.target.value)}   required />
                                                            <Form.Control.Feedback type="invalid">
                                                                ce champ est obligatoire
                                                                </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} >
                                                    <Form.Group  >
                                                        <FloatingLabel  label="Objectif" className="uppercase mb-3">
                                                            <Form.Control type="text" onChange={(e) => setAddressLocationRoles(e.target.value)}   required />
                                                            <Form.Control.Feedback type="invalid">
                                                                ce champ est obligatoire
                                                                </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} >
                                                    <Form.Group  >
                                                        <FloatingLabel  label="Pays" className="uppercase mb-3">
                                                        <Form.Select  className="pl-1 select"  onChange={(e) => setAddressCountryRegionId(e.target.value)}   required  >
                                                            <option value=""></option>
                                                            <option value="DJI">DJI</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                        ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <div className="gap mb-4">
                                                    <Button variant="success"  onClick={adresse} className="succes mt-4 uppercase xs align-right br-30">
                                                            <img variant="top" src="./assets/verify-white.svg" style={{paddingRight:5}} />  Envoyer 
                                                    </Button> 
                                                    
                                                </div>
                                                </Form>
                                            </Row>
                                        </Modal.Body>
                                </Modal>
                                 
                            </Tab>
                            <Tab eventKey="CNSS" title="CONTACTS">
                                <Form className="bar-top flex">
                                        <div className="m-0 first">
                                            <Form.Control
                                            type="search"
                                            placeholder="Rechercher"
                                            className="me-2 w-100 br-15  m-0  mb-2"
                                            aria-label="Rechercher"
                                            onChange={handleInputSearch}
                                            value={searchVal}
                                            />
                                        </div>
                                        <div className="btns-bar">
                                            <Button variant="outline-success" className="add br-15" onClick={() => AddContact()}> <span className="Icon"></span> Ajouter</Button>
                                        </div>
                                      
                                    </Form>
                                    <Row className="mt-2 scrol-x-t">
                                                <DataTable
                                                    columns={columnsContacts}
                                                    data={dataContacts}
                                                    pagination
                                                    highlightOnHover
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="500px"
                                                />
                                                <Modal show={EditCmodal} onHide={() => setEditCmodal(false)} className="modal-style">
                                                        <Modal.Header closeButton className="m-0">
                                                        <Modal.Title className="m-0">Modifier un contact </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body className="m-0">
                                                            <Row className="form-row">
                                                            <Form noValidate validated={validatedC} className="mt-4">
                                                                <Col sm={12} >
                                                                    <Form.Group >
                                                                        <FloatingLabel  label="Description"  className="uppercase mb-3">
                                                                            <Form.Control  type="text" defaultValue={ContactSelected.Description} onChange={(e) => setContactDescription(e.target.value)}   required  />
                                                                            <Form.Control.Feedback type="invalid">
                                                                            ce champ est obligatoire
                                                                            </Form.Control.Feedback>
                                                                        </FloatingLabel> 
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={12} >
                                                                    <Form.Group  >
                                                                        <FloatingLabel  label="Type" className="uppercase mb-3">
                                                                        <Form.Select  className="pl-1 select" defaultValue={ContactSelected["Type"]} onChange={(e) => setType(e.target.value)}   required  >
                                                                            <option value=""></option>
                                                                            <option value="Phone">Phone</option>
                                                                            <option value="Email">Email</option>
                                                                        </Form.Select>
                                                                        <Form.Control.Feedback type="invalid">
                                                                        ce champ est obligatoire
                                                                        </Form.Control.Feedback>
                                                                        </FloatingLabel> 
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={12} >
                                                                    <Form.Group  >
                                                                        <FloatingLabel  label="Numero/ Adresse de contact" className="uppercase mb-3">
                                                                            <Form.Control type="text" defaultValue={ContactSelected["Locator"]} onChange={(e) => setLocator(e.target.value)}   required />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                ce champ est obligatoire
                                                                                </Form.Control.Feedback>
                                                                        </FloatingLabel> 
                                                                    </Form.Group>
                                                                </Col>
                                                                <div className="gap mb-4">
                                                                    <Button variant="success"  onClick={EditContact}  className="succes mt-4 uppercase xs align-right br-30">
                                                                            <img variant="top" src="./assets/edit.svg" style={{paddingRight:5}} />  Modifier 
                                                                    </Button> 
                                                                </div>
                                                                </Form>
                                                            </Row>
                                                        </Modal.Body>
                                                </Modal>
                                                <Modal show={DeleteCmodal}  onHide={() => setDeleteCmodal(false)} className="modal-style" >
                                                        <Modal.Header closeButton className="m-0">
                                                        </Modal.Header>
                                                        <Modal.Body className="f-w"> Voulez-vous vraiment supprimer ce contact ? </Modal.Body>
                                                        <Modal.Footer className="gap">
                                                            <Button variant="danger" className="m-0" onClick={() => setDeleteCmodal(false)}>
                                                                Non
                                                            </Button>
                                                            <Button variant="success" className="m-0" onClick={confirmDelete}>
                                                                Oui
                                                            </Button>
                                                        </Modal.Footer>
                                                </Modal>
                                    
                                    </Row>  
                                    <Button variant="success"  type="submit" className="succes mt-4 uppercase xs align-right hide">
                                                    Valider <img variant="top" src="./assets/inscription.png" className="pl-5" />
                                    </Button> 
                                    <Modal show={showCon} fullscreen={fullscreenCon} onHide={() => setShowCon(false)} className="modal-style">
                                        <Modal.Header closeButton className="m-0">
                                        <Modal.Title className="m-0">Ajouter un contact </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="m-0">
                                            <Row className="form-row">
                                            <Form noValidate validated={validatedC} className="mt-4">
                                                <Col sm={12} >
                                                    <Form.Group >
                                                        <FloatingLabel  label="Description"  className="uppercase mb-3">
                                                            <Form.Control  type="text" onChange={(e) => setContactDescription(e.target.value)}   required  />
                                                            <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} >
                                                    <Form.Group  >
                                                        <FloatingLabel  label="Type" className="uppercase mb-3">
                                                        <Form.Select  className="pl-1 select" onChange={(e) => setType(e.target.value)}   required  >
                                                            <option value=""></option>
                                                            <option value="Phone">Phone</option>
                                                            <option value="Email">Email</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                        ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} >
                                                    <Form.Group  >
                                                        <FloatingLabel  label="Numero/ Adresse de contact" className="uppercase mb-3">
                                                            <Form.Control type="text" onChange={(e) => setLocator(e.target.value)}   required />
                                                            <Form.Control.Feedback type="invalid">
                                                                ce champ est obligatoire
                                                                </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <div className="gap mb-4">
                                                   
                                                    <Button
        variant="success"
        className="succes mt-4 uppercase xs br-30 align-right mb-4"
        onClick={contacts}
        disabled={isSubmitting} // Disable the button during submission
      >
        {isSubmitting ? (
          <>
            <Spinner animation="border" size="sm" role="status" style={{ marginRight: "5px" }} />
            <span>Loading...</span>
          </>
        ) : (
          <>
            <img variant="top" src="./assets/verify-white.svg" style={{ paddingRight: 5 }} /> Ajouter
          </>
        )}
      </Button>
                                                </div>
                                                </Form>
                                            </Row>
                                        </Modal.Body>
                                    </Modal>
                            </Tab>
                            <Tab eventKey="DOCUMENTS" title="DOCUMENTS">
                                <Form className="bar-top flex">
                                    <div className="m-0 first">
                                        <Form.Control
                                        type="search"
                                        placeholder="Rechercher"
                                        className="me-2 br-15  m-0  mb-2"
                                        aria-label="Rechercher"
                                        style={{width: "65%"}}
                                        onChange={handleInputSearch}
                                        value={searchVal}
                                        />
                                    </div>
                                </Form>
                                <Row className="mt-2 scrol-x-t">
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        pagination
                                        highlightOnHover
                                        fixedHeader
                                        fixedHeaderScrollHeight="500px"
                                    />
                                
                                </Row>  
                                <Button variant="success"  className="succes mt-4 uppercase xs align-right hide">
                                                Valider <img variant="top" src="./assets/inscription.png" className="pl-5" />
                                </Button> 
                            </Tab>
                    </Tabs>
                </div>
                
                <HorizontalHeader />
            </Card>
    </>
  );
}
export default Employeur;