import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import Header from "../../Components/Header";
import '../Authentification/Connexion.scss';
import '../Authentification/vs-mobil/Auth-mobil.scss';
import { BsEmojiFrown } from "react-icons/bs";
import { api } from '../../index';
  
function SuccessPayment() {

    const [paymentData, setPaymentData] = useState(false);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id')
        if (id ) {
            api.get(`customer/payment/${id}`).then(async (response) => {
                setPaymentData(response.data.data)
            })
        }
    },[]);

  return (
    <div id="app">
        <Header/>
        <div className="justify-content-center m-2">
            <div className="align-center mt-4r">
                 <img  variant="top" src="/assets/cnss-logo.svg" /> 
            </div>
            <Card className="login-card margin-auto mt-2 mb-4">
                <Row className="h-100 m-0">
                    <Col className="p-0 m-0" xs={12} md={6}>
                        <div className="left-grid m-0"> <Card.Img  variant="top" src="/assets/login.png" /> </div>
                    </Col>
                    <Col  style={{marginTop:"5%"}}>
                        <div className="right-grid align-center pt-2">
                            {   paymentData?.payment_method ?  
                                paymentData?.payment_method == "Virement" ? 
                                <h2 className="title-form msg-virement" >Votre paiement par virement est en attente de validation</h2> :
                                <h1 className="title-form">Validation de paiement</h1> :
                                ""
                            }
                            
                            <p className="subtitle-form sm">
                              <div>
                                    {   paymentData.payment_method ? 
                                        paymentData.payment_method == "Virement" ? "" : <p>Paiement effectué avec succès</p> : 
                                        ""
                                    }
                                    <img src="/assets/success.svg" className="mt-10" />
                               </div>
                            </p>
                            
                        </div>
                          <div className="right-grid align-center pt-2">
                                <p className="subtitle-form sm">
                                    { paymentData ?
                                    (
                                        <div>
                                                <p>N° de Paiement : <strong>{paymentData.PaymentId}</strong></p>
                                                <p>Méthode de Paiement : <strong>{paymentData.payment_method}</strong></p>
                                                {   paymentData.payment_method ? 
                                                    (paymentData.payment_method == "Virement" || paymentData.payment_method == "P-SABA") ? "" : <p>Reference de Paiement : <strong>{paymentData.order_id}</strong></p> : 
                                                    ""
                                                }
                                                <p>Montant de Paiement : <strong>{paymentData.PaymAmount} DJF</strong></p>
                                        </div>
                                    ):
                                    (
                                        <div>
                                            <p >Merci pour votre paiement.</p>
                                            <p >Votre paiement est en cours de traitement avec l'équipe CNSS. Pour toute information supplémentaire, veuillez contacter l'équipe CNSS.</p>  
                                        </div>
                                        
                                    )} 
                                </p>
                            </div>                        
                        <div className="align-center">
                            <Button  className="valider mt-4 uppercase btn  xs" href="/paiements">
                                            Continuer <img src="/assets/verified.svg"/>
                            </Button>
                        </div>
                    </Col>
               
                
                </Row>
            </Card>
 
        </div>
    </div>
  );
}
export default SuccessPayment;