// import axios from "axios";
import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Header from "../../../Components/Header";
import '../Connexion.scss';
import { api } from '../../../index';

 
function ResetPwd() {

    const [validated, setValidated] = useState(false);
    const [Errors, setErrors] = useState([]);
    const [show, setShow] = useState(false);
    const [password, setpassword] = useState("");
    const [password_confirmation, setpassword_confirmation] = useState("");

    const [passwordShow, setpasswordShow] = useState(false);
    const [password_confirmationShow, setpassword_confirmationShow] = useState(false);

    const togglePasswordVisiblity = () => {
        setpasswordShow(passwordShow ? false : true);
    };
    const toggleConfirmPass = () => {
        setpassword_confirmationShow(password_confirmationShow ? false : true);
      };

      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token')
      const login = urlParams.get('login')
     

    const Reset = (event) => {
        const objErrors = {};
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            }
            setValidated(true);
        
        api.post('reset-password', {
           token,login ,password,password_confirmation
            }).then(async (response) => {
            if(response.data.success){
                setShow(true)
                //window.location.href = "/";
            }
            }, (error) => {
               setErrors(error.response.data.errors);
            });
     
      };
      
  
  return (
    <>
       <div id="app">
        <Header/>
        <div className="justify-content-center m-2">
            <div className="align-center mt-4r">
                 <img  variant="top" src="/assets/cnss-logo.svg" /> 
            </div>
            <Card className="login-card margin-auto mt-4 mb-4">
                <Row className="h-100 m-0">
                    <Col className="p-0 m-0" xs={12} md={6}>
                        <div className="left-grid m-0"> <Card.Img  variant="top" src="/assets/login.png" /> </div>
                    </Col>
                    <Col className={show ? "hide m-0" :"m-0"}>
                        <div className="right-grid align-center">
                         <h1 className="title-form mt-4"> Mot de passe / <br/> identifiant oublie</h1>
                                    <p className="subtitle-form sm"> Vos accès vous serons envoyés par mail si votre compte existe .</p>
                            <Form noValidate validated={validated} className="mt-4 pad-form">
                                <Form.Group className="mb-3"  controlId="formBasicPassword">
                                    <FloatingLabel controlId="floatingPassword" label="MOT DE PASSE *">
                                        <a className="eye mt-1" onClick={togglePasswordVisiblity}>{!passwordShow? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                        <Form.Control  type={passwordShow ? "text" : "password"}  onChange={(e) => setpassword(e.target.value)} required />
                                        {Errors && Errors.password ?  
                                            <Form.Control.Feedback type="invalid" style={{display:"block"}}>
                                                 {Errors.password[0]}
                                             </Form.Control.Feedback>
                                        :""}
                                    </FloatingLabel>        
                                </Form.Group>
                                <Form.Group className="mb-3"  controlId="formBasicPassword">
                                    <FloatingLabel controlId="floatingPassword" label="CONFIRMATION DE MOT DE PASSE *">
                                        <a className="eye mt-1" onClick={toggleConfirmPass}>{!password_confirmationShow? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                        <Form.Control  type={password_confirmationShow ? "text" : "password"}  onChange={(e) => setpassword_confirmation(e.target.value)} required />
                                        {Errors && Errors.password_confirmation ?  
                                            <Form.Control.Feedback type="invalid" style={{display:"block"}}>
                                                 {Errors.password_confirmation[0]}
                                             </Form.Control.Feedback>
                                        :""}
                                    </FloatingLabel>        
                                </Form.Group>
                               
                                <div className="mt-4">
                                    <Button   className="mt-4 uppercase small align-left back-btn">
                                       <img src="/assets/back.svg" className="pr-2"/> Retour
                                    </Button>
                                    <Button variant="success"onClick={Reset} className="mt-4 uppercase btn valider xs align-right">
                                        Valider
                                    </Button>
                                </div>
                            </Form>
                         </div>
                    </Col>
                     <Col className={show ? "" :"hide "} style={{marginTop:"15%"}}>
                     <div className="right-grid align-center pt-2">
                         <h1 className="title-form"> Reinitialisation </h1>
                         <p className="subtitle-form sm"> Utilisez votre nouveau mot de passe  pour vous connecter.</p>
                         <img src="/assets/success.svg" className="mt-10" />
                     </div>
                     <div className="align-center">
                         <Button  className="valider mt-4 uppercase btn  xs" href="/">
                                         Continuer <img src="/assets/verified.svg" />
                         </Button>
                     </div>
                     </Col>
                </Row>
            </Card> 
            </div>
    </div>   
     </>

  );
}
export default ResetPwd;