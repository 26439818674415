import React, {useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import Header from "../../Components/Header";
import '../Authentification/Connexion.scss';
import '../Authentification/vs-mobil/Auth-mobil.scss';
import { BsEmojiFrown } from "react-icons/bs";
// import axios from "axios";
  
function FailurePayment() {

    const [payment , setPayment] = useState({
        paymentId : "",
        referenceId : "",
        requestId : "",
        paymentMethod : "",
        amount : ""
    });

    const urlParams = new URLSearchParams(window.location.search);
    const paymentId = urlParams.get('id');
    const token = urlParams.get('hppResultToken');

    // useEffect(() => {
    //     api.get(`payment/failure/${token}`).then(async (response) => {
    //         setPayment(response.data.data);
    //         console.log(response.data);
    //     })
    // },[]);
  return (
    <div id="app">
        <Header/>
        <div className="justify-content-center m-2">
            <div className="align-center mt-4r">
                 <img  variant="top" src="/assets/cnss-logo.svg" /> 
            </div>
            <Card className="login-card margin-auto mt-4 mb-4">
                <Row className="h-100 m-0">
                    <Col className="p-0 m-0" xs={12} md={6}>
                        <div className="left-grid m-0"> <Card.Img  variant="top" src="/assets/login.png" /> </div>
                    </Col>
                    <Col  style={{marginTop:"15%"}}>
                        <div className="right-grid align-center pt-2">
                            <h1 className="title-form">La transaction de paiement a échoué.</h1>
                            <p className="subtitle-form sm"> 
                              <div>
                                    <BsEmojiFrown className="sadImotc"/> 
                              </div>
                            </p>
                            
                        </div>
                        <div className="right-grid align-center pt-2">
                            <p className="subtitle-form sm">
                              <div>
                                    <p>N° de Paiement : <strong>{paymentId}</strong></p>
                                    {/* <p>Référence de Paiement : <strong>{payment.referenceId}</strong></p>
                                    <p>Méthode de Paiement : <strong>{payment.paymentMethod}</strong></p>
                                    <p>Amount : <strong>{payment.amount} DJF</strong></p> */}
                               </div>
                            </p>
                            
                        </div>
                        <div className="align-center">
                            <Button  className="valider mt-4 uppercase btn  xs" href="/paiements">
                                            Continuer <img src="/assets/verified.svg"/>
                            </Button>
                        </div>
                    </Col>
               
                
                </Row>
            </Card>
 
        </div>
    </div>
  );
}
export default FailurePayment;