import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Link } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill} from "react-icons/bs";
import Header from "../../Components/Header";
import Forgot from "./Forgot";
import Register from "./Register";
import './Connexion.scss';
import './vs-mobil/Auth-mobil.scss';
// import axios from "axios";
import { api } from '../../index';
  
function Conditions() {
    const [show, setShow] = useState(true); 
    const [showRegister, setShowRegister] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [login, setEmail] = useState("");
    const [password, setpassword] = useState("");
    const [passwordShow, setpasswordShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState()

    const togglePasswordVisiblity = () => {
        setpasswordShow(passwordShow ? false : true);
    };
    const RegisterShow = () =>  {
          setShowRegister(true);
          setShow(false);
          setShowForgot(false);
    }
    const ForgotShow = () =>  {
            setShowRegister(false);
            setShow(false);
            setShowForgot(true);
  }
    const LoginShow = () =>  {
            setShowRegister(false);
            setShow(true);
            setShowForgot(false);
    }
    const connexion = (event) => {
        const objErrors = {};
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            }
            setValidated(true);
        
            api.post('login', {
                login,password
            }).then(async (response) => {
                localStorage.setItem('data', JSON.stringify(response.data.data));
                localStorage.setItem('smmToken', response.data.data.token);
                localStorage.setItem('expirationDate', response.data.data.expirationDate);
                if(response.data.success){
                    window.location.href = "Accueil";
                }
            }, (error) => {
                setErrors(error.response ? error.response.data?.data.error : "Server error! try again.");
            });
    
      };

  return (
    <div id="app">
        <Header/>
        <div className="justify-content-center m-2">
            <div className="align-center mt-4r">
                 <img  variant="top" src="./assets/cnss-logo.svg" /> 
            </div>
            <Card className="login-card  mt-4 mb-4" style={{width:"100%", overflowY:"scroll" }}>
            <h5 className="mb-3 c-primary">CONDITIONS D'UTILISATION DU PORTAIL WEB EMPLOYEUR - TÉLÉDÉCLARATION/TÉLÉPAIEMENT</h5>
            <>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Bienvenue sur le Portail Web Employeur de la CNSS Djibouti. Avant
          d'accéder et d'utiliser nos services en ligne, veuillez lire
          attentivement les conditions d'utilisation suivantes.&nbsp;
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="none"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          color: "rgb(34, 34, 34)",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          L'accès et l'utilisation de ce portail sont soumis aux dispositions
          détaillées ci-dessous.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          color: "rgb(34, 34, 34)"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun Underlined SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          textDecoration: "underline",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          1. Objet du Portail.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
 
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="none"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          color: "rgb(34, 34, 34)",
          fontSize: "12pt",
          lineHeight: "19.425px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Le portail de&nbsp;
        </span>
        <span
          className="NormalTextRun SpellingErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlSpellingErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          télé-déclaration
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;et télépaiement de la CNSS a pour objectif de faciliter et
          sécuriser les procédures de déclaration et de paiement des cotisations
          sociales pour les cotisants et employeurs à Djibouti
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "12pt",
          lineHeight: "19.425px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          color: "rgb(34, 34, 34)"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
 
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun Underlined SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          textDecoration: "underline",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          2
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .&nbsp;
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Accès au Portail.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          2
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          1&nbsp;
        </span>
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          .
        </span>
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          Création
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;de Compte
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Employeur.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          L'accès au Portail Web Employeur commence par la création d'un compte.
          L'employeur doit fournir des informations précises et valides,
          comprenant le numéro d'affiliation, le nom, la fonction, le numéro de
          téléphone, l'adresse e-mail, et choisir un mot de passe sécurisé. Ces
          informations sont cruciales pour identifier et authentifier
          l'employeur lors de l'utilisation du portail.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          L'employeur s'engage à fournir des données exactes, à jour et
          complètes. En cas de modification des informations, il est de la
          responsabilité de l'employeur de mettre à jour son profil dans les
          plus brefs délais. L'utilisation de fausses informations peut
          entraîner la suspension ou la résiliation du compte.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          2
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .2 Validation&nbsp;
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          du&nbsp;
        </span>
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          Compte &nbsp;Employeur
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Après avoir soumis les informations&nbsp;
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          de création de compte
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;l'employeur recevra une notification par e-mail l'invitant à
          valider son compte. La validation du compte nécessite une visite à
          l'agence CNSS. Cette étape vise à renforcer la sécurité et à garantir
          l'authenticité de l'employeur. Pendant la visite à l'agence, des
          mesures de sécurité supplémentaires peuvent être prises, telles que la
          vérification d'identité, pour prévenir toute utilisation frauduleuse
          du portail.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Il est impératif que l'employeur complète la procédure de validation
          dans les délais spécifiés. Le non-respect de cette étape peut
          entraîner la suspension temporaire ou la désactivation du compte
          jusqu'à la validation réussie.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          2
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .3&nbsp;
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Obligations et responsabilité des employeurs lors de la création du
          compte.
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          L'employeur est pleinement responsable de l'exactitude des
          informations fournies lors de l'inscription et tout au long de
          l'utilisation du portail. Il doit veiller à ce que les données
          personnelles et professionnelles soient à jour et reflètent fidèlement
          la réalité. En cas de changement dans les informations, l'employeur
          doit mettre à jour son profil sans délai.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Toute activité effectuée sur le portail à travers le compte de
          l'employeur est sous sa responsabilité. Il est recommandé à
          l'employeur de maintenir la confidentialité de ses informations
          d'identification pour éviter tout accès non autorisé à son compte. La
          CNSS&nbsp;
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          décline toute responsabilité pour les conséquences résultant d'une
          utilisation non autorisée des informations d'identification de
          l'employeur.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          En acceptant ces conditions d'utilisation, l'employeur reconnaît et
          accepte sa responsabilité quant à l'exactitude et à la confidentialité
          de ses informations.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .&nbsp;
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Modification et réinitialisation du mot de passe.
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Réinitialisation :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;L'employeur peut réinitialiser son mot de passe en suivant les
            procédures définies dans le portail.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="ListContainerWrapper SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        position: "relative"
      }}
    >
      <ul
        className="BulletListStyle1 SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflow: "visible",
          cursor: "text",
          listStyleType: "disc",
          fontFamily: "verdana"
        }}
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid={5}
          data-list-defn-props='{"335552541":1,"335559684":-2,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
          aria-setsize={-1}
          data-aria-posinset={1}
          data-aria-level={1}
          className="OutlineElement Ltr SCXW143257488 BCX0"
          style={{
            margin: "0px 0px 0px 24px",
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            overflow: "visible",
            cursor: "text",
            clear: "both",
            position: "relative",
            direction: "ltr",
            display: "block",
            fontSize: "11pt",
            fontFamily: "Arial, Arial_MSFontService, sans-serif",
            verticalAlign: "baseline"
          }}
        >
          <p
            className="Paragraph SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
              fontWeight: "normal",
              fontStyle: "normal",
              verticalAlign: "baseline",
              fontKerning: "none",
              backgroundColor: "transparent",
              color: "windowtext",
              textAlign: "justify",
              textIndent: 0
            }}
          >
            <span
              data-contrast="auto"
              lang="FR-FR"
              className="TextRun SCXW143257488 BCX0"
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontVariantLigatures: "none !important",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
                fontWeight: "bold"
              }}
            >
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                Procédures de Réinitialisation :
              </span>
            </span>
            <span
              className="EOP SCXW143257488 BCX0"
              data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
              }}
            >
              &nbsp;
            </span>
          </p>
        </li>
      </ul>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.1&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Connectez-vous au Portail :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;L'employeur doit accéder à la page de connexion du Portail Web
            Employeur à l'aide de son nom d'utilisateur et de son mot de passe
            actuels.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.2&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Option "Mot de Passe Oublié" :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Sur la page de connexion, l'employeur trouvera une option
            intitulée "Mot de Passe Oublié" ou un lien similaire. Il doit
            cliquer sur cette option pour démarrer le processus de
            réinitialisation.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.3&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Vérification d'Identité :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Pour des raisons de sécurité, le portail peut demander à
            l'employeur de fournir des informations supplémentaires pour
            vérifier son identité. Cela peut inclure la réponse à des questions
            de sécurité prédéfinies ou la validation de certaines informations
            personnelles.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.4&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Notification de Réinitialisation :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Une fois l'identité vérifiée, le portail enverra une
            notification à l'adresse e-mail enregistrée lors de l'inscription,
            confirmant la demande de réinitialisation du mot de passe.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.5&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Liens de Réinitialisation :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;La notification par e-mail contiendra des liens sécurisés
            permettant à l'employeur de réinitialiser son mot de passe. En
            cliquant sur ces liens, il sera redirigé vers une page sécurisée du
            portail.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.6&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Création d'un Nouveau Mot de Passe :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Sur la page sécurisée, l'employeur pourra créer un nouveau mot
            de passe. Il est recommandé de choisir un mot de passe robuste,
            combinant majuscules, minuscules, chiffres et caractères spéciaux.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.7&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Confirmation de la Réinitialisation :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Une fois le nouveau mot de passe saisi, l'employeur devra le
            confirmer. La confirmation garantit l'exactitude du nouveau mot de
            passe.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.8&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Accès Rétabli :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Après la confirmation, le mot de passe est réinitialisé avec
            succès, et l'employeur peut utiliser les nouvelles informations
            d'identification pour accéder au Portail Web Employeur.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.9&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Cryptage des Données :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Toutes les données liées à la réinitialisation du mot de
            passe, y compris les réponses aux questions de sécurité et les
            nouveaux mots de passe, sont cryptées pour garantir la
            confidentialité.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.10&nbsp;
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Authentification à Deux Facteurs :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Selon la politique de sécurité, le processus de
            réinitialisation peut être renforcé par une authentification à deux
            facteurs, offrant une couche supplémentaire de protection.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            La CNSS Djibouti met en place ces procédures afin d'assurer la
            sécurité des comptes des employeurs tout en facilitant le processus
            de réinitialisation du mot de passe lorsque nécessaire.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
   
    
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            4
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .&nbsp;
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Utilisation des services du Portail.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            4
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1 Déclarations et Paiements
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            L'employeur est autorisé à utiliser le Portail Web Employeur pour
            effectuer des déclarations d'appels de cotisation et des paiements
            en ligne. Il est impératif que toutes les informations fournies lors
            de ces transactions soient précises, complètes et conformes aux
            données réelles de l'entreprise. Toute déclaration inexacte ou
            incomplète pourrait entraîner des conséquences légales et
            financières.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Lors de chaque déclaration, l'employeur doit vérifier attentivement
            les montants, les données relatives aux employés et toute autre
            information pertinente. Les déclarations d'appels de cotisation
            soumises seront examinées par la CNSS pour approbation. Des
            notifications concernant l'approbation ou le rejet de la déclaration
            seront envoyées à l'employeur par e-mail.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Les paiements associés aux déclarations peuvent être effectués par
          divers moyens, tels que le virement bancaire, D-Money ou&nbsp;
        </span>
        <span
          className="NormalTextRun SpellingErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlSpellingErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          Waafi
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          . Les références de paiement et les instructions connexes doivent être
          suivies avec précision pour garantir une comptabilisation correcte des
          paiements.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          4
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .2&nbsp;
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Confidentialité
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          La confidentialité des informations est cruciale pour la sécurité des
          transactions effectuées sur le Portail Web Employeur. L'employeur est
          tenu de maintenir la confidentialité de son nom d'utilisateur et de
          son mot de passe associés à son compte. Ces informations sont
          personnelles et ne doivent en aucun cas être partagées avec des tiers.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Tout accès non autorisé au compte résultant d'une divulgation
          négligente des informations d'identification relève de la
          responsabilité exclusive de l'employeur. Il est recommandé à
          l'employeur de modifier régulièrement son mot de passe et de prendre
          des mesures de sécurité supplémentaires, telles que l'utilisation de
          caractères spéciaux et de chiffres, pour renforcer la sécurité de son
          compte.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          4
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .3 Sécurité
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          La sécurité du compte de l'employeur est d'une importance capitale.
          Pour garantir la protection contre l'accès non autorisé, l'employeur
          doit mettre en œuvre toutes les mesures nécessaires. Cela inclut la
          mise à jour régulière du mot de passe, la restriction de l'accès aux
          personnes autorisées uniquement, et l'utilisation de technologies de
          sécurité appropriées.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          En cas de constatation d'une activité suspecte ou d'un accès non
          autorisé au compte, l'employeur doit en informer immédiatement la CNSS
          et suivre les procédures de sécurité recommandées. La coopération
          proactive de l'employeur est essentielle pour prévenir toute violation
          de la sécurité et assurer l'intégrité des données.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          5
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .&nbsp;
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Déclarations et paiements.
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          5
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .1 Approbation de la CNSS
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          L'approbation de la CNSS est une étape cruciale dans le processus de
          déclarations d'appels de cotisation. Avant que les cotisations ne
          soient officiellement enregistrées, elles doivent être examinées et
          approuvées par la CNSS. Voici les détails pour différents cas de
          figure :
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          5
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .1.1 Déclarations Correctes :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Lorsque l'employeur soumet une déclaration précise et conforme
          aux règles établies, la CNSS procède à l'approbation de manière
          efficiente. Une notification d'approbation est alors envoyée à
          l'employeur par e-mail, confirmant que les cotisations ont été
          acceptées.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          5
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .1.2 Erreurs ou Informations Manquantes :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Si la déclaration comporte des erreurs ou des informations
          manquantes, la CNSS notifiera l'employeur par e-mail du rejet de la
          déclaration. Les détails spécifiques des corrections nécessaires
          seront fournis afin que l'employeur puisse apporter les ajustements
          requis.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          5
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .2 Paiements
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Les paiements sont une composante essentielle du processus, et
          plusieurs options sont mises à la disposition des employeurs pour
          faciliter cette opération :
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          5
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .2.1 Virement Bancaire :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Les employeurs peuvent choisir de régler leurs cotisations par
          virement bancaire en utilisant les coordonnées bancaires fournies par
          la CNSS. Les références de paiement, qui identifient de manière unique
          chaque transaction, doivent être correctement mentionnées lors du
          virement pour assurer l'alignement avec les déclarations.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          5
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .2.2 D-
        </span>
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          Money&nbsp;
        </span>
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          &nbsp;et
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;
        </span>
        <span
          className="NormalTextRun SpellingErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlSpellingErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          Waafi
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Pour offrir une flexibilité accrue, les paiements peuvent
          également être effectués via D-Money, une solution de paiement mobile.
          Les employeurs doivent s'assurer de suivre les instructions
          spécifiques fournies par la CNSS pour cette méthode de paiement
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Le respect scrupuleux des instructions de paiement et l'utilisation
          correcte des références sont impératifs pour assurer la
          comptabilisation précise des cotisations. Tout paiement effectué avec
          des références incorrectes peut entraîner des retards dans le
          traitement et la validation par la CNSS.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          En cas de questions ou d'incertitudes concernant le processus
          d'approbation des déclarations ou les modalités de paiement, les
          employeurs sont invités à contacter le support technique de la CNSS
          pour une assistance appropriée.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            6
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .&nbsp;
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Obligations et responsabilité des banques et interfaces de
            paiement.&nbsp;
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            6
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1 Devoir et Respect des Droits
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Les banques et les interfaces de paiement jouent un rôle essentiel
            dans le processus de&nbsp;
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            télédéclaration
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;et télépaiement sur le Portail Web Employeur de la CNSS
            Djibouti. Leur intervention implique des responsabilités spécifiques
            envers les employeurs, qui sont détaillées comme suit pour chaque
            cas de figure :
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            6
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.1 Sécurité des Transactions :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Les banques et les interfaces de paiement sont tenues de
            mettre en place des mesures de sécurité robustes pour garantir la
            confidentialité et l'intégrité des transactions financières
            effectuées par les employeurs sur le portail. Cela inclut
            l'utilisation de protocoles de cryptage sécurisés et de mécanismes
            d'authentification fiables pour prévenir tout accès non autorisé.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            6
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.2 Confidentialité des Données Financières :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Les institutions financières doivent respecter la
            confidentialité des données financières des employeurs. Aucune
            information sensible ne doit être divulguée à des tiers sans le
            consentement explicite de l'employeur. Les données financières
            transmises via le portail doivent être traitées avec le plus grand
            soin et conformément aux normes de confidentialité en vigueur.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            6
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.3 Traitement Équitable des Paiements :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Les banques et les interfaces de paiement doivent s'assurer
            d'un traitement équitable des paiements effectués par les
            employeurs. Cela inclut la gestion efficace des délais de
            traitement, la confirmation rapide des transactions réussies, et la
            notification immédiate en cas d'échec de la transaction. Tout
            problème technique ou toute anomalie dans le processus de paiement
            doit être résolu de manière transparente et rapide.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            6.
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            1.4 Assistance en Cas de Litiges :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;En cas de litiges liés aux transactions ou aux paiements, les
            banques et les interfaces de paiement s'engagent à fournir une
            assistance diligente et à coopérer avec la CNSS Djibouti pour
            résoudre les problèmes. Une communication transparente et une
            résolution rapide des litiges contribuent à maintenir la confiance
            des employeurs dans le système de&nbsp;
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            télédéclaration
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;et télépaiement.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            6
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1.5 Conformité aux Normes Réglementaires :
          </span>
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Les banques et les interfaces de paiement doivent opérer en
            conformité avec les normes et réglementations financières en vigueur
            à Djibouti. Elles sont tenues de mettre à jour leurs systèmes et
            procédures pour rester conformes aux évolutions législatives et
            réglementaires, assurant ainsi la pérennité et la légalité des
            transactions effectuées par le biais du Portail Web Employeur.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            En acceptant d'utiliser le Portail Web Employeur, les employeurs
            reconnaissent l'importance de la collaboration avec les banques et
            les interfaces de paiement dans le respect de leurs droits. La CNSS
            Djibouti supervise cette collaboration pour garantir un
            environnement sécurisé, fiable et équitable pour toutes les parties
            impliquées.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          className="LineBreakBlob BlobObject DragDrop SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "WordVisiCarriageReturn_MSFontService, Garamond, Garamond_EmbeddedFont, Garamond_MSFontService, serif"
          }}
        >
          <span
            className="SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent",
              whiteSpace: "pre !important"
            }}
          >
            &nbsp;
          </span>
          <br
            className="SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent",
              whiteSpace: "pre !important"
            }}
          />
        </span>
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            7
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            . SUSPENSION ET IMMATRICULATION
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            7
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .1&nbsp;
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Les
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;demandes d’immatriculation et de suspension.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Les demandes de suspension et d'immatriculation sont des procédures
            essentielles que la CNSS prend en charge pour assurer la gestion
            efficace des comptes employeur sur le Portail Web. Pour garantir une
            gestion transparente et rapide de ces demandes, les points suivants
            sont à&nbsp;
          </span>
          <span
            className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent",
              backgroundRepeat: "repeat-x",
              backgroundPosition: "left bottom",
              backgroundImage:
                'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
              borderBottom: "1px solid transparent"
            }}
          >
            considérer:
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            7
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .2.
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Suspension
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;:
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            L'employeur peut soumettre une demande de suspension de son compte
            dans des circonstances spécifiques telles que la cessation
            temporaire d'activités. La demande doit être accompagnée des
            documents justificatifs nécessaires, et une explication claire des
            raisons de la suspension doit être fournie. La CNSS examinera la
            demande dans les plus brefs délais.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="ListContainerWrapper SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        position: "relative"
      }}
    >
      <ul
        className="BulletListStyle1 SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflow: "visible",
          cursor: "text",
          listStyleType: "disc",
          fontFamily: "verdana"
        }}
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid={3}
          data-list-defn-props='{"335552541":1,"335559684":-2,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
          aria-setsize={-1}
          data-aria-posinset={1}
          data-aria-level={1}
          className="OutlineElement Ltr SCXW143257488 BCX0"
          style={{
            margin: "0px 0px 0px 24px",
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            overflow: "visible",
            cursor: "text",
            clear: "both",
            position: "relative",
            direction: "ltr",
            display: "block",
            fontSize: "11pt",
            fontFamily: "Arial, Arial_MSFontService, sans-serif",
            verticalAlign: "baseline"
          }}
        >
          <p
            className="Paragraph SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
              fontWeight: "normal",
              fontStyle: "normal",
              verticalAlign: "baseline",
              fontKerning: "none",
              backgroundColor: "transparent",
              color: "windowtext",
              textAlign: "justify",
              textIndent: 0
            }}
          >
            <span
              data-contrast="auto"
              lang="FR-FR"
              className="TextRun SCXW143257488 BCX0"
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontVariantLigatures: "none !important",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
                fontWeight: "bold"
              }}
            >
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                Approbation :
              </span>
            </span>
            <span
              data-contrast="auto"
              lang="FR-FR"
              className="TextRun SCXW143257488 BCX0"
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontVariantLigatures: "none !important",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
              }}
            >
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                &nbsp;En cas d'approbation de la demande de suspension,
                l'employeur sera notifié par le biais du Portail Web Employeur,
                ainsi qu'à l'adresse e-mail enregistrée. Pendant la période de
                suspension, l'employeur n'aura pas accès à certaines
                fonctionnalités du portail, conformément aux conditions définies
                par la CNSS.
              </span>
            </span>
            <span
              className="EOP SCXW143257488 BCX0"
              data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
              }}
            >
              &nbsp;
            </span>
          </p>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid={3}
          data-list-defn-props='{"335552541":1,"335559684":-2,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
          aria-setsize={-1}
          data-aria-posinset={2}
          data-aria-level={1}
          className="OutlineElement Ltr SCXW143257488 BCX0"
          style={{
            margin: "0px 0px 0px 24px",
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            overflow: "visible",
            cursor: "text",
            clear: "both",
            position: "relative",
            direction: "ltr",
            display: "block",
            fontSize: "11pt",
            fontFamily: "Arial, Arial_MSFontService, sans-serif",
            verticalAlign: "baseline"
          }}
        >
          <p
            className="Paragraph SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
              fontWeight: "normal",
              fontStyle: "normal",
              verticalAlign: "baseline",
              fontKerning: "none",
              backgroundColor: "transparent",
              color: "windowtext",
              textAlign: "justify",
              textIndent: 0
            }}
          >
            <span
              data-contrast="auto"
              lang="FR-FR"
              className="TextRun SCXW143257488 BCX0"
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontVariantLigatures: "none !important",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
                fontWeight: "bold"
              }}
            >
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                Rejet :
              </span>
            </span>
            <span
              data-contrast="auto"
              lang="FR-FR"
              className="TextRun SCXW143257488 BCX0"
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontVariantLigatures: "none !important",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
              }}
            >
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                &nbsp;Si la demande de suspension est rejetée, l'employeur
                recevra une notification expliquant les raisons du rejet.
                L'employeur devra alors prendre les mesures nécessaires pour
                maintenir son compte en conformité avec les exigences de la
                CNSS.
              </span>
            </span>
            <span
              className="EOP SCXW143257488 BCX0"
              data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
              }}
            >
              &nbsp;
            </span>
          </p>
        </li>
      </ul>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
            fontWeight: "bold"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            7.
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            3
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            .
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;Immatriculation
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;:
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="OutlineElement Ltr SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflow: "visible",
        cursor: "text",
        clear: "both",
        position: "relative",
        direction: "ltr"
      }}
    >
      <p
        className="Paragraph SCXW143257488 BCX0"
        style={{
          margin: "0px 0px 10.6667px",
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          fontWeight: "normal",
          fontStyle: "normal",
          verticalAlign: "baseline",
          fontKerning: "none",
          backgroundColor: "transparent",
          color: "windowtext",
          textAlign: "justify",
          textIndent: 0
        }}
      >
        <span
          data-contrast="auto"
          lang="FR-FR"
          className="TextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontVariantLigatures: "none !important",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            Pour une immatriculation, l'employeur doit fournir les informations
            requises pour l'en
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            registrement de son entreprise
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            . Ces
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;informations&nbsp;
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            concerne
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            nt l’immatriculation du&nbsp;
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            personnel
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;de l’entreprise
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            , les changements d'adresse, ou toute autre information pertinente.
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            &nbsp;La CNSS examinera ces dem
          </span>
          <span
            className="NormalTextRun SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent"
            }}
          >
            andes pour assurer la précision des données dans le système.
          </span>
        </span>
        <span
          className="EOP SCXW143257488 BCX0"
          data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            fontSize: "11pt",
            lineHeight: "17.2667px",
            fontFamily:
              "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <div
      className="ListContainerWrapper SCXW143257488 BCX0"
      style={{
        margin: 0,
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        position: "relative"
      }}
    >
      <ul
        className="BulletListStyle1 SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          overflow: "visible",
          cursor: "text",
          listStyleType: "disc",
          fontFamily: "verdana"
        }}
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid={4}
          data-list-defn-props='{"335552541":1,"335559684":-2,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
          aria-setsize={-1}
          data-aria-posinset={3}
          data-aria-level={1}
          className="OutlineElement Ltr SCXW143257488 BCX0"
          style={{
            margin: "0px 0px 0px 24px",
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            overflow: "visible",
            cursor: "text",
            clear: "both",
            position: "relative",
            direction: "ltr",
            display: "block",
            fontSize: "11pt",
            fontFamily: "Arial, Arial_MSFontService, sans-serif",
            verticalAlign: "baseline"
          }}
        >
          <p
            className="Paragraph SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
              fontWeight: "normal",
              fontStyle: "normal",
              verticalAlign: "baseline",
              fontKerning: "none",
              backgroundColor: "transparent",
              color: "windowtext",
              textAlign: "justify",
              textIndent: 0
            }}
          >
            <span
              data-contrast="auto"
              lang="FR-FR"
              className="TextRun SCXW143257488 BCX0"
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontVariantLigatures: "none !important",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
                fontWeight: "bold"
              }}
            >
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                Approbation :
              </span>
            </span>
            <span
              data-contrast="auto"
              lang="FR-FR"
              className="TextRun SCXW143257488 BCX0"
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontVariantLigatures: "none !important",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
              }}
            >
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                &nbsp;Une fois que l'
              </span>
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                immatriculation
              </span>
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                &nbsp;est approuvée, l'employeur sera informé par le biais du
                Portail Web Employeur et par e-mail. L'accès complet aux
                fonctionnalités du portail sera rétabli conformément aux droits
                de l'employeur.
              </span>
            </span>
            <span
              className="EOP SCXW143257488 BCX0"
              data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
              }}
            >
              &nbsp;
            </span>
          </p>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid={4}
          data-list-defn-props='{"335552541":1,"335559684":-2,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
          aria-setsize={-1}
          data-aria-posinset={4}
          data-aria-level={1}
          className="OutlineElement Ltr SCXW143257488 BCX0"
          style={{
            margin: "0px 0px 0px 24px",
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            overflow: "visible",
            cursor: "text",
            clear: "both",
            position: "relative",
            direction: "ltr",
            display: "block",
            fontSize: "11pt",
            fontFamily: "Arial, Arial_MSFontService, sans-serif",
            verticalAlign: "baseline"
          }}
        >
          <p
            className="Paragraph SCXW143257488 BCX0"
            style={{
              margin: 0,
              padding: 0,
              userSelect: "text",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "transparent",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
              fontWeight: "normal",
              fontStyle: "normal",
              verticalAlign: "baseline",
              fontKerning: "none",
              backgroundColor: "transparent",
              color: "windowtext",
              textAlign: "justify",
              textIndent: 0
            }}
          >
            <span
              data-contrast="auto"
              lang="FR-FR"
              className="TextRun SCXW143257488 BCX0"
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontVariantLigatures: "none !important",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
                fontWeight: "bold"
              }}
            >
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                Rejet :
              </span>
            </span>
            <span
              data-contrast="auto"
              lang="FR-FR"
              className="TextRun SCXW143257488 BCX0"
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontVariantLigatures: "none !important",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
              }}
            >
              <span
                className="NormalTextRun SCXW143257488 BCX0"
                style={{
                  margin: 0,
                  padding: 0,
                  userSelect: "text",
                  WebkitUserDrag: "none",
                  WebkitTapHighlightColor: "transparent"
                }}
              >
                &nbsp;Si la demande d'immatriculation est rejetée, l'employeur
                sera notifié des raisons du rejet. Il devra prendre les mesures
                nécessaires pour corriger les informations ou fournir des
                documents supplémentaires conformément aux exigences de la CNSS.
              </span>
            </span>
            <span
              className="EOP SCXW143257488 BCX0"
              data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
              style={{
                margin: 0,
                padding: 0,
                userSelect: "text",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "transparent",
                fontSize: "11pt",
                lineHeight: "17.2667px",
                fontFamily:
                  "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
              }}
            >
              &nbsp;
            </span>
          </p>
        </li>
      </ul>
    </div>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          En engageant ces procédures, la CNSS vise à maintenir des informations
          précises et à jour, assurant ainsi une collaboration efficace entre
          les employeurs et l'organisme de sécurité sociale.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          8
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          . DROITS DE PROPRIÉTÉ INTELLECTUELLE
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          8
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .1
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Propriété
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Intellectuelle :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;La CNSS Djibouti détient tous les droits de propriété
          intellectuelle associés au contenu, aux fonctionnalités et à la
          conception du Portail Web Employeur. Ces droits comprennent, sans s'y
          limiter, les droits d'auteur, les marques de commerce, les brevets, et
          tout autre droit de propriété intellectuelle découlant de la
          conception et du développement du portail.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          8
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .2
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Utilisation
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Limitée :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;En acceptant ces conditions d'utilisation, l'employeur reconnaît
          que l'accès au Portail Web Employeur ne confère aucun droit de
          propriété intellectuelle sur le contenu, les fonctionnalités ou la
          conception du portail. L'employeur s'engage à utiliser le portail
          uniquement à des fins autorisées et conformément à ces conditions.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          8
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .3
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Interdictions :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Sauf autorisation expresse de la CNSS Djibouti, il est
          strictement interdit à l'employeur de copier, reproduire, distribuer,
          afficher, réaliser des travaux dérivés, ou exploiter de quelque
          manière que ce soit le contenu, les fonctionnalités ou la conception
          du portail. Toute violation de ces interdictions entraînera des
          mesures légales appropriées prises par la CNSS Djibouti pour protéger
          ses droits de propriété intellectuelle.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          8
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          4
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          &nbsp;
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          .
        </span>
        <span
          className="NormalTextRun ContextualSpellingAndGrammarErrorV2Themed SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "left bottom",
            backgroundImage:
              'var(--urlContextualSpellingAndGrammarErrorV2, url("data:image/svg+xml',
            borderBottom: "1px solid transparent"
          }}
        >
          Marques
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;de Commerce :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Les noms, logos, marques de service et autres identificateurs de
          la CNSS Djibouti utilisés sur le Portail Web Employeur sont des
          marques déposées et sont la propriété exclusive de la CNSS Djibouti.
          Aucune utilisation de ces marques n'est autorisée sans le consentement
          écrit préalable de la CNSS Djibouti.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          8
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .5
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Contenu des Utilisateurs :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;L'employeur conserve tous les droits de propriété intellectuelle
          sur le contenu qu'il soumet ou télécharge sur le portail. Cependant,
          en soumettant du contenu sur le portail, l'employeur accorde à la CNSS
          Djibouti une licence non exclusive, mondiale, libre de redevance,
          pouvant être sous-licenciée, pour utiliser, afficher, reproduire et
          distribuer ce contenu dans le cadre de la prestation des services du
          Portail Web Employeur.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          8
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .6
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Notification des Violations :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;Si un employeur constate une utilisation non autorisée ou une
          violation des droits de propriété intellectuelle liés au Portail Web
          Employeur, il est prié de le signaler à la CNSS Djibouti pour
          permettre des mesures correctives appropriées.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          En utilisant le Portail Web Employeur, l'employeur reconnaît et
          accepte les droits de propriété intellectuelle de la CNSS Djibouti et
          s'engage à les respecter intégralement.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        className="LineBreakBlob BlobObject DragDrop SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "WordVisiCarriageReturn_MSFontService, Garamond, Garamond_EmbeddedFont, Garamond_MSFontService, serif"
        }}
      >
        <span
          className="SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            whiteSpace: "pre !important"
          }}
        >
          &nbsp;
        </span>
        <br
          className="SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent",
            whiteSpace: "pre !important"
          }}
        />
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          9
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          . MODIFICATIONS DES CONDITIONS
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          La CNSS Djibouti se réserve le droit de modifier ces conditions
          d'utilisation afin de s'adapter aux évolutions législatives,
          réglementaires, techniques ou organisationnelles. Ces modifications
          peuvent également résulter de l'amélioration des fonctionnalités du
          portail ou de l'introduction de nouveaux services.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          9
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .1 Notification des Modifications :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;En cas de modification des conditions d'utilisation, la CNSS
          Djibouti s'engage à informer tous les employeurs enregistrés sur le
          Portail Web Employeur. Cette notification sera effectuée par le biais
          du portail, et une alerte ou un message spécifique sera affiché lors
          de la connexion à l'espace employeur.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          9
        </span>
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .2 Acceptation des Nouvelles Conditions :
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          &nbsp;En continuant à utiliser le Portail Web Employeur après la
          notification des modifications, vous acceptez automatiquement les
          nouvelles conditions d'utilisation. Il est de la responsabilité de
          l'employeur de consulter régulièrement les conditions d'utilisation
          pour être informé des éventuelles modifications.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          10. Droit Applicable et Juridiction
        </span>
      </span>
      <span
        data-contrast="none"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          color: "rgb(34, 34, 34)",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          fontWeight: "bold"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          .
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif",
          color: "rgb(34, 34, 34)"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>

  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Les présentes Conditions Générales d’Utilisations sont régies par la
          législation en vigueur à Djibouti.&nbsp;
        </span>
      </span>
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun Highlight SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          outline: "transparent solid 1px",
          fontVariantLigatures: "none !important",
          backgroundColor: "rgb(255, 255, 255)",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          En cas de litige, les parties s'engagent à résoudre la situation de
          manière amiable. Si aucune résolution n'est trouvée, les tribunaux
          compétents de Djibouti seront saisis.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Tout litige relatif à l'utilisation du portail sera soumis à la
          juridiction compétente de Djibouti.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          En acceptant cette charte, les utilisateurs et partenaires reconnaissent avoir pris connaissance des conditions et s'engagent à les respecter dans le cadre de l'utilisation du portail de la CNSS de Djibouti.
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
  <div
    className="OutlineElement Ltr SCXW143257488 BCX0"
    style={{
      margin: 0,
      padding: 0,
      userSelect: "text",
      WebkitUserDrag: "none",
      WebkitTapHighlightColor: "transparent",
      overflow: "visible",
      cursor: "text",
      clear: "both",
      position: "relative",
      direction: "ltr",
      color: "rgb(0, 0, 0)",
      fontFamily: '"Segoe UI", "Segoe UI Web", Arial, Verdana, sans-serif',
      fontSize: 12,
      fontStyle: "normal",
      fontVariantLigatures: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: 2,
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      widows: 2,
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      whiteSpace: "normal",
      backgroundColor: "rgb(255, 255, 255)",
      textDecorationThickness: "initial",
      textDecorationStyle: "initial",
      textDecorationColor: "initial"
    }}
  >
    <p
      className="Paragraph SCXW143257488 BCX0"
      style={{
        margin: "0px 0px 10.6667px",
        padding: 0,
        userSelect: "text",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "transparent",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight: "normal",
        fontStyle: "normal",
        verticalAlign: "baseline",
        fontKerning: "none",
        backgroundColor: "transparent",
        color: "windowtext",
        textAlign: "justify",
        textIndent: 0
      }}
    >
      <span
        data-contrast="auto"
        lang="FR-FR"
        className="TextRun SCXW143257488 BCX0"
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontVariantLigatures: "none !important",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        <span
          className="NormalTextRun SCXW143257488 BCX0"
          style={{
            margin: 0,
            padding: 0,
            userSelect: "text",
            WebkitUserDrag: "none",
            WebkitTapHighlightColor: "transparent"
          }}
        >
          Fait à Djibouti, le [Date].
        </span>
      </span>
      <span
        className="EOP SCXW143257488 BCX0"
        data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":259}'
        style={{
          margin: 0,
          padding: 0,
          userSelect: "text",
          WebkitUserDrag: "none",
          WebkitTapHighlightColor: "transparent",
          fontSize: "11pt",
          lineHeight: "17.2667px",
          fontFamily:
            "Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif"
        }}
      >
        &nbsp;
      </span>
    </p>
  </div>
</>

            </Card>
 
        </div>
    </div>
  );
}
export default Conditions;