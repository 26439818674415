// import axios from "axios";
import { api } from '../../index';
import React, { useState } from "react";
import { useEffect } from "react";
import { Alert, Badge, Button, Card, Col, FloatingLabel, Form, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import Header from "../../Components/Header";
import VerticalHeader from "../../Components/VerticalHeader";
import HorizontalHeader from "../../Components/HorizontalHeader";
import '../css/Dashboard.scss';
import SearchSalarie from "../Forms/SearchSalarie";
  
function Salaires() {

  const items = JSON.parse(localStorage.getItem('data'));
  const [show,setShow]=useState(false);
  const [dataSalary,setdataSalary]=useState([]);
  const [searchData,setSearchData]=useState([]);
  const [validated, setValidated] = useState(false);
  const [VendorAccountNumber,setVendorAccountNumber]=useState();
  const [VendorOrganizationName,setVendorOrganizationName]=useState();
  const [PersonBirthYear,setPersonBirthYear]=useState();
  const [SSMMotherName,setSSMMotherName]=useState();
  const [AssureSelected,setAssureSelected]=useState(false);
  const [MatriculeSelected,setMatriculeSelected]=useState();
  const [SSMIdentificationDocumentTypeId ,setSSMIdentificationDocumentTypeId ]=useState();
  const [SSMIdentificationDocumentValue,setSSMIdentificationDocumentValue]=useState();
  const [hideDetails, sethideDetails] = useState(false);
  const [btnDeclarer, setbtnDeclarer] = useState(false);
  const [data,setData]=useState();
  const [currentSort, setcurrentSort] = useState("default");
  const [searchVal, setSearchVal] = useState('');
  const [Message, setMessage] = useState("");

  const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api.get(`customer/${items.user.customer["CustomerAccount"]}/vendors`).then(async (response) => {
             setdataSalary(response.data.data);
        }) .finally(() => setLoading(false)); ;
        
    }, []); 

    const handleInputSearch = (e) => {
        setSearchVal(e.target.value);
    }

    let dataSalaryIds = [];
        dataSalary.filter((dec) => {
            dataSalaryIds.push(dec.VendorAccountNumber.toLowerCase(),dec.EmployeeExternalNum ? dec.EmployeeExternalNum.toLowerCase() :"",
                dec["vendor"]?dec["vendor"]["VendorOrganizationName"].toLowerCase():"",
                dec["vendor"]?dec["vendor"]["VendorOrganizationName"].toLowerCase():"");
        });
    const searchIn= dataSalaryIds.filter((dec) => {
        return dec.includes(searchVal.toLowerCase());
    });
    
    let dataSalaryArray = [];
        dataSalary.forEach(index => {
            if (searchIn.includes(index["vendor"]?index["vendor"]["VendorOrganizationName"].toLowerCase():"") || searchIn.includes(index["vendor"]?index["vendor"]["VendorOrganizationName"].toLowerCase():"") || searchIn.includes(index.VendorAccountNumber.toLowerCase()) || searchIn.includes(index.EmployeeExternalNum ? index.EmployeeExternalNum.toLowerCase() :"")) {
                dataSalaryArray.push(index)
            }
        })


    const showSearchF = () =>  {
         setShow(true)
         sethideDetails(false)
    }
    const back = () =>{
         window.location.href="/salaires"
    }
    const search = (event) => {
      {/**    const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        */}
        setbtnDeclarer(true);
        api.post('vendor/search', {
            VendorAccountNumber,VendorOrganizationName,PersonBirthYear,SSMMotherName,SSMIdentificationDocumentValue,SSMIdentificationDocumentTypeId
        }).then(async (response) => {
            if(response.data.data){
                setSearchData(response.data.data)   
              if((response.data.data).length > 0){
                setbtnDeclarer(true);
              }
            }
            
        }, (error) => {
        });
    
    }
    const SelectItem = (event) => { 
        setMatriculeSelected(event.target.getAttribute("data-m"))
      };
    const SelectAssure = () => { 
        if(MatriculeSelected){
            searchData.forEach(index => {
                    if(index["VendorAccountNumber"] === MatriculeSelected ){
                    setData(index)
                    setAssureSelected(true)
                    }
            })
        }

    };
    const RedirectToDemande = () => {
            window.location.href="/demandes"
            localStorage.setItem('imma', true);
            
    }

   
      const columns = [
        {
            name: 'Matricule assuré',
            selector: row => row.matricule_assure,
            sortable: true,
            width: "180px",
        },
        {
            name: 'Nom',
            selector: row => row.Nom,
            sortable: true,
            width: "220px",
        },
        {
            name: 'Date de naissance ',
            selector: row => row.date_birth,
            sortable: true,
        },
        {
            name: "Pièce d'identité",
            selector: row => row.piece_identite,
            sortable: true,
        },
        {
          name: 'Matricule interne',
          selector: row => row.matricule_interne,
          sortable: true,
        },
        {
          name: 'Poste',
          selector: row => row.poste,
          sortable: true,
        },
        {
          name: 'Salaire',
          selector: row => row.salaire,
          sortable: true,
        },  
        {
          name: 'Régime',
          selector: row => row.regime,
          sortable: true,
        },
        {
          name: 'Embauché le',
          selector: row => row.embauche,
          sortable: true,
        },
        {
          name: 'Débauché le',
          selector: row => row.debauche,
          sortable: true,
        }
      ];
    
    let salary = [];
    dataSalaryArray.map((index) => {
        salary.push({
            matricule_assure: index.VendorAccountNumber,
            Nom : index.vendor ?index.vendor.VendorOrganizationName:"-",
            date_birth: index.vendor ?index.vendor.date:"-",
            piece_identite : index.vendor?index.vendor.SSMIdentificationDocumentValue:"-",
            matricule_interne:index.EmployeeExternalNum,
            poste: index.HcmPositionType,
            salaire: index["Salary"].toLocaleString() ,
            regime : index.RegimeId,
            embauche: index.ValidFrom,
            debauche : index.ValidTo !== "2154-12-31"  ?index.ValidTo : "-",
          
          });
        });

        const columnsSearch = [
            {
              name: ' ',
              selector: row => row.id,
              sortable: false,
              class:"test3"
            },
            {
                name: 'Matricule',
                selector: row => row.matricule,
                sortable: true,
            },
            {
                name: 'Nom',
                selector: row => row.nom,
                sortable: true,
            },
            {
                name: "Nom de la Mére",
                selector: row => row.nom_mere,
                sortable: true,
            },
            {
                name: "Piéce d identité",
                selector: row => row.piece_identite,
                sortable: true,
            }
          ];
        let dataSearch = [];
        searchData&&searchData.map((index) => {
                dataSearch.push({
                    id: <Form.Check
                            inline
                            value={index.VendorAccountNumber} 
                            type="radio"
                            data-m={index.VendorAccountNumber} 
                            onChange={SelectItem} 
                            /> ,
                    matricule: index.VendorAccountNumber,
                    nom : index.VendorOrganizationName,
                    nom_mere:index.SSMMotherName ? index.SSMMotherName :"-",
                    piece_identite :index.SSMIdentificationDocumentValue ? index.SSMIdentificationDocumentValue : "-",
                
                });
            });
  return (
    <>
    <Header/>
        <Card className="mb-4 p-2 br-15 card-content">
            <VerticalHeader />
               <div className="parent m-2 salary-container">
                      <div className="align-right back">
                          <Button variant="light" onClick={back} className="mt-4 uppercase back-btn small align-left">
                              <img src="./assets/back.svg" /> Retour
                          </Button>
                      </div>
                      <h1 className="title-form">Mes salariés </h1>
                      <span className="subtitle-form sm">Consulter la liste des salariés, ajouter de nouveaux salariés. </span>
                      <div className={show ? "hide":""}>
                          <Form className="bar-top d-flex mt-4">
                              <Form.Control
                              type="search"
                              placeholder="Rechercher"
                              className="me-2 w-50 br-15 pl-1 p-1"
                              aria-label="Rechercher"
                              onChange={handleInputSearch}
                              value={searchVal}
                              />
                              <div className="btns-bar dark">
                                  <Button className="btn-100 bg-primary-1 salary br-15" onClick={showSearchF}> <img src="./assets/add.svg"/> Ajouter un nouveau salarie</Button>
                              </div>
                          </Form>
                          <Row className="mt-2 scrol-x-t">
                                    {loading ? (
                    <div class="loader">
  <div class="loader-wheel"></div>
  <div class="loader-text"></div>
</div>
                    ) : (
                          <DataTable
                                        columns={columns}
                                        data={salary}
                                        pagination
                                        highlightOnHover
                                        fixedHeader
                                        fixedHeaderScrollHeight="500px"
                                    />
                                    )}
                                    </Row> 
                          
                      </div>
                      <div className={show ? "":"hide"}>
                            <div className={AssureSelected  ? "hide":""}>
                                <Form >
                                    
                                    <Row className="form-row">
                                        <h5 className="mb-2 c-primary mt-4">Informations personnelles</h5>
                                        <Col sm={12} md={6}>
                                                <Form.Group >
                                                    <FloatingLabel  label="Matricule"  className="uppercase mb-3">
                                                        <Form.Control  type="text"  onChange={(e) => setVendorAccountNumber(e.target.value)}    />
                                                        <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel> 

                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Form.Group >
                                                    <FloatingLabel  label="Nom" className="uppercase mb-3" >
                                                        <Form.Control type="text"  onChange={(e) => setVendorOrganizationName(e.target.value)}    />
                                                        <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Form.Group  >
                                                    <FloatingLabel  label="Date de naissance" className="uppercase mb-3">
                                                        <Form.Control type="text"  onChange={(e) => setPersonBirthYear(e.target.value)}     />
                                                        <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Form.Group  >
                                                    <FloatingLabel  label="Nom de la Mére" className="uppercase mb-3">
                                                        <Form.Control  type="text"  onChange={(e) => setSSMMotherName(e.target.value)}    />
                                                        <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <h5 className="mb-2 c-primary">Piéce d identité</h5>
                                            <Col sm={12} md={6} className="m-0">
                                                <Form.Group >
                                                    <FloatingLabel  label="Type de piéce d identité" className="uppercase mb-3">
                                                        <Form.Control  type="text"  onChange={(e) => setSSMIdentificationDocumentTypeId(e.target.value)}   />
                                                        <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6} className="m-0">
                                                <Form.Group >
                                                    <FloatingLabel  label="piéce d identité" className="uppercase mb-3">
                                                        <Form.Control  type="text"  onChange={(e) => setSSMIdentificationDocumentValue(e.target.value)}  />
                                                        <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel> 
                                                </Form.Group>
                                            </Col>
                                            <div className="gap mb-4">
                                                <Button variant="success" onClick={search} className="succes mt-4 uppercase xs align-right br-30">
                                                        <BsSearch  className="icon-size" />  Rechercher 
                                                </Button>
                                            </div>
                                    </Row>
                                </Form>
                                {searchData&&searchData.length > 0 ? 
                                    <Row  className="mt-2  scrol-x-t">
                                            <DataTable
                                                columns={columnsSearch}
                                                data={dataSearch}
                                                pagination
                                                highlightOnHover
                                                fixedHeader
                                                fixedHeaderScrollHeight="400px"
                                            />
                                            <div className="gap mb-4">
                                                <Button variant="success" onClick={SelectAssure} className="succes mt-4 uppercase xs align-right br-30">
                                                        <BsSearch  className="icon-size" />  Valider 
                                                </Button>
                                            </div>
                                    </Row>
                                :""}
                                {btnDeclarer ? 
                                    <Button   onClick={RedirectToDemande} className="bg-primary-1  uppercase xs align-right br-30 dec-btn-salary" >
                                            <img src="./assets/add.svg"/>  Déclarer un(e) assuré(e)
                                    </Button>
                                :""}
                            </div>
                            <div className={AssureSelected ? "":"hide"}>
                                 <SearchSalarie data={data} setAssureSelected={setAssureSelected} />
                            </div>
                          
           
                      </div>
                   
               </div>
               <HorizontalHeader/>
            </Card>
    </>
  );
}
export default Salaires;