// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .HorizontalHeader {
    display: grid;
  }
  .HorizontalHeader .nav-tabs {
    order: 2;
  }
  .HorizontalHeader .tab-content {
    order: 1;
  }
  .HorizontalHeader .nav-pills .nav-link.active {
    background-color: unset;
    color: #f27e3f;
  }
  .HorizontalHeader .nav-pills .nav-link.active img.icon {
    display: none;
  }
  .HorizontalHeader .nav-pills .nav-link.active img.iconHover {
    display: block;
  }
  .horizontal-header {
    text-align: center;
    padding: 10px 5px;
    background: #45485f;
    border-radius: 15px;
    margin-left: 0;
    padding-bottom: 0px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 15px;
  }
  .horizontal-header img.icon, .horizontal-header img.iconHover {
    width: 80%;
  }
  .horizontal-header a.nav-link {
    padding: 0;
    margin: 0px 9px;
  }
  .horizontal-header.nav-pills .nav-link.active {
    background-color: unset;
    color: #f27e3f;
  }
  .horizontal-header.nav-pills .nav-link.active img.icon {
    display: none;
  }
  .horizontal-header.nav-pills .nav-link.active img.iconHover {
    display: block;
  }
  .horizontal-header.nav-pills .nav-link {
    background-color: unset;
  }
  .horizontal-header.nav-pills .nav-link img.icon {
    display: block;
  }
  .horizontal-header.nav-pills .nav-link img.iconHover {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .horizontal-header {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/vs-mobil/HorizontalHeader.scss"],"names":[],"mappings":"AACA;EACA;IACI,aAAA;EAAF;EACE;IACI,QAAA;EACN;EACE;IACI,QAAA;EACN;EACE;IACI,uBAAA;IACA,cAAA;EACN;EAAM;IACI,aAAA;EAEV;EAAM;IACI,cAAA;EAEV;EAIF;IACI,kBAAA;IACA,iBAAA;IACA,mBAAA;IACA,mBAAA;IACA,cAAA;IACA,mBAAA;IACA,eAAA;IACA,OAAA;IACA,SAAA;IACA,WAAA;IACA,iBAAA;EAFF;EAGE;IACG,UAAA;EADL;EAGE;IACI,UAAA;IACA,eAAA;EADN;EAKF;IACQ,uBAAA;IACA,cAAA;EAHN;EAIM;IACI,aAAA;EAFV;EAIM;IACI,cAAA;EAFV;EAKF;IACI,uBAAA;EAHF;EAIE;IACI,cAAA;EAFN;EAIE;IACI,aAAA;EAFN;AACF;AAMA;EACA;IACI,aAAA;EAJF;AACF","sourcesContent":["\n@media only screen and (max-width: 600px) {\n.HorizontalHeader{\n    display: grid;\n    .nav-tabs{\n        order: 2;\n    }\n    .tab-content{\n        order: 1;\n    }\n    .nav-pills .nav-link.active {\n        background-color: unset;\n        color: #f27e3f;\n        img.icon{\n            display:none;\n        }\n        img.iconHover{\n            display:block;\n        }\n    }\n\n}\n\n.horizontal-header{\n    text-align: center;\n    padding: 10px 5px;\n    background: #45485f;\n    border-radius: 15px;\n    margin-left: 0;\n    padding-bottom: 0px;\n    position: fixed;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    padding-top: 15px;\n    img.icon,img.iconHover{\n       width: 80%;\n    }\n    a.nav-link {\n        padding: 0;\n        margin: 0px 9px;\n    }\n}\n\n.horizontal-header.nav-pills .nav-link.active {\n        background-color: unset;\n        color: #f27e3f;\n        img.icon{\n            display:none;\n        }\n        img.iconHover{\n            display:block;\n        }\n    }\n.horizontal-header.nav-pills .nav-link {\n    background-color: unset;\n    img.icon{\n        display:block;\n    }\n    img.iconHover{\n        display:none;\n    }\n}\n}\n\n@media only screen and (min-width: 600px) {\n.horizontal-header{\n    display: none;\n}\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
