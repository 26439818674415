import React, { useEffect,useState } from 'react';
import './PaymentPage.css'; // Import the CSS file
// import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { FaLanguage, FaFlag } from 'react-icons/fa';

// Import JSON translations
import enTranslations from '../../locals/cac/en.json';
import frTranslations from '../../locals/cac/fr.json';
import { useParams } from 'react-router-dom';
import { api } from '../../index';


const PaymentPage = () => {
  const navigate = useNavigate();
  const { paymentid } = useParams();
  const paymentMethod = "Cac";
  const PieceIdentite=null;
  const [paymentRequestId, setPaymentRequestId] = useState(null); //THis it will contain paymentrequestID received from API call 1

  const [error, setError] = useState(null);

  const [amount, setAmount] = useState(null);
  const [orderNO, setOrderNo] = useState(null); 
  const baseurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchPaymentData = async () => {
      await  api.get(`customer/payment/${paymentid}`)
                .then(async (response) => {
                    setLoading(false);
                    setAmount(response.data.data.PaymAmount);
                    setOrderNo(response.data.data.PaymentId);
                    // if(response.data.data.url) window.location.href = response.data.data.url;
                },(error) => {
                    setLoading(false);
                    
                    console.log(error+"errrr");
                    // setError(error.response ? error.response.data.data.error : 'An error occurred');  //Render the Errorr 

                    window.location.href = "/paiements";

                    // setError(error.response.data.data.error);
            });
    };

    // Call the fetchPaymentData function when the component mounts
    fetchPaymentData();
  }, [paymentid, baseurl]);
  //--Show this instead of Render the vieww ( Payment page ) !
  if (error) {
    return (
      <div style={{ color: 'red', fontSize: 16 }}>
        <h1>{error === 'Unauthorized' ? 'You are not authorized to access this page.' : 'Payment expired.'}</h1>
        
      </div>
    );
  }
  //----
  const redirectToHomePage = () => {
    const isConfirmed = window.confirm('Are you sure you want to cancel payment?');
    if (isConfirmed) {
      navigate('/paiements');
    }
  };
  const [formData, setFormData] = useState(null);

  const [step, setStep] = useState(1); // Step 1 by default
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const [otp, setOtp] = useState('');


  const [language, setLanguage] = useState('en');
  const translations = language === 'en' ? enTranslations : frTranslations;

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'fr' : 'en');
  };

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };
  
  const handleSmsCodeSubmit = async (e) => {
    e.preventDefault();
    setAlert();
    setLoading(true);

    try {
      // Make the second API call
      const response = await fetch(baseurl+'verifycode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ otp , paymentid  }),
      });

      if (response.ok) {
        const responseData = await response.json();
        window.location.href = responseData.data.url;
        setAlert({ type: 'success', message: responseData.description || 'An error occurred' });
      } else {
        const responseData = await response.json(); 
        console.log(responseData);
        setAlert({ type: 'danger', message: responseData.data.error || 'An error occurred' });
      }
    } catch (error) {
      console.error('Error during API call:', error);
      setAlert({ type: 'danger', message: 'An error occurred during the API call' });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert();
    setLoading(true);
  
    try {
      const response = await fetch(baseurl+'initialise', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const responseData = await response.json();
  
      if (response.ok && responseData.paymentRequestId) {
        console.log(responseData.message);
        setPaymentRequestId(responseData.paymentRequestId.toString());

        setAlert();
        setStep(2);
      } else {
        console.log(responseData.message);
        const errorMessage = responseData.message || 'Badge id wrong';
        setAlert({ type: 'danger', message: errorMessage });
      }
    } catch (error) {
      console.error('Error during API call:', error);
      setAlert({ type: 'danger', message: 'An error occurred during the API call' });
    } finally {
      setLoading(false);
    }
  };
  
  
  return (
    <div className="limiter" style={{zIndex:'999'}}>
      <div className="container-login100">
      <div className="wrap-login100" >
  {/* Your existing content goes here */}

  {/* Add the button with absolute positioning */}
  {/* Switcher 1 design */}
  <div
  style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    backgroundColor: '#dae1f3',
    color: 'white',
    border: 'none',
    padding: '15px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease',
  }}
>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <button onClick={() => changeLanguage('fr')} className={language === 'en' ? 'inactive-lang' : 'active-lang'}>
      FR
    </button>
    <span style={{ margin: '0 8px' }}>|</span>
    <button onClick={() => changeLanguage('en')} className={language === 'en' ? 'active-lang' : 'inactive-lang'}>
      EN
    </button>
  </div>
  {/* 
  <img
    width="28"
    height="28"
    style={{ margin: '0px 8px' }}
    src={language === 'en' ? 'https://img.icons8.com/color/48/france.png' : 'https://img.icons8.com/color/48/usa.png'}
    alt={language === 'en' ? 'usa' : 'fr'}
  /> 
  */}
</div>


          <div className="login100-form validate-form">
          
            {step === 1 && (
            <form className="step1" onSubmit={handleSubmit}>
            <span className="login100-form-title">{translations.initializePayment}</span>
            <div className="wrap-input100 validate-input" data-validate="Badge ID is required" style={{ padding: 5 }}>
              {alert && (
                <div className={`alert alert-${alert.type}`} style={{ marginBottom: '10px' }} role="alert">
                  {alert.message}
                </div>
              )}
              <input
                className="input100"
                type="text"
                name="BagdeID"
                required
                placeholder={translations.badgeIdLabel}
                onChange={(e) => setFormData({ ...formData, BagdeID: e.target.value ,paymentid:paymentid})}
              />
              <span className="focus-input100" />
            </div>
            <div className="container-login100-form-btn">
              {/* Apply a different style for loading state */}
              <button className={`login100-form-btn ${loading ? 'loading-btn' : ''}`} disabled={loading}>
                {loading ? 'Loading...' : translations.PaybtnText}
              </button>
            </div>
          </form>
)}
{step === 2 && (
             <div class="step2">
						
						<span class="login100-form-title">
							

							{translations.otpVerification}
              
						</span>
            <div style={{padding:'5px'}}>

						{alert && (
                      <div className={`alert alert-${alert.type}`} role="alert">
                        {alert.message}
                      </div>
                    )}
            </div>
					
            <form onSubmit={handleSmsCodeSubmit}>
      <div className="wrap-input100 validate-input" data-validate="OTP Code is required">
        <input
          className="input100"
          type="number"
          name="pass"
          placeholder="OTP Code"
          
          onChange={(e) => setOtp(e.target.value)}
        />
        <span className="focus-input100" />
        <span className="symbol-input100">
          <i className="fa fa-lock" aria-hidden="true" />
        </span>
      </div>
      <span>{translations.otpdesc}</span>
      <div className="container-login100-form-btn">
        {/* Apply a different style for loading state */}
        <button className={`login100-form-btn ${loading ? 'loading-btn' : ''}`} disabled={loading}>
        {loading ? translations.verifying : translations.verifyButtonText}

        </button>
      </div>
    </form>

					</div> 

          )}
            <div className="text-center p-t-136">
            <a className="txt2" href="#" onClick={redirectToHomePage}>
        <i className="fa fa-long-arrow-left m-l-5" aria-hidden="true" />
        {translations.cancelPayment}
      </a>
      
            </div>
          </div>
          <div className="login100-pic">
          <span className="login100-form-title">
              <div
                style={{
                  fontFamily: '"Arial", sans-serif',
                  padding: 15
                }}
              >
                
                <img  src="../assets/cac_horiz.png" alt="IMG" />
                <hr style={{margin:'20px 0px '}}></hr>
                <h6 style={{ color: "#333", fontSize: 18, marginBottom: 10 }}>
                {translations.orderDetails}
                </h6>
                <p style={{ margin: 0, color: "#555", fontSize: 17 }}>
                {translations.orderNoLabel}: <b>{orderNO}</b>
                </p>
                <p style={{ margin: 0, color: "#555", fontSize: 17 }}>
                {translations.amountLabel}: <b>{amount} DJF</b>
                </p>
               
                
                <hr style={{margin:'20px 0px '}}></hr>            
                <img  variant="top" src="../assets/cnss-logo.svg" /> 
                
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

  );
};

export default PaymentPage;
