// import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Alert, Badge, Button,  Card,  Form,  Row,  Table, Tabs } from "react-bootstrap";
import { BsFillCheckCircleFill, BsFillExclamationCircleFill, BsPrinter, BsSearch, BsXCircleFill } from "react-icons/bs";
import '../css/Dashboard.scss';
import '../css/Declarations.scss';
import AddDeclaration from "../Forms/AddDeclaration";
import AddPayment from "../Forms/AddPayment";
import DataTable from "react-data-table-component";
import VerticalHeader from "../../Components/VerticalHeader";
import HorizontalHeader from "../../Components/HorizontalHeader";
import Header from "../../Components/Header";
import { api } from '../../index';
  
function Declaratinons() {
  const tableAdmin= [    
    { description:"lorem lorem lorem lorem lorem lorem lorem ",periode: "Mes paiements", dateDeclaration: "test role", datePayment: "role",regime: "test",statutPayment:"paye"},
    { description:"lorem lorem lorem lorem lorem lorem lorem ",periode: "Mes paiements", dateDeclaration: "test role", datePayment: "role",regime: "test",statutPayment:"paye"},
    { description:"lorem lorem lorem lorem lorem lorem lorem ",periode: "Mes paiements", dateDeclaration: "test role", datePayment: "role",regime: "test",statutPayment:"paye"},
    { description:"lorem lorem lorem lorem lorem lorem lorem ",periode: "Mes paiements", dateDeclaration: "test role", datePayment: "role",regime: "test",statutPayment:"paye"},
    { description:"lorem lorem lorem lorem lorem lorem lorem ",periode: "Mes paiements", dateDeclaration: "test role", datePayment: "role",regime: "test",statutPayment:"paye"},
  ]
    const items = JSON.parse(localStorage.getItem('data'));
    const [showDec,setShowDec]=useState(false);
    const [showCotis,setCotis]=useState(false);
    const [historySolde,sethistorySolde]=useState(false);
    const [Declarations,setDeclarations]=useState([]);
    const [notPayed,setnotPayed]=useState(false);
    const [enablePayBtn,setEnablePayBtn]=useState(false);
    const [checkedList,setcheckedList]=useState([]);
    const [checkedListCst,setcheckedListCst]=useState([]);
    const [NotPayedList,setNotPayedList]=useState([]);
    const [showAlert, setshowAlert] = useState(false);
    const [AlertDeclarer, setAlertDeclarer] = useState(false);
    const [ItemSelected, setItemSelected] = useState("");
    const [currentSort, setcurrentSort] = useState("default");
    const [searchVal, setSearchVal] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleInputSearch = (e) => {
          setSearchVal(e.target.value);
        }

    let ContributionCallIds = [];
            Declarations.filter((dec) => {
                ContributionCallIds.push(dec.ContributionCallId.toLowerCase(),dec.SSMCalendarPeriod_Name.toLowerCase());
            });
             
      const searchIn= ContributionCallIds.filter((dec) => {
           return dec.includes(searchVal.toLowerCase());
       });
       let DeclarationArray = [];
            Declarations.forEach(index => {
                if (searchIn.includes(index["ContributionCallId"].toLowerCase()) || searchIn.includes(index["SSMCalendarPeriod_Name"].toLowerCase() )) {
                    DeclarationArray.push(index)
                }
            })
    
        const back = () =>{
            localStorage.removeItem("general");
            localStorage.removeItem("assure");
            localStorage.removeItem("id");
            setcheckedList([])
            setcheckedListCst([])
            setNotPayedList([])
            setShowDec(false);
            setCotis(false);
            sethistorySolde(false)
            setnotPayed(false)
        }

       
        const handleChange = ({ selectedRows }) => {
            let selected = [];
            let selectedConsulter = []
            let NotPayed = [];
            selectedRows.forEach(element => {
                    selectedConsulter.push(element.id)
               if(element.status === "Created" || element.status === "InDelay" || element.status === "Rejected"   ){
                    selected.push(element.id)
               }
               else if(element.class === "NotPayed"){
                    NotPayed.push(element.id)
              }
            });
            setcheckedList(selected);
            setcheckedListCst(selectedConsulter);
            setNotPayedList(NotPayed);
            if(NotPayed.length == 0){
                setEnablePayBtn(false);
            }else{
                setShowSuccess(false);
                setEnablePayBtn(true);
            }
     
        }
        const showAddDec = () =>  {
           
                if(checkedList.length == 1 && checkedListCst.length == 1){
                  
                    setItemSelected(checkedList[0] )
                    
                    {Declarations.map((index)=> (
                        (index["ContributionCallId"] === checkedList[0] ? localStorage.setItem('general', JSON.stringify(index)):"")
                    ))}
                   
                        setShowDec(true);
                        sethistorySolde(false)
                        setCotis(false);
                        setAlertDeclarer(false);
                        setcheckedListCst([])
                }else if(checkedList.length > 1 || checkedListCst.length > 1){
                     setAlertDeclarer(true);
                }
                
        }
        const showAppelCotis = () =>  {
                if(checkedListCst.length == 1){
                    setItemSelected(checkedListCst[0])
                {Declarations.map((index)=> (
                    (index["ContributionCallId"] === checkedListCst[0]    ? localStorage.setItem('general', JSON.stringify(index)):"")
                ))}
                    setShowDec(false);
                    sethistorySolde(false)
                    setCotis(true);
                    setshowAlert(false);
                    setcheckedList([])
                }else{
                    setshowAlert(true);
                }
        }

      

        const columns = [
            {
                name: 'Appel de cotisation',
                selector: row => row.Ap,
                sortable: true,
                id:"test1"
            },
            {
                name: 'Période',
                selector: row => row.Periode,
                sortable: true,
            },
            {
                name: 'Date de déclaration',
                selector: row => row.Date_de_declaration,
                sortable: true,
            },
            {
                name: 'Date de paiement',
                selector: row => row.Date_de_paiement,
                sortable: true,
            },
            {
                name: 'Régime',
                selector: row => row.Regime,
                sortable: true,
            },
            {
                name: 'Statut',
                selector: row => row.Status,
                sortable: true,
            },
            {
                name: 'Statut de paiement',
                selector: row => row.Statut_de_paiement,
                sortable: true,
            }
        ];
        let data = [];
        DeclarationArray.map((dec) => {
              
              data.push({
                id: dec['ContributionCallId'],
                Ap: dec['ContributionCallId'],
                Periode: dec['SSMCalendarPeriod_Name'],
                Date_de_declaration : dec['DeclarationDate'] === "2154-12-31" ? "-" :
                 dec['DeclarationDate'] === "1900-01-01" ? "-"  : dec['DeclarationDate'],
                Date_de_paiement: dec['PaymDate'] === "2154-12-31" ? "-" :
                dec['PaymDate'] === "1900-01-01" ? "-"  : dec['PaymDate'],
                Status: dec["Status"] === "Created" ? "Créé" :
                dec["Status"] === "Canceled" ? "Annulé" :
                dec["Status"] === "Rejected" ? "Rejeté" :
                dec["Status"] === "Close" ? "Cloturé" :
                dec["Status"] === "Declared" ? "Déclaré" :
                dec["Status"] === "WaitingPortalApproval" ? "En attente d'approbation" :
                dec["Status"] === "InDelay" ? "En retard" :"-",
                Regime: dec['SSMRegime_RegimeId'],
                Statut_de_paiement: dec['PaymentStatus'] === "PaymentInProcess" ? 
                <Badge pill  bg="secondary" className="p-2" > Paiement en cours</Badge>:
                dec['PaymentStatus'] === "NotPayed" ? 
                <Badge pill className="p-2 badge-warning" ><BsXCircleFill className="icon-size"/> Non payé</Badge>:
                dec["PaymentStatus"] === "Payed" ?
                 <Badge pill className="p-2 badge-succes"><BsFillCheckCircleFill className="icon-size"/> Payé</Badge> :"-",
                class: dec["PaymentStatus"],
                status:dec["Status"]
              
              });
            });
        
        const showSolde = () =>  {
            setCotis(false);
            setShowDec(false);
            sethistorySolde(true)
        }
    //get contributioncalls
        useEffect(() => {
            setLoading(true);
            api.get(`customer/${items.user.customer["CustomerAccount"]}/contributioncalls`).then(async (response) => {
                 setDeclarations(response.data.data);
            }) .finally(() => setLoading(false)); ;
        }, []); 


  return (
    <>
       <Header/>
        <Card className="mb-4 p-2 br-15 card-content">
            <VerticalHeader />
          
                  <div className="parent m-2 w-100">
                        <div className="align-right back">
                              <Button variant="light" onClick={back} className="mt-4 uppercase back-btn small align-left">
                                  <img src="./assets/back.svg" /> Retour
                              </Button>
                        </div>
                        {notPayed ?
                            <div>
                                    <h1 className="title-form">Mes paiements </h1>
                                    <span className="subtitle-form sm">Effectuer un paiement, voir statut des paiements, consulter les paiements antérieurs. </span>
                                    <Row className="mt-2">
                                           <AddPayment  notPayedList={NotPayedList} showSuccess = {showSuccess} setShowSuccess = {setShowSuccess}/>
                                    </Row>
                            </div>
                            
                        : <div>
                                <h1 className="title-form">Mes déclarations </h1>
                                <span className="subtitle-form sm">Consulter, vérifier et effectuer mes déclarations périodiques. </span>
                                <Form className={showDec || showCotis ? "bar-top mt-4 hide":"bar-top d-flex mt-4"}>
                                        <Form.Control
                                            type="search"
                                            placeholder="Rechercher"
                                            className="me-2 w-50 br-15 pl-1 p-1"
                                            aria-label="Rechercher"
                                            icon={<BsSearch/>}
                                            onChange={handleInputSearch}
                                            value={searchVal}
                                            />
                                            <div className="btns-bar dark desktop">
                                                <Button variant="outline-warning" className="br-15 p-1 orange hide" onClick={showSolde}><img src="./assets/solde.svg"/> Solde de déclaration</Button>
                                                <Button  className={(checkedList.length == 1 && checkedListCst.length == 1)?"bg-primary-1 br-15":"bg-gray br-15"}  onClick={showAddDec}> <img src="./assets/add.svg"/> Déclarer</Button>
                                                <Button className="bg-primary-1 br-15" onClick={showAppelCotis}> <img src="./assets/edit.svg"/> Consulter</Button>
                                                <Button className={enablePayBtn ? "br-15 paye" :"br-15 paye disabled"}  data-t="left-tabs-example-tab-paiements" onClick={() => setnotPayed(true)}>  <BsPrinter className="icon-size"  />  Payer</Button>
                                            </div>
                                            <div className="btns-bar mobil">
                                                <div className=" btns-declaration first hide" onClick={showSolde}><img src="./assets/solde.svg"/> <span>Historique</span></div>
                                                <div  className="bg-primary-1 btns-declaration second"  onClick={showAddDec}> <img src="./assets/add.svg"/> <span>Déclarer</span></div>
                                                <div className="bg-primary-1  btns-declaration second" onClick={showAppelCotis}> <img src="./assets/edit.svg"/> <span>Consulter</span></div>
                                                <div className={enablePayBtn ? "btns-declaration last download" :" btns-declaration last download disabled"} onClick={() => setnotPayed(true)}> <BsPrinter className="icon-size"  /><span>Payer</span></div>
                                            </div>
                                </Form>
                                
                                {showDec ?
                                <Row className="mt-2">
                                    <AddDeclaration Add={true} ItemSelected={ItemSelected}/>
                                </Row> 
                                :showCotis ? 
                                <Row className="mt-2">
                                    <AddDeclaration  Add={false}  ItemSelected={ItemSelected}/>
                                </Row> 
                                :historySolde ? 
                                <Row className="mt-2  scrol-x-t">
                                    <Table className="table-adress" hover >
                                        <thead>
                                            <tr> 
                                                    <th width="35%">Appel de cotisation</th>
                                                    <th width="25%">Montant total du paiement</th>
                                                    <th width="25%">Date du paiement </th>
                                                    <th width="15%  ">Piece jointe</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableAdmin.map((data)=> (
                                            
                                            <tr key={data}>
                                                <td> {data.description}</td>
                                                <td>{data.description}</td>
                                                <td>{data.description}</td>
                                                <td><Badge className="p-2 badge-info"><BsFillExclamationCircleFill/> {data.description} </Badge></td>
                                                
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Row> 
                                :<div>
                                {showAlert ? 
                                    <Alert  variant="danger" className="mt-1">
                                        Merci de  selectionner un seul  AP
                                    </Alert>
                                :" "}
                                 {AlertDeclarer ? 
                                    <Alert  variant="danger" className="mt-1">
                                        Merci de sélectionner un seul AP avec un statut créé ou en retard pour pouvoir déclarer .
                                    </Alert>
                                :" "}
                                <Row className="mt-2 scrol-x-t">
                                    {loading ? (
                    <div class="loader">
  <div class="loader-wheel"></div>
  <div class="loader-text"></div>
</div>
                    ) : (
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    selectableRows
                                    onSelectedRowsChange={handleChange}
                                    pagination
                                    highlightOnHover
                                    fixedHeader
                                    fixedHeaderScrollHeight="500px"
                                />
                                )}
                                </Row>
                                </div>
                                } 
                          </div>
                          }
                        
                    </div>
                    <HorizontalHeader />
        </Card>
    </>
  );
}
export default Declaratinons;