import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import Header from "../../../Components/Header";
import '../Connexion.scss';
import '../vs-mobil/Auth-mobil.scss';
import { BsEmojiFrown } from "react-icons/bs";
  
function VerifyMail() {

  
  
  
   const urlParams = new URLSearchParams(window.location.search);
   const login = urlParams.get('login')
   const isverified = urlParams.get('isverified')
   const base_url = process.env.REACT_APP_URL;
  return (
    <div id="app">
        <Header/>
        <div className="justify-content-center m-2">
            <div className="align-center mt-4r">
                 <img  variant="top" src="/assets/cnss-logo.svg" /> 
            </div>
            <Card className="login-card margin-auto mt-4 mb-4">
                <Row className="h-100 m-0">
                    <Col className="p-0 m-0" xs={12} md={6}>
                        <div className="left-grid m-0"> <Card.Img  variant="top" src="/assets/login.png" /> </div>
                    </Col>
                    <Col  style={{marginTop:"15%"}}>
                        <div className="right-grid align-center pt-2">
                            <h1 className="title-form"> Validation de compte</h1>
                            <p className="subtitle-form sm"> 
                              {isverified == 0 ?
                              <div>
                                    <p>verification expire </p>
                                    <BsEmojiFrown className="sadImotc"/> 
                              </div> :
                              isverified == 1 ?
                              <div>
                                    <p> Votre compte est confirmé, nous vous prions de <a className="upload-url" href={`${base_url}/create-pdf-file/${login}`}> télécharger votre formulaire d’inscription </a> <br/> et le déposer avec les documents requis dans votre agence CNSS pour activer votre accès. </p>
                                    <img src="/assets/success.svg" className="mt-10" />
                              </div>:
                              <div>
                                    <p>deja verifier </p>
                                    <img src="/assets/success.svg" className="mt-10" />
                               </div>
                               } 
                            </p>
                            
                        </div>
                        <div className="align-center">
                            <Button  className="valider mt-4 uppercase btn  xs" href="/">
                                            Continuer <img src="/assets/verified.svg"/>
                            </Button>
                        </div>
                    </Col>
               
                
                </Row>
            </Card>
 
        </div>
    </div>
  );
}
export default VerifyMail;