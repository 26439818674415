// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf {
  position: fixed;
  padding-top: 10% !important;
  margin: 0;
}
.pdf .paragraph {
  font-size: 14px;
  padding-right: 1%;
  padding-left: 10px;
}
.pdf .table {
  width: 81%;
}
.pdf .card-matricule {
  line-height: 2rem;
  border: 2px solid black;
  padding: 10px;
  font-size: 14px;
}
.pdf .card-matricule.padding {
  padding-right: 4px;
}
.pdf .pr-15 {
  padding-right: 13%;
}
.pdf .pr-20 {
  padding-right: 20%;
}
.pdf .border-t {
  border-top: 2px solid;
}
.pdf .border-3 {
  border: 2px solid;
  border-right: unset;
}
.pdf tr.declarer, .pdf th {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.pdf tr.payment {
  border: unset;
}
.pdf td {
  font-size: var(--xs);
}
.pdf table {
  border: #000;
}
.pdf .padding-toRight {
  padding: 10px 10px 10px 60px;
}`, "",{"version":3,"sources":["webpack://./src/pages/css/PDF.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACC,2BAAA;EAEA,SAAA;AAAJ;AACI;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;AACR;AAEI;EACI,UAAA;AAAR;AAEI;EACI,iBAAA;EACA,uBAAA;EACA,aAAA;EACA,eAAA;AAAR;AAGI;EACI,kBAAA;AADR;AAGI;EACI,kBAAA;AADR;AAGI;EACI,kBAAA;AADR;AAGI;EACI,qBAAA;AADR;AAGI;EACI,iBAAA;EACA,mBAAA;AADR;AAGI;EACG,6BAAA;EACA,4BAAA;AADP;AAGI;EACE,aAAA;AADN;AAGI;EACI,oBAAA;AADR;AAGI;EACI,YAAA;AADR;AAGI;EACI,4BAAA;AADR","sourcesContent":[".pdf{\n   position: fixed;\n    padding-top: 10% !important;\n  //  width: 70%;\n    margin: 0;\n    .paragraph{\n        font-size: 14px;\n        padding-right: 1%;\n        padding-left:10px;\n        \n    }\n    .table{\n        width: 81%;\n    }\n    .card-matricule{\n        line-height: 2rem;\n        border: 2px solid black;\n        padding: 10px;\n        font-size: 14px;\n    \n    }\n    .card-matricule.padding{\n        padding-right: 4px;\n    }\n    .pr-15{\n        padding-right: 13%;\n    }\n    .pr-20{\n        padding-right: 20%;\n    }\n    .border-t{\n        border-top: 2px solid;\n    }\n    .border-3{\n        border: 2px solid;\n        border-right: unset;\n    }\n    tr.declarer, th {\n       border-bottom: 1px solid #000;\n       border-right: 1px solid #000; \n    }\n    tr.payment {\n      border: unset;\n     }\n    td{\n        font-size: var(--xs);\n    }\n    table{\n        border: #000;\n    }\n    .padding-toRight{\n        padding: 10px 10px 10px 60px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
