import React from "react";
import { Button, Table } from "react-bootstrap";
import Pdf from "react-to-pdf";
import { useBarcode } from 'react-barcodes';
import './css/PDF.scss';

const ref = React.createRef();

function App() {
    const salaries = JSON.parse(localStorage.getItem('salaries'));
    const informatinos = JSON.parse(localStorage.getItem('general'));

    const { inputRef } = useBarcode({
        value: informatinos["ContributionCallId"],
        options: {
          background: '#ffff',
        }
      });
  return (
    <div>
      <Pdf targetRef={ref} filename="code-example.pdf">
        {({ toPdf }) => <Button variant="light" className=" mt-4 uppercase xs align-right download br-30" onClick={toPdf}>Imprimer</Button>} 
      </Pdf>
        <div ref={ref} className="pdf p-2 m-0">
            <div className="flex uppercase">
                <div className="m-0 f-w pr-20">
                        <p>Caisse Nationale De securite Sociale</p>
                        <p>BP 696 - djibouti - tel : 35 21 62</p>
                        <p>Releve nominatif des salaires et </p>
                        <p>Declaration des salaires percus</p>
                </div>
                <div className="m-0">
                    <div><svg className="barcode" ref={inputRef} /></div>
                </div>
            </div>
            <div className="flex mt-3">
                <div className="m-0 paragraph">
                        <p className="uppercase">periode du : ----- Au: --------- exigible le : -------------</p>
                        <p className="uppercase">Toute somme non payee ................................................................ sera majoree</p>
                        <p>(avec les majorations et pénalités décrites au verso)</p>
                        <p className="uppercase">Le reglement des cotisations peut s effectuer : </p>
                        <p className="ml-4">1. Par versement direct a la caisse </p>
                        <p>2. Par chéque bancaire </p>
                        <p>3. Par virement a l un des comptes suivants :  </p>
                        <p className="uppercase err">. tresor N° 430 / b.c.i.n n° 570436 262 001 19 / b.a.o n° 012087 3000 / i.c.b n° 02/ 740 / 15 </p>
                </div>
                <div className="m-0 card-matricule padding">
                    <div>Matricule : {informatinos["ContributionCallId"]}</div>
                    <div>Nom/Raison Sociale : {informatinos["ContributionCallId"]} </div>
                    <div>Adresse : test</div>
                    <div>Tél : 00000 </div>
                </div>
            </div>
            <div className="flex mt-3">
                <div className="m-0 paragraph pr-20 border-3">
                        <p className="uppercase align-center f-w">Cadre a remplir par l employeur</p>
                        <p className="uppercase mt-4">Salaire bruts déclarés (plafonnée) ...............</p>
                        <p className="uppercase">Salaire bruts déclarés (déplafonnée) .................</p>
                        <p>Cotisations a verser (taux 21,7 %) ...........</p>
                        <p>Nombre de salariés :  {salaries ? salaries.length :"......." }</p>
                </div>
                <div className="m-0 card-matricule border-t">
                    <div>Date ...............</div>
                    <div className="uppercase err">Signature & cachet de l employeur </div>
                </div>
            </div> 
            <div className="flex mt-3">
                <Table className="m-0" bordered>
                    <thead>
                        <tr className="f-xs uppercase declarer">
                            <th>nom/prenom du salarie</th>
                            <th>matricule du salaire</th>
                            <th>periode d emploi</th>
                            <th> salaire limite au plafond</th>
                            <th width="10">salaire total</th>
                            <th width="10">categorie profession</th>
                            <th>observation</th>
                         </tr>
                    </thead>
                    <tbody>
                        {salaries&&salaries.map((data)=> (                     
                            <tr className="declarer">
                                <td>{data.EmployeeName}</td>
                                <td>{data.VendorAccountNumber}</td>
                                <td> DU ...... <br/>AU ...... </td>
                                <td>{data.VendorAccountNumber}</td>
                                <td>{data.DeclaredSalary}</td>
                                <td>{data.ContributionTypeId}</td>
                                <td>{data.SSMIdentificationDocumentValue ? data.SSMIdentificationDocumentValue : "............" }</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>  
        </div>
    </div>
  );
}

export default App;
