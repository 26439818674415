// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .login-card {
    width: 100%;
    height: 100%;
    padding: 5px;
    padding-bottom: 20%;
  }
  .left-grid {
    width: 100%;
  }
  .left-grid img {
    width: 50%;
    margin: 20% 20%;
  }
  .right-grid {
    padding: 0px;
  }
  .pad-form {
    padding: 0px 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Authentification/vs-mobil/Auth-mobil.scss"],"names":[],"mappings":"AACA;EACI;IACI,WAAA;IACA,YAAA;IACA,YAAA;IACA,mBAAA;EAAN;EAEE;IACI,WAAA;EAAN;EACM;IACI,UAAA;IACA,eAAA;EACV;EAEE;IACI,YAAA;EAAN;EAEE;IACI,iBAAA;EAAN;AACF","sourcesContent":["\n@media only screen and (max-width: 600px) {\n    .login-card {\n        width: 100%;\n        height: 100%;\n        padding: 5px;\n        padding-bottom: 20%;\n    }\n    .left-grid{\n        width: 100%;\n        img{\n            width: 50%;\n            margin :20% 20%;\n        }\n    }\n    .right-grid {\n        padding: 0px;\n    }\n    .pad-form{\n        padding: 0px 15px;\n    }\n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
