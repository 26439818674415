// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-radio {
  display: flex;
  font-size: var(--large);
  gap: 5px;
  font-weight: 600;
}
.container-radio .form-check-input:checked {
  background-color: #0da781;
  border-color: #0da781;
}
.container-radio span {
  color: #0da781;
  font-size: var(--medium);
}

.Employeur-tabs .form-floating .form-control {
  background-color: green;
}`, "",{"version":3,"sources":["webpack://./src/pages/Employeur.scss"],"names":[],"mappings":"AAAA;EACK,aAAA;EACA,uBAAA;EACA,QAAA;EACA,gBAAA;AACL;AAAI;EACI,yBAAA;EACA,qBAAA;AAER;AAAI;EACI,cAAA;EACA,wBAAA;AAER;;AACA;EACI,uBAAA;AAEJ","sourcesContent":[".container-radio{\n     display: flex;\n     font-size: var(--large);\n     gap: 5px;\n     font-weight: 600;\n    .form-check-input:checked {\n        background-color: #0da781;\n        border-color: #0da781;\n    }\n    span{\n        color:#0da781 ;\n        font-size: var(--medium);\n    }\n}\n.Employeur-tabs .form-floating .form-control{\n    background-color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
