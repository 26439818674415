
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PaymentPage from './pages/cac/PaymentPage';
import SabaPayment from './pages/SabaPayment/SabaPayment';

import Connexion from './pages/Authentification/Connexion';
import Conditions from './pages/Authentification/Conditions';
import VerifyMail from './pages/Authentification/Verification/VerifyMail';
import ResetPwd from './pages/Authentification/Verification/ResetPwd';
import Dashboard from './pages/Dashboard/Dashboard';
import Logged from './utils/Logged';
import PrivateRoute from './utils/PrivateRoute';
import Demandes from './pages/Demandes/Demandes';
import Declaratinons from './pages/Declarations/Declarations';
import Employeur from './pages/FicheEmploye/Employeur';
import Paiements from './pages/Payment/Paiements';
import SuccessPayment from './pages/Payment/SuccessPayment';
import FailurePayment from './pages/Payment/FailurePayment';
import Salaires from './pages/Salary/Salaires';
import Configurations from './pages/Configurations';

function App() {


  return (
    <Router>
    <Routes>
      <Route element={<PrivateRoute/>}>
          <Route path='/Accueil' element={<Dashboard/>} />
          <Route path='/Demandes' element={<Demandes/>} />
          <Route path='/Declarations' element={<Declaratinons/>} />
          <Route path='/Employeur' element={<Employeur/>} />
          <Route path='/Paiements' element={<Paiements/>} />
          <Route path='/Salaires' element={<Salaires/>} />
          <Route path='/Configurations' element={<Configurations/>} />
      </Route>
      <Route element={<Logged/>}>
            <Route path='/' element={<Connexion/>}/>
            <Route path='/conditions' element={<Conditions/>}/>
            <Route path='/verification' element={<VerifyMail/>}/>
            <Route path='/reinitialisation' element={<ResetPwd />}/>
      </Route>
      <Route path='/paiements/success' element={<SuccessPayment />}/>
      <Route path='/paiements/failure' element={<FailurePayment />}/>
      <Route path='/paymentpage/:paymentid' element={<PaymentPage />} />
      <Route path='/sabapayment/:paymentid' element={<SabaPayment />} />

    </Routes>
</Router>
  )
}

export default App;
