import React, { useState } from "react";
import { Alert, Badge, Button, Card, Col, Form, Modal, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import '../css/Dashboard.scss';
import '../css/Declarations.scss';
import '../vs-mobil/Dash-mobil.scss';
import Immatriculations from  "../Forms/Immatriculation";
import Suspensions from  "../Forms/Suspension";
// import axios from "axios";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import VerticalHeader from "../../Components/VerticalHeader";
import Header from "../../Components/Header";
import { BsFillEyeFill } from "react-icons/bs";
import ImmatriculationView from "./ImmatriculationView";
import SuspensionView from "./SuspensionView";
import { api } from '../../index';
  
function Demandes() {
  const items = JSON.parse(localStorage.getItem('data'));
  const [Choice, setChoice] = useState(false)
  const [Suspension, setSuspension] = useState(false)
  const [Immatriculation, setImmatriculation] = useState(false)
  const [showImma, setshowImma] = useState(false)
  const [ListDemandes,setListDemandes]=useState([]);
  const [RegistrationRowSelected,setRegistrationRowSelected]=useState();
  const [SuspensionRowSelected,setSuspensionRowSelected]=useState([]);
  const [RegistrationView, setRegistrationView] = useState(false);
  const [SuspView, setSuspView] = useState(false);
  const [currentSort, setcurrentSort] = useState("default");
  const [searchVal, setSearchVal] = useState('');
  const [loading, setLoading] = useState(true);

  const back = () =>  {
    localStorage.removeItem("imma")
    setChoice(false)
    setImmatriculation(false);
    setSuspension(false)
    setshowImma(false)
    window.location.href="/demandes"
  }
  useEffect(() => {
    //${items.user.customer["CustomerAccount"]} 0101000000024
    setLoading(true);
      api.get(`customer/${items.user.customer["CustomerAccount"]}/requests`).then(async (response) => {
          setListDemandes(response.data.data);
      }) .finally(() => setLoading(false)); ;
  }, []); 

  const handleInputSearch = (e) => {
       setSearchVal(e.target.value);
  }

  let dataDemandesIds = [];
      ListDemandes.filter((index) => {
          dataDemandesIds.push(index.RequestId.toLowerCase());
  });

  const searchIn= dataDemandesIds.filter((index) => {
      return index.includes(searchVal.toLowerCase());
  });

const RequestSelected = (event) =>{
    ListDemandes.forEach(element => {
        if(element["_id"] == event.currentTarget.id){
            if(element.RequestType == "EmployeeRegistration")
            {
                setRegistrationRowSelected(element)
                setRegistrationView(true)
            }else
            {
                setSuspensionRowSelected(element)
                setSuspView(true)
            }
        }
    });
}
  let dataDemandesArray = [];
      ListDemandes.forEach(index => {
          if (searchIn.includes(index.RequestId.toLowerCase())) {
                dataDemandesArray.push(index)
          }
      })
     

  const sortTypes = {
      up: {
          class: 'sort-up',
          fn: (a, b) => new Date(a.DepositDate) - new Date(b.DepositDate)
      },
      down: {
          class: 'sort-down',
          fn: (a, b) => new Date(b.DepositDate) - new Date(a.DepositDate),
      },
      default: {
          class: 'sort',
          fn: (a, b) => new Date(a)
      }
  }
    
    const onSortChange = () => {
        let nextSort;
        
        if(currentSort === 'down') nextSort = 'up';
        else if(currentSort === 'up') nextSort = 'default';
        else if(currentSort === 'default') nextSort = 'down';
        
        setcurrentSort(nextSort);
    }
  
    const columns = [
        {
            name: 'Numéro de la demande',
            selector: row => row.num_demande,
            sortable: true,
        },
        {
            name: 'Type de la demande',
            selector: row => row.type_demande,
            sortable: true,
        },
        {
            name: 'Date de dépôt',
            selector: row => row.date_depot,
            sortable: true,
        },
        {
            name: 'Statut',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: "Action",
            selector: row => row.principale,
            sortable: true,
        }
      ];
    let data = [];
    dataDemandesArray.map((index) => {
          data.push({
            id: index.RequestId,
            num_demande : index.RequestId,
            type_demande: index["RequestType"] === "EmployeeRegistration" ? "Immatriculation" :
            index["RequestType"] === "EmployerSuspend" ? "Suspension" :
            "-",
            date_depot: index.DepositDate,
            status:  index["RequestStatus"] === "Created" ? "Créé" :
            index["RequestStatus"] === "Rejected" ? "Rejeté" :
            index["RequestStatus"] === "Closed" ? "Cloturé" :
            index["RequestStatus"] === "Approved" ? "Approuvé" :
            index["RequestStatus"] === "PendingApproval" ? "En attente d’approbation" :
            index["RequestStatus"] === "ChangeRequested" ? "En attente d’approbation" :"-",
            principale :<div className="container-radio m-0">
                            <div className="btns-bar">
                                <Button variant="outline-success" className="edit br-15" id={index["_id"]} onClick={RequestSelected}><BsFillEyeFill /></Button>
                            </div>
                        </div>,
          
          });
        });
  return (
    <>
    <Header/>

<Card className="mb-4 p-2 br-15 card-content">
    <VerticalHeader />
            <div className="parent m-2 w-100">
                   <div className="align-right back">
                          <Button variant="light" onClick={back} className="mt-4 uppercase back-btn small align-left">
                              <img src="./assets/back.svg" /> Retour
                          </Button>
                    </div>

                    <h1 className="title-form">Mes demandes </h1>
                    <span className="subtitle-form sm">Accéder à toutes mes demandes en cours, consulter mes anciennes demandes, effectuer de nouvelles demandes. </span>
                    
                  {Suspension?
                     <Suspensions />:
                  Immatriculation || showImma || localStorage.getItem('imma') ?
                    <Immatriculations />:
                    SuspView ?
                    <SuspensionView SuspensionRowSelected = {SuspensionRowSelected}/>:
                    RegistrationView ?
                    <ImmatriculationView RegistrationRowSelected = {RegistrationRowSelected} />:
                    <div>
                      <Form className="bar-top d-flex mt-4">
                            <Form.Control
                            type="search"
                            placeholder="Rechercher"
                            className="me-2 w-50 br-15 pl-1 p-1"
                            aria-label="Rechercher"
                            onChange={handleInputSearch}
                            value={searchVal}
                            />
                            <div className="btns-bar dark">
                                <Button className="btn-100 bg-primary-1 demande br-15"  onClick={() => setChoice(true)}><img src="./assets/add.svg"/> Nouvelle demande </Button>
                                <Button className="btn-100 bg-primary-1 br-15" id="immatriculation" onClick={() => setshowImma(true)} hidden><img src="./assets/add.svg"/>  </Button>
                            </div>
                          
                      </Form>
                      <Modal show={Choice}  onHide={() => setChoice(false)} className="modal-style" >
                              <Modal.Header closeButton className="m-0">
                              </Modal.Header>
                              <Modal.Footer className="gap">
                                  <Button variant="link" id="immat" className="m-0 c-primary f-w" onClick={() => setImmatriculation(true)}>
                                      Immatriculation
                                  </Button>
                                  ou
                                  <Button variant="link"  className="m-0 c-primary f-w" onClick={() => setSuspension(true)}>
                                      Suspension
                                  </Button>
                              </Modal.Footer>
                      </Modal>
                      <Row className="mt-2 scrol-x-t">
                                    {loading ? (
                    <div class="loader">
  <div class="loader-wheel"></div>
  <div class="loader-text"></div>
</div>
                    ) : (
                            
                      <DataTable
                                        columns={columns}
                                        data={data}
                                        pagination
                                        highlightOnHover
                                        fixedHeader
                                        fixedHeaderScrollHeight="500px"
                                    />  
                                    )}
                                    </Row>
                    </div>
                  }
      </div>
   </Card>
    </>
  );
}
export default Demandes;