import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Link } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill} from "react-icons/bs";
import Header from "../../Components/Header";
import Forgot from "./Forgot";
import Register from "./Register";
import './Connexion.scss';
import './vs-mobil/Auth-mobil.scss';
// import axios from "axios";
import { api } from '../../index';
  
function Connexion() {
    const [show, setShow] = useState(true); 
    const [showRegister, setShowRegister] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [login, setEmail] = useState("");
    const [password, setpassword] = useState("");
    const [passwordShow, setpasswordShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState()

    const togglePasswordVisiblity = () => {
        setpasswordShow(passwordShow ? false : true);
    };
    const RegisterShow = () =>  {
          setShowRegister(true);
          setShow(false);
          setShowForgot(false);
    }
    const ForgotShow = () =>  {
            setShowRegister(false);
            setShow(false);
            setShowForgot(true);
  }
    const LoginShow = () =>  {
            setShowRegister(false);
            setShow(true);
            setShowForgot(false);
    }
    const connexion = (event) => {
        const objErrors = {};
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            }
            setValidated(true);
        
            api.post('login', {
                login,password
            }).then(async (response) => {
                localStorage.setItem('data', JSON.stringify(response.data.data));
                localStorage.setItem('smmToken', response.data.data.token);
                localStorage.setItem('expirationDate', response.data.data.expirationDate);
                if(response.data.success){
                    window.location.href = "Accueil";
                }
            }, (error) => {
                setErrors(error.response ? error.response.data?.data.error : "Server error! try again.");
            });
    
      };

  return (
    <div id="app">
        <Header/>
        <div className="justify-content-center m-2">
            <div className="align-center mt-4r">
                 <img  variant="top" src="./assets/cnss-logo.svg" /> 
            </div>
            <Card className="login-card margin-auto mt-4 mb-4">
                <Row className="h-100 m-0">
                    <Col className="p-0 m-0" xs={12} md={6}>
                        <div className="left-grid m-0"> <Card.Img  variant="top" src="./assets/login.png" /> </div>
                    </Col>
                    {show ?
                    <Col className="col-sm-6"  xs={12} md={6}>
                         <div className="right-grid align-center">
                         <h1 className="title-form"> Télé-déclaration</h1>
                                    <p className="subtitle-form sm">Utilisez vos identifiants pour vous connecter .</p>
                             <Form noValidate validated={validated} className="mt-4">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <FloatingLabel controlId="floatingPassword" label="LOGIN">
                                         <Form.Control type="text" placeholder="Enter email"  onChange={(e) => setEmail(e.target.value)}  required/>
                                         <Form.Control.Feedback type="invalid" className="err">
                                           ce champ est obligatoire
                                        </Form.Control.Feedback>
                                    </FloatingLabel> 
                                </Form.Group>
                                <Form.Group  controlId="formBasicPassword">
                                    <FloatingLabel controlId="floatingPassword" label="MOT DE PASSE">
                                        <a className="eye" onClick={togglePasswordVisiblity}>{!passwordShow? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                        <Form.Control  type={passwordShow ? "text" : "password"} onChange={(e) => setpassword(e.target.value)}  placeholder="Password"  required/>
                                        <Form.Control.Feedback type="invalid" className="err">
                                           ce champ est obligatoire
                                        </Form.Control.Feedback>
                                    </FloatingLabel>        
                                </Form.Group>
                     
                                
                                <Link to="#" onClick={ForgotShow} className="float-right xs underline-unset second-color">Mot de passe ou login oublié ?</Link>
                                {errors && errors ? 
                                <Alert className="err mt-4 p-2" key="danger" variant="danger">
                                      {errors} 
                                </Alert>
                                : ''}
                                <div className="border-bottom mt-10">
                                    <Button className="align-center mb-4 mt-10 submit btn uppercase xs"  onClick={connexion} >
                                        se connecter <img variant="top" src="./assets/log-in.png" className="pl-5"/>
                                    </Button>
                                </div>
                                <span className="text-in-line sm bg-white">ou</span>
                                <div>

                                    <Button variant="success" onClick={RegisterShow} className="mt-4 uppercase btn xs">
                                        inscription <img variant="top" src="./assets/inscription.png" className="pl-5" />
                                    </Button>
                                </div>
                            </Form>
                         </div>
                    </Col>:""}
                  
                    {showRegister ? <Register RegisterShow={RegisterShow} LoginShow={LoginShow} /> :"" }
                    {showForgot ? <Forgot ForgotShow={ForgotShow} LoginShow={LoginShow} /> :"" }
                
                </Row>
            </Card>
 
        </div>
    </div>
  );
}
export default Connexion;