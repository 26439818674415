import React, { useEffect, useState } from "react";
import { Alert, Button, Col, FloatingLabel, Form, Row, Tab, Table, Tabs, Spinner } from "react-bootstrap";

import '../Forms/Forms.scss';
import '../vs-mobil/Forms.scss';
import { BsTrashFill } from "react-icons/bs";
// import axios from "axios";
import { api } from '../../index';

function PaymentView({PaymentRowSelected}) {
    const [AV, setAV] = useState(<div> <span class="text-1"> Télécharger </span><span class="text-2"> votre Avis de crédit </span> <span class="text-4"> appareil</span> </div>);
    const [DeclarationData, setDeclarationData] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState('');
    const [Message, setMessage] = useState("");
    const [PieceIdentite, setPieceIdentite] = useState();
    const [Banque, setBanque] = useState('');
    const [banqueOptions, setBanqueOptions] = useState([]);

    const [deleteAV, setdeleteAV] = useState(false);
    const [loading, setLoading] = useState(false);
    const paymentMethods= [    
        { label:"D-Money Wallet",value: "P-DMoney", img: "./assets/D-Money-logo.png"},
        { label:"WaafiPay Wallet",value: "P-Waafi", img: "./assets/waafi-logo.png"},
        { label:"CAC International Bank",value: "P-CAC", img: "./assets/cac.png"},
        // { label:"Saba African Bank",value: "P-SABA", img: "./assets/logosabaa.png"},
        { label:"Virement",value: "Virement", img: "./assets/virement-logo.webp"},
    ];
    useEffect(() => {
        api.get(`get/banks`).then(async (response) => {
            setBanqueOptions(response.data.data)
            
        })
        
    }, []);
    useEffect(() => {
        if (PaymentRowSelected?.payment_status == "Success") {
            setPaymentMethod(PaymentRowSelected.payment_method);
            setIsDisabled(true);
        }else if (PaymentRowSelected?.Status != "Draft") setIsDisabled(true);
    },[]);
    
    useEffect(() => {
        api.get(`payment/${PaymentRowSelected.PaymentId}/contributioncalls`).then(async (response) => {
            setDeclarationData(response.data.contributionCalls)
        })
    },[]);

    const avis_credit = event =>{
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e) => {
            setPieceIdentite(e.target.result)
        };
          setAV(event.target.files[0]?event.target.files[0].name :AV)
          setdeleteAV(event.target.files[0]?true :false)
    }
    const handleClick = (event) => {
            document.getElementById("cvfile").click() 
    };
    const DeleteAV = () => {
        setAV(<div> <span class="text-1"> Télécharger </span><span class="text-2"> votre Avis de crédit </span> <span class="text-4"> appareil</span> </div>);
        setdeleteAV(false);
    };

    const  validPayment = async () => {
        setError(paymentMethod ? "" : "ce champ est obligatoire");
        setMessage(Banque && PieceIdentite || paymentMethod != "Virement" ? "" : "Veuillez renseigner ces champs obligatoires")
        if(paymentMethod && (Banque && PieceIdentite || paymentMethod != "Virement")) {
            setLoading(true);
            await api.post(`payment/valid`, {id : `${PaymentRowSelected.PaymentId}` , paymentMethod,PieceIdentite,Banque })
                .then(async (response) => {
                    if(response.data.data.url) window.location.href = response.data.data.url;
                    setLoading(false);
                },(error) => {
                    setError(error.response.data.data.error);
                    setLoading(false);
            });
        }
        
    }


  return (
    <div className="view-payment">

        <Form >
            <Row className="form-row">
                <Col className="mb-2" sm={12} md={6}>
                    <Form.Group className="mb-3 input-mobil">
                        <FloatingLabel  label="Montant du paiement" className="uppercase">
                            <Form.Control  type="text"  value={PaymentRowSelected.PaymAmount}   disabled  className="bg-light" />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
                <Col className="mb-2" sm={12} md={6}>
                    <Form.Group className="mb-3 input-mobil">
                        <FloatingLabel  label="Date de paiement" className="uppercase">
                            <Form.Control  type="text"   value={PaymentRowSelected.PaymDate}   disabled  className="bg-light"/>
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="form-row ">
                <Col  sm={12} md={6} className="textarea-col">
                    <Form.Group className="mb-0 ">
                        <FloatingLabel  label="Motif du rejet" className="uppercase">
                            <Form.Control  as="textarea"  value={PaymentRowSelected?.Notes}   disabled  className="bg-light payment-view-textarea" />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
                <Col  sm={12} md={6} className={`m-0 input-mobil`}>
                    <div class={` ${paymentMethod == "Virement" && !isDisabled ? "" : "hide"}`}>
                        <Form.Group className="mb-2 input-mobil">
                            <FloatingLabel label="Banque (*)" className="uppercase">
                                <Form.Select
                                    value={Banque}
                                    onChange={(e) => setBanque(e.target.value)}
                                >
                                    <option value="">Sélectionnez une banque</option>
                                    {banqueOptions.map(option => (
                                        <option  value={option.BankGroupId}>
                                            {option.BankGroupId}
                                        </option>
                                    ))}
                                </Form.Select>
                            </FloatingLabel>
                        </Form.Group>
                        <div class={`wrap file ${paymentMethod == "Virement" && !isDisabled ? "" : "hide"}`} onClick={handleClick}>
                            <div class="valign-middle">
                                <div class="form-group">
                                    <label for="file" class="sr-only">
                                        {AV}<BsTrashFill className={!deleteAV?"hide":""} onClick={DeleteAV}/>
                                        
                                    </label>
                                    <div class="t">
                                        <span class="btn1">
                                            <div> <span class="text-1">{AV} </span><span class="text-2"> votre C.V</span> </div>
                                            <div> <span class="text-3">Ou telechargez votre C.V depuis votre </span><span class="text-4"> appareil</span></div>
                                        </span>
                                        <span class="file-selected"></span><input type="file" name="cvfile" className="cvfiles" id="cvfile" onChange={avis_credit}/>
                                    </div>
                                </div>
                            </div>
                                
                        </div>
                    
                    </div>
                    <Alert  variant="danger" className={Message ? "methode_alert uppercase" : "hide"}>
                        {Message}
                    </Alert>
                </Col>
                <Col className="mb-2 mt-3" sm={12} md={12}>
                    <Form.Group className="mb-3 input-mobil payment-mode">
                        <Form.Label className="mb-2 ms-3 text-uppercase">Méthode de paiement</Form.Label>
                        {paymentMethods.map((element,i)=> (
                             <Form.Check
                             className={`mb-2 ${paymentMethod === element.value ? "radio-seected" : ""}`}
                             type="radio"
                             label={
                                 <>
                                    <img src={`${element.img}`} alt="Option 1" width="100" height="100" className="me-3" />
                                    {element.label}
                                 </>
                             }
                             name="radioGroup"
                             id={`${element.value}`}
                             value={`${element.value}`}
                             disabled={isDisabled}
                             checked={paymentMethod ===  element.value}
                             onChange={(e) => setPaymentMethod(e.target.value)}
                         />
                        ))} 
                        <Alert  variant="danger" className={error ? "methode_alert" : "hide"}>
                            {error}
                        </Alert>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={isDisabled ? "hide" :"form-row"} >
                <Col className="mb-2" sm={12} md={6}>
                </Col>
                <Col className="mb-2" sm={12} md={6}>
                <Button
        variant="success"
        className="succes mt-4 uppercase xs br-30 align-right mb-4"
        onClick={validPayment}
        disabled={loading} // Disable the button during submission
      >
        {loading ? (
          <>
            <Spinner animation="border" size="sm" role="status" style={{ marginRight: "5px" }} />
            <span>Loading...</span>
          </>
        ) : (
          <>
            <img variant="top" src="./assets/verify-white.svg" style={{ paddingRight: 5 }} /> PAYER
          </>
        )}
      </Button>
                </Col>
            </Row>
            <Row className="mt-2  scrol-x-t" >
                <Table className="table-adress mt-2 table-scroll small-first-col" hover >
                        <thead>
                            <tr>
                                <th>Appel de cotisation </th>
                                <th>Période  </th>
                                <th >Régime  </th>
                                <th >Montant   </th>

                            </tr>
                        </thead>
                        <tbody className="body-half-screen fix-height">
                            <div>
                                {DeclarationData.map((data,i)=> (
                                    <tr>
                                        <td> {data.ContributionCallId}</td>
                                        <td>{data.SSMCalendarPeriod_Name}</td>
                                        <td>{data.SSMRegime_RegimeId}</td>
                                        <td>{data.Total}</td>
                                    </tr>
                                ))}
                            </div>

                        </tbody>
                </Table>
            </Row>
        </Form>
    </div>
  );
}
export default PaymentView;