import React, { useState } from "react";
import {  Nav, Tab, Tabs } from 'react-bootstrap';
import './vs-mobil/HorizontalHeader.scss';

function VerticalHeader() {
   
  const tabs= [    
    {id:"accueil" ,title:"Accueil",path:"/Accueil",icon: "./assets/home-gray.svg",iconHover: "./assets/home-orange.svg"},
    {id:"employeur" , title:"Ma fiche employeur",path:"/Employeur",icon: "./assets/employ-gray.svg",iconHover: "./assets/employ-orange.svg"},
    {id:"declarations" , title:"Mes déclarations",path:"/declarations",icon: "./assets/declaration-gray.svg",iconHover: "./assets/declaration-orange.svg"},
    {id:"demandes" , title:"Mes demandes",path:"/demandes",icon: "./assets/demande-gray.svg",iconHover: "./assets/demande-orange.svg"},
    {id:"paiements" , title:"Mes paiements",path:"/paiements",icon: "./assets/payment-gray.svg",iconHover: "./assets/payment-orange.svg"},
    {id:"salaires" , title:"Mes salariés",path:"/salaires",icon: "./assets/salary-gray.svg",iconHover: "./assets/salary-orange.svg"},
    {id:"configurations" , title:"Mes configurations",path:"/configurations",icon: "./assets/setting-gray.svg",iconHover: "./assets/setting-orange.svg"},
  ]
  const Logout = () => {
    localStorage.clear();
    window.location.href="/"
};
    
    return (
         <>
            <Nav
                variant="pills"
                className="horizontal-header"
                >
                 {tabs.map((data)=> (
                
                <Nav.Link  eventKey={data.id} href={data.path}  >
                        <img className="icon"  variant="top"  src={data.icon} />
                        <img  className="iconHover"  src={data.iconHover} /><br />
                    
                 </Nav.Link>
                                  
                 ))}
                 <Nav.Link onClick={Logout} className="reverse last" >
                                        <img className="icon"  variant="top"  src="./assets/logout-gray.svg" />
                  </Nav.Link>
            </Nav>
               
            
                  
                                        
         </>
     
    );
  }


export default VerticalHeader;