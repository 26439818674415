import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import Header from "../../Components/Header";
import VerticalHeader from "../../Components/VerticalHeader";
import HorizontalHeader from "../../Components/HorizontalHeader";
import '../css/Dashboard.scss';
import '../vs-mobil/Dash-mobil.scss';
import { BsFillEyeFill, BsFillEyeSlashFill} from "react-icons/bs";
// import axios from "axios";
import { api } from '../../index';
  
function Dashboard() {

  const [passwordShown, setPasswordShown] = useState(false);
  const [Solde, setSolde] = useState(" ");
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);

  };
  
  const items = JSON.parse(localStorage.getItem('data'));
  useEffect(() => {

    api.get(`customer/${items.user["SSMCustomerAccount"]}/solde`).then(async (response) => {
      setSolde(response.data.data)
    });
  }, []);

  const cards1 = [
    {id:"employeur" ,path: "/Employeur",  title: "Ma fiche employeur", icon: "./assets/employ-lg-hover.svg" , iconHover: "./assets/employ-lg.svg" ,hover:"card1" , content:"Accéder aux informations de votre compte : informations, adresses, modes de contact, documents."},
    {id:"declarations" ,path: "/declarations", title: "Mes déclarations", icon: "./assets/declaration-lg-hover.svg",iconHover: "./assets/declaration-lg.svg",hover:"card2", content:"Consulter, vérifier et effectuer mes déclarations périodiques."},
    {id:"demandes" ,path: "/demandes",  title: "Mes demandes", icon: "./assets/demande-lg-hover.svg",iconHover: "./assets/demande-lg.svg",hover:"card3", content:"Accéder à toutes mes demandes: en cours et anciennes, effectuer de nouvelles demandes."},
  ]
  const cards2 = [    
    {id:"paiements" ,path: "/paiements", title: "Mes paiements", icon: "./assets/payment-lg-hover.svg",iconHover: "./assets/payment-lg.svg",hover:"card4", content:" Effectuer un paiement, voir statut des paiements, consulter les paiements antérieurs."},
    {id:"salaires" ,path: "/salaires", title: "Mes salariés", icon: "./assets/salary-lg-hover.svg",iconHover: "./assets/salary-lg.svg",hover:"card5", content:"Consulter la liste des salariés, ajouter de nouveaux salariés."},
    {id:"configurations" ,path: "/configurations", title: "Mes configurations", icon: "./assets/setting-lg-hover.svg",iconHover: "./assets/setting-lg.svg",hover:"card6", content:"Accéder à mon compte, modifier  mot de passe, ajouter de nouveaux utilisateurs."}
  ]

  const cardsMobil = [
    {id:"employeur" ,path: "/Accueil",  title: "Ma fiche employeur", icon: "./assets/employ-lg-hover.svg" , iconHover: "./assets/employ-lg.svg" ,hover:"card1" , content:"Accéder aux informations de votre compte : informations, adresses, modes de contact, documents."},
    {id:"declarations" ,path: "/Employeur", title: "Mes déclarations", icon: "./assets/declaration-lg-hover.svg",iconHover: "./assets/declaration-lg.svg",hover:"card2", content:"Consulter, vérifier et effectuer mes déclarations périodiques."},
    {id:"demandes" ,path: "/declarations",  title: "Mes demandes", icon: "./assets/demande-lg-hover.svg",iconHover: "./assets/demande-lg.svg",hover:"card3", content:"Accéder à toutes mes demandes: en cours et anciennes, effectuer de nouvelles demandes."},  
    {id:"paiements" ,path: "/paiements", title: "Mes paiements", icon: "./assets/payment-lg-hover.svg",iconHover: "./assets/payment-lg.svg",hover:"card4", content:" Effectuer un paiement, voir statut des paiements, consulter les paiements antérieurs."},
    {id:"salaires" ,path: "/Demandes", title: "Mes salariés", icon: "./assets/salary-lg-hover.svg",iconHover: "./assets/salary-lg.svg",hover:"card5", content:"Consulter la liste des salariés, ajouter de nouveaux salariés."},
    {id:"configurations" ,path: "/configurations", title: "Mes configurations", icon: "./assets/setting-lg-hover.svg",iconHover: "./assets/setting-lg.svg",hover:"card6", content:"Accéder à mon compte, modifier  mot de passe, ajouter de nouveaux utilisateurs."}
  ]
 

      const handleClick = event => {
          const  allWithTab = Array.from(
            document.getElementsByClassName('tabhead nav-link')
          );
      
          allWithTab.forEach(allWithTab => {
            setTimeout(() => {
              (allWithTab.id === event.target.getAttribute("data-t") ? document.getElementById(event.target.getAttribute("data-t")).click(): document.getElementById("te").click())
            }, "500")
          });

      };

      

  return (
    <>
        <Header/>

          <Card className="mb-4 p-2 br-15 card-content desktop">
      
            <Tab.Container id="left-tabs-example" defaultActiveKey="accueil">
                <VerticalHeader />
                <Tab.Content>
                    <Tab.Pane eventKey="accueil" className="components" >
                      <div className="p-3 m-2 w-100">
                      <div className="align-right matricule">
                          <span>Mon compte employeur</span><br></br>
                          <span>{items.user.customer["NameAlias"]  ? items.user.customer["NameAlias"] : items.user.customer["OrganizationName"]}</span><br></br>
                          <span>N°: {items.user["SSMCustomerAccount"]}</span><br></br>
                          <span>Adresse : {items.user.customer["FullPrimaryAddress"]}</span><br></br>
                          <span className="solde">Solde à payer : {Solde.toLocaleString()}</span>
                      </div>
                        <h1 className="title-form">Bonjour , {items.user["LastName"]} <img src="./assets/hand.svg" /></h1>
                        <span className="subtitle-form sm">Bienvenue sur votre portail employeur . </span>
                        <Tabs
                            defaultActiveKey="profile"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3 mt-4 Dash-tabs w-100"
                          >
                            {/**<Tab eventKey="home" title="Admin" disabled>
                            <Form className="d-flex search-bar m-0">
                                  <Form.Control
                                  type="search"
                                  placeholder="Rechercher"
                                  className="me-2 br-15 pl-1 p-1"
                                  aria-label="Rechercher"
                                  />
                              </Form>
                            <Row className="mt-2">
                                  <Table className="table-adress" hover >
                                      <thead>
                                          <tr>
                                              <th width="22%">Description</th>
                                              <th width="22%">Nom et prenom</th>
                                              <th width="14%">Role</th>
                                              <th width="22%  ">Mot de passe</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        {tableAdmin.map((data,i)=> (
                                        
                                          <tr>
                                              <td>{data.description}</td>
                                              <td>{data.name}</td>
                                              <td>{data.role}</td>
                                              <td>   
                                                <Form.Group  controlId="formBasicPassword">
                                                    <FloatingLabel controlId="floatingPassword" label="MOT DE PASSE">
                                                        <a className="eye w-100 p-2" onClick={togglePasswordVisiblity}>{!passwordShown? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                                        <Form.Control value={data.password}  type={passwordShown ? "text" : "password"}  placeholder="Password" />
                                                        
                                                    </FloatingLabel>        
                                                </Form.Group>
                                              </td>
                                          </tr>
                                        ))}
                                      
                                      </tbody>
                                  </Table>
                                
                              </Row>  
                              <Button variant="success"  type="submit" className="mt-4 uppercase xs align-right">
                                              Valider <img variant="top" src="./assets/inscription.png" className="pl-5" />
                              </Button>  
                            </Tab> */}
                            
                            <Tab eventKey="profile" title="Employeur">
                                <Row className="mt-4 container-cards">
                                    <Nav variant="pills"   defaultActiveKey="accueil"> 
                                          {
                                            cards1.map((index) => (
                                              <Col key={index} path={index.path}>
                                                  <Nav.Link  eventKey={index.id} href={index.path} data-e={`left-tabs-example-tabpane-${index.id}`} data-t={`left-tabs-example-tab-${index.id}`}  className={`left-tabs-example-tabpane-${index.id}`} onClick={handleClick} >
                                                  <Card  border="primary"  className={` p-1 xs  br-30 cards ${index.hover}`}>
                                                    <Card.Body className="p-0">
                                                      <Card.Title data-t={`left-tabs-example-tab-${index.id}`}>{index.title}</Card.Title>
                                                      <Card.Text className="mt-2" data-t={`left-tabs-example-tab-${index.id}`}>
                                                        {index.content}
                                                      </Card.Text>
                                                    </Card.Body>
                                                    <div> 
                                                      <img className="icon" src={index.icon} data-t={`left-tabs-example-tab-${index.id}`} />
                                                      <img className="iconHover"  src={index.iconHover} data-t={`left-tabs-example-tab-${index.id}`}/>
                                                    </div>
                                                  </Card>
                                                  </Nav.Link>
                                            </Col>
                                            ))
                                          }
                                    </Nav>
                                </Row>
                                <Row className="mt-4 container-cards">
                                    <Nav variant="pills"   defaultActiveKey="accueil"> 
                                              {
                                                cards2.map((index) => (
                                                  <Col key={index} path={index.path} >
                                                      <Nav.Link href={index.path}  eventKey={index.id} data-e={`left-tabs-example-tabpane-${index.id}`} data-t={`left-tabs-example-tab-${index.id}`}  className={`left-tabs-example-tabpane-${index.id}`} onClick={handleClick} >
                                                      <Card  border="primary" data-t={`left-tabs-example-tab-${index.id}`}  className={`xs  br-30 cards ${index.hover}`}>
                                                        <Card.Body className="p-0"  data-t={`left-tabs-example-tab-${index.id}`} >
                                                          <Card.Title data-t={`left-tabs-example-tab-${index.id}`}>{index.title}</Card.Title>
                                                          <Card.Text data-t={`left-tabs-example-tab-${index.id}`} className="mt-2">
                                                            {index.content}
                                                          </Card.Text>
                                                        </Card.Body>
                                                        <div data-t={`left-tabs-example-tab-${index.id}`}> 
                                                          <img className="icon" src={index.icon} data-t={`left-tabs-example-tab-${index.id}`} />
                                                          <img className="iconHover"  src={index.iconHover} data-t={`left-tabs-example-tab-${index.id}`} />
                                                        </div>
                                                        </Card>
                                                      </Nav.Link>
                                                </Col>
                                                ))
                                              }
                                    </Nav>
                                </Row>    
                            </Tab>
                            {/**
                             *  
                             *  <Tab eventKey="CNSS" title="CNSS" disabled>
                              maquette manquante 
                            </Tab>
                             */}
                       
                        </Tabs>
                        
                      </div>
                      </Tab.Pane>
                    
                </Tab.Content>
                
            </Tab.Container>
    
          </Card>
          <Card className="mb-3 br-15 card-content mobil">
              
              <Tab.Container id="left-tabs-example" defaultActiveKey="accueil">
              <Row>
                <Col sm={12}>
                <Tab.Content>
                    <Tab.Pane eventKey="accueil">
                      <div className="w-100">
                        <h1 className="title-form">Bonjour , {items.user["LastName"]} <img src="./assets/hand.svg" /></h1>
                        <span className="subtitle-form sm">Bienvenue sur votre portail employeur . </span>
                        <Tabs
                            defaultActiveKey="profile"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3 mt-4 Dash-tabs w-100"
                          >
                            {/** 
                            <Tab eventKey="home" title="Admin">
                              <Form className="d-flex">
                                      <Form.Control
                                      type="search"
                                      placeholder="Rechercher"
                                      className="me-2 m-0 w-100 br-15 pl-1 p-1 search-mobil"
                                      aria-label="Rechercher"
                                      />
                                  
                              </Form>
                            <Row className="mt-2 scrol-x-t">
                                  <Table className="table-adress" hover >
                                      <thead>
                                          <tr>
                                              <th>Description</th>
                                              <th>Nom et prenom</th>
                                              <th>Role</th>
                                              <th >Mot de passe</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        {tableAdmin.map((data,i)=> (
                                        
                                          <tr>
                                              <td>{data.description}</td>
                                              <td>{data.name}</td>
                                              <td>{data.role}</td>
                                              <td>   
                                                  <Form.Group  controlId="formBasicPassword">
                                                    <FloatingLabel controlId="floatingPassword" label="MOT DE PASSE">
                                                        <a className="eye w-100 p-2" onClick={togglePasswordVisiblity}>{!passwordShown? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                                        <Form.Control value={data.password}  type={passwordShown ? "text" : "password"}  placeholder="Password" />
                                                        
                                                    </FloatingLabel>        
                                                  </Form.Group>
                                              </td>
                                          </tr>
                                        ))}
                                      
                                      </tbody>
                                  </Table>
                                
                              </Row>  
                              
                              <Button variant="success" size="lg"  type="submit" className="succes mt-4 uppercase xs align-right">
                                              Valider <img variant="top" src="./assets/inscription.png" className="pl-5" />
                              </Button> 
                              
                                
                            </Tab>
                            */}
                            <Tab eventKey="profile" title="Employeur">
                            <Row className="mt-4">
                            {
                              cardsMobil.map((index) => (
                                <Col key={index} path={index.path} className="p-0">
                                   <Nav.Link href={index.path}  eventKey={index.id} data-e={`left-tabs-example-tabpane-${index.id}`} data-t={`left-tabs-example-tab-${index.id}`}  className={`left-tabs-example-tabpane-${index.id}`} onClick={handleClick} >
                                        <Card  border="primary"  className={`xs  br-15 cards mobil ${index.hover}`}>
                                          <div> 
                                              <img className="icon" src={index.icon} />
                                              <img className="iconHover"  src={index.iconHover} />
                                          </div>
                                          <Card.Body >
                                            <Card.Title>{index.title}</Card.Title>
                                            <Card.Text className="mt-2">
                                              {index.content}
                                            </Card.Text>
                                          </Card.Body>
                                          
                                        </Card>
                                    </Nav.Link>
                              </Col>
                              
                              ))
                            }
                            
                            </Row> 
                            
                            <div className="align-right matricule w-100">
                                <span>Mon compte employeur</span><br></br>
                                <span>{items.user.customer["NameAlias"]  ? items.user.customer["NameAlias"] : items.user.customer["OrganizationName"]}</span><br></br>
                                <span>N°: {items.user["SSMCustomerAccount"]}</span><br></br>
                                <span>Adresse : {items.user.customer["FullPrimaryAddress"]}</span><br></br>
                                <span className="solde">Solde à payer : {Solde.toLocaleString()}</span>
                            </div>  
                            </Tab>
                            {/** 
                            <Tab eventKey="CNSS" title="CNSS">
                              test 3
                            </Tab>
                            */}
                        </Tabs>
                        
                      </div>
                    </Tab.Pane>
                   
                </Tab.Content>
                </Col>
                <Col sm={12}>
                     <HorizontalHeader />
                </Col>
              </Row>
              </Tab.Container>


          </Card>

      
    </>
  );
}
export default Dashboard;