// import axios from "axios";
import { api } from '../../index';
import React, { useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import './Connexion.scss';

 
function Register({LoginShow}) {
    const [LastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [PrimaryPhoneNumber, setPrimaryPhoneNumber] = useState("");
    const [SSMCustomerAccount, setSSMCustomerAccount] = useState("");
    const [EmploymentJobFunctionName, setEmploymentJobFunctionName] = useState("");
    const [password, setpassword] = useState("");
    const [password_confirmation, setpassword_confirmation] = useState("");
    const [Errors, setErrors] = useState([]);

    const [passwordShow, setpasswordShow] = useState(false);
    const [password_confirmationShow, setpassword_confirmationShow] = useState(false);


    const [acceptedTerms, setAcceptedTerms] = useState(false);
    
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [showForm, setshowForm] = useState(true);
    const [loading, setloading] = useState(false);

  const register = (event) => {
     setloading(true)
     setShow(false)
     setshowForm(false)
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }
    setValidated(true);
    api.post('register', {
        LastName,email,PrimaryPhoneNumber,SSMCustomerAccount,password,password_confirmation,EmploymentJobFunctionName,acceptedTerms
        }).then(async (response) => {
        if(response.data.success){
            setShow(true)
            setloading(false)
            setshowForm(false)
        }
        }, (error) => {
         // setloading(false);
         setloading(false)
         setshowForm(true)

         setErrors(error.response ? error.response.data.errors : "Server error! try again.");
        });
 
  };

    const togglePasswordVisiblity = () => {
        setpasswordShow(passwordShow ? false : true);
    };
    const toggleConfirmPass = () => {
        setpassword_confirmationShow(password_confirmationShow ? false : true);
      };
  return (
                <>
                   
                    <Col className={showForm ? "" :"hide"} >
                        <div className="right-grid align-center pt-2">
                             <h1 className="title-form"> Inscription</h1>
                             <p className="subtitle-form sm">Saisissez vos informations, un agent prendra contact avec vous après la soumission de votre demande d'inscription .</p>
                            <Form  validated={validated} className="mt-4 pad-form">
                                <fieldset className="scroll-form">
                                <Form.Group className="mb-3" controlId="formBasictest"  >
                                    <FloatingLabel controlId="formBasictest" label="N° D'AFFILAITION *"  >
                                         <Form.Control type="number" onChange={(e) => setSSMCustomerAccount(e.target.value)} required    isInvalid={Errors && Errors.SSMCustomerAccount} />
                                         {Errors && Errors.SSMCustomerAccount ?  
                                            <Form.Control.Feedback type="invalid" style={{display: "block", textTransform: "capitalize"}}>
                                            {Errors.SSMCustomerAccount[0]}
                                             </Form.Control.Feedback>
                                        :""}
                                    </FloatingLabel> 
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <FloatingLabel controlId="floatingNom" label="NOM *">
                                         <Form.Control type="text"  onChange={(e) => setLastName(e.target.value)} required />
                                         <Form.Control.Feedback type="invalid" style={{ textTransform: "capitalize"}}>
                                           Ce champ est obligatoire
                                        </Form.Control.Feedback>
                                    </FloatingLabel> 
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <FloatingLabel  label="FONCTION *" className="uppercase">
                                        <Form.Select aria-label="Default select example" className="pl-1 select" onChange={(e) => setEmploymentJobFunctionName(e.target.value)} required >
                                        <option value=""></option>
                                            <option value="DG">DG</option>
                                            <option value="DSI">DSI</option>
                                            <option value="DRH">DRH</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid" style={{textTransform: "capitalize"}}>
                                           Ce champ est obligatoire
                                        </Form.Control.Feedback>
                                    </FloatingLabel> 
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <FloatingLabel controlId="floatingPassword" label="TELEPHONE *">
                                         <Form.Control type="number" onChange={(e) => setPrimaryPhoneNumber(e.target.value)} required />
                                        {Errors && Errors.PrimaryPhoneNumber ?  
                                                        <Form.Control.Feedback type="invalid" style={{display: "block", textTransform: "capitalize"}}>

                                                 {Errors.PrimaryPhoneNumber[0]}
                                             </Form.Control.Feedback>
                                        :""}
                                    </FloatingLabel> 
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <FloatingLabel controlId="floatingPassword" label="ADRESSE EMAIL *">
                                         <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} required />
                                        {Errors && Errors.email ?  
                                            <Form.Control.Feedback type="invalid" style={{display: "block", textTransform: "capitalize"}}>
                                            {Errors.email[0]}
                                             </Form.Control.Feedback>
                                        :""}
                                    </FloatingLabel> 
                                </Form.Group>
                                <Form.Group className="mb-3"  controlId="formBasicPassword">
                                    <FloatingLabel controlId="floatingPassword" label="MOT DE PASSE *">
                                        <a className="eye mt-1" onClick={togglePasswordVisiblity}>{!passwordShow? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                        <Form.Control  type={passwordShow ? "text" : "password"}  onChange={(e) => setpassword(e.target.value)} required />
                                        {Errors && Errors.password ?  
                                    <Form.Control.Feedback type="invalid" style={{display: "block", textTransform: "capitalize"}}>
                                    {Errors.password[0]}
                                             </Form.Control.Feedback>
                                        :""}
                                    </FloatingLabel>        
                                </Form.Group>
                                <Form.Group className="mb-3"  controlId="formBasicPassword">
                                    <FloatingLabel controlId="floatingPassword" label="CONFIRMATION DE MOT DE PASSE *">
                                        <a className="eye mt-1" onClick={toggleConfirmPass}>{!password_confirmationShow? <BsFillEyeFill /> : <BsFillEyeSlashFill /> }</a>
                                        <Form.Control  type={password_confirmationShow ? "text" : "password"}  onChange={(e) => setpassword_confirmation(e.target.value)} required />
                                        {Errors && Errors.password_confirmation ?  
                                        <Form.Control.Feedback type="invalid" style={{display: "block", textTransform: "capitalize"}}>
                                        {Errors.password_confirmation[0]}
                                             </Form.Control.Feedback>
                                        :""}
                                    </FloatingLabel>        
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicTerms">
                                    <Form.Check
                                        type="checkbox"
                                        label={<span>J'accepte les <a target='_blank' href="/conditions">conditions d'utilisation</a></span>}
                                        onChange={(e) => setAcceptedTerms(e.target.checked)}
                                        required
                                    />
                                  
                                </Form.Group>
                                
                                </fieldset>
                                <div>
                                    <Button variant="light"  onClick={LoginShow}  className="mt-4 uppercase back-btn small align-left">
                                    <img src="./assets/back.svg" className="pr-2"/> Retour
                                    </Button>
                                    <Button  onClick={register} className="valider mt-4 uppercase btn  xs align-right">
                                        Suivant
                                    </Button>
                                </div>
                            </Form>
                         </div>
                    </Col>
                    <Col className={show ? "" :"hide"} style={{marginTop:"15%"}}>
                        <div className="right-grid align-center pt-2">
                            <h1 className="title-form"> Inscription</h1>
                            <p className="subtitle-form sm"> Votre inscription est enregistrée, Un mail vous a été envoyé pour confirmer votre compte.</p>
                            <img src="./assets/success.svg" className="mt-10" />
                        </div>
                        <div className="align-center">
                            <Button  className="valider mt-4 uppercase btn  xs" href="/">
                                            Continuer <img src="./assets/verified.svg" />
                            </Button>
                        </div>
                    </Col>
                    <Col className={loading ? "" :"hide"} style={{marginTop:"15%"}}>
                        <div>
                            <Spinner animation="border" variant="success" />
                        </div>
                    </Col>
                </>

  );
}
export default Register;