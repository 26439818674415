import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, FloatingLabel, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { BsCheck, BsFillExclamationCircleFill, BsSearch } from "react-icons/bs";
import Wizard from "../../Components/Wizard";

import './Forms.scss';
import '../css/Declarations.scss';
import '../vs-mobil/Forms.scss';
// import axios from "axios";
import { api } from '../../index';
  
function AppelCotis({data,setAssureSelected}) {
    
      const [Salary,setSalary]=useState("");
      const [HcmPositionType,setHcmPositionType]=useState("");
      const [RegimeId,setRegimeId]=useState("");
      const [ValidFrom,setValidFrom]=useState("");
      const [ValidTo,setValidTo]=useState("");
      const [EmployeeExternalNum,setEmployeeExternalNum]=useState("");
      const [validated, setValidated] = useState(false);
      const [success, setsuccess] = useState(false);
      const [Errors, setErrors] = useState("");

    const [FunctionList, setFunctionList] = useState([]);
    const [ListRegimeId , setListRegimeId ] = useState([]);
    useEffect(() => {

        api.get('positionTypes').then(async (response) => {
            setFunctionList(response.data.data);
        });
        api.get('regimes').then(async (response) => {
            setListRegimeId(response.data.data);
        });

    }, [])
    
      const items = JSON.parse(localStorage.getItem('data'));

      const addsalary = (event) => {
      const form = event.currentTarget;
          if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
          }
          setValidated(true);
          api.post('vendor/addvendorrelation', {
              VendorAccountNumber : data.VendorAccountNumber,CustomerAccount :items.user["SSMCustomerAccount"],Salary,ValidFrom,EmployeeExternalNum,HcmPositionType,ValidTo,RegimeId
          }).then(async (response) => { 
            if(response.data.data){
                // window.location.href="/Accueil"
                setsuccess(true);
            }
          }, (error) => {
              setErrors(error.response.data.data.error.Salary[0]);
          });
      
      }
  
  return (
    <>
  
         <Form noValidate validated={validated}  className={success?"hide":"mt-4 left-container"}>
                    <Alert  variant="danger" className={Errors ? "" : "hide"}>
                            {Errors}
                    </Alert>
                    <Row className="form-row">
                            <Col sm={12} md={6} className="m-0">
                                <Card className="salary-card">
                                    <Card.Body className="m-0">
                                        <Card.Text className="text-muted">Nom : <span className="c-primary-1 f-w"> {data? data.VendorOrganizationName :"-" }</span></Card.Text>
                                        <Card.Text className="text-muted">Matricule : <span className="c-primary-1 f-w"> {data? data.VendorAccountNumber :"-" }</span></Card.Text>
                                        <Card.Text className="text-muted">Piéce d identité : <span className="c-primary-1 f-w"> {data? data.SSMIdentificationDocumentValue :"-" }</span></Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group  controlId="formBasicEmail">
                                            <FloatingLabel controlId="floatingPassword" label="Salaire" className="uppercase mb-3">
                                                <Form.Control  type="text" onChange={(e) => setSalary(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                 </Form.Control.Feedback>
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3">
                                            <FloatingLabel  label="Fonction" className="uppercase mb-3">
                                                <Form.Select onChange={(e) => setHcmPositionType(e.target.value)} required>
                                                        <option></option>
                                                        {FunctionList.map((index)=> (
                                                           <option value={index.PositionTypeId}>{index.Description !="" ? index.Description : index.PositionTypeId}</option>
                                                        ))}  
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                 </Form.Control.Feedback>
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3">
                                            <FloatingLabel  label="RG" className="uppercase mb-3">
                                                <Form.Select onChange={(e) => setRegimeId(e.target.value)} required>
                                                        <option></option>
                                                        {ListRegimeId.map((index)=> (
                                                            <option value={index.RegimeId}>{index.Description}</option>
                                                        ))}  
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                 </Form.Control.Feedback>
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group  controlId="formBasicEmail">
                                            <FloatingLabel controlId="floatingPassword" label="Date d'embauche" className="uppercase mb-3" >
                                                <Form.Control  type="date" onChange={(e) => setValidFrom(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">
                                                            ce champ est obligatoire
                                                 </Form.Control.Feedback>
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group  controlId="formBasicEmail">
                                            <FloatingLabel controlId="floatingPassword" label="Date de débauche" className="uppercase mb-3" >
                                                <Form.Control  type="date" onChange={(e) => setValidTo(e.target.value)}  />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group  controlId="formBasicEmail">
                                            <FloatingLabel controlId="floatingPassword" label="Matricule interne" className="uppercase mb-3">
                                                <Form.Control  type="text" onChange={(e) => setEmployeeExternalNum(e.target.value)}  />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col> 
                    </Row>
                   
                    <Button variant="success" onClick={addsalary} className="succes mt-4 uppercase  xs align-right br-30">
                          <img variant="top" src="./assets/verify-white.svg" style={{paddingRight:5}} />   Rajouter dans la liste 
                    </Button>
            </Form>
            <div className={success?"right-grid align-center pt-2 mt-4":"hide"}>
                        <h1 className="subtitle-form sm"> Votre assure  est ajouté avec succès.</h1>
                        <img src="./assets/success.svg" className="mt-4" />
            </div>
             
                     
    </>
  );
}
export default AppelCotis;