import React from "react";
import { Button, Container, Form, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import './Header.scss';

function Header() {

    return (
         <>
            <Navbar className="top-header justify-content-center"  expand="lg" variant="light">
                <Container className="nav-container xs">
                    <Navbar.Brand href="#home" className="m-0"></Navbar.Brand>
                    <Nav className="justify-content-end m-0">
                        <Nav.Link href="#home">La CNSS</Nav.Link>
                        <Nav.Link href="#features">Actualites</Nav.Link>
                        <Nav.Link href="#pricing">Structures</Nav.Link>   
                    </Nav>
                </Container>
            </Navbar>
            <Navbar className="header justify-content-center" expand="lg"  variant="white" >
                <Container className="nav-container mobil  bg-white sm" fluid>
                
                <Navbar.Toggle aria-controls="navbarScroll" className="m-0" />
                
                <Navbar.Collapse id="navbarScroll">
                <Navbar.Brand href="#home"><img  src="./assets/cnss-logo.svg" width="100%" />  </Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link href="#home">La CNSS</Nav.Link>
                        <Nav.Link href="#features">Actualites</Nav.Link>
                        <Nav.Link href="#pricing">Structures</Nav.Link>
                        <Nav.Link href="#features">Espaces</Nav.Link>
                        <Nav.Link href="#pricing">Assurance Maladie Universelle</Nav.Link>
                    </Nav>
                    <Nav.Link href="/home" className="nav-btn"> TELECHARGEMENT +</Nav.Link>
                </Navbar.Collapse>
                </Container>
            </Navbar>
            
         </>
     
    );
  }


export default Header;