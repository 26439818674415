import React, { useState } from "react";
import { Button, Container, Nav, Navbar, Tab, Tabs } from 'react-bootstrap';
import './Header.scss';

function VerticalHeader() {
   
        const tabs= [    
          {name:"Accueil" ,title:"Accueil",path:"/Accueil",icon: "./assets/home-gray.svg",iconHover: "./assets/home-orange.svg"},
          {name:"Employeur" , title:"Ma fiche employeur",path:"/Employeur",icon: "./assets/employ-gray.svg",iconHover: "./assets/employ-orange.svg"},
          {name:"declarations" , title:"Mes déclarations",path:"/declarations",icon: "./assets/declaration-gray.svg",iconHover: "./assets/declaration-orange.svg"},
          {name:"demandes" , title:"Mes demandes",path:"/demandes",icon: "./assets/demande-gray.svg",iconHover: "./assets/demande-orange.svg"},
          {name:"paiements" , title:"Mes paiements",path:"/paiements",icon: "./assets/payment-gray.svg",iconHover: "./assets/payment-orange.svg"},
          {name:"salaires" , title:"Mes salariés",path:"/salaires",icon: "./assets/salary-gray.svg",iconHover: "./assets/salary-orange.svg"},
          {name:"configurations" , title:"Mes configurations",path:"/configurations",icon: "./assets/setting-gray.svg",iconHover: "./assets/setting-orange.svg"},
        ]
        const Logout = () => {
             localStorage.clear();
             window.location.href="/"
         };

         const link = window.location.href;
         const name_url = link.substr(link.lastIndexOf('/') +1);


    return (
         <>

                         <Nav variant="pills" className="vertical-header flex-column m-0 p-2 br-15"  >
                              <img  src="./assets/logo-white.svg" width="100%" className="mb-2 mt-4 p-2" /> 
                              {tabs.map((data)=> (
                                   <Nav.Link eventKey={data.id} href={data.path}
                                   className={name_url === data.name ? " tabhead active" : ""}
                                   >
                                        <img className="icon"  variant="top"  src={data.icon} />
                                        <img  className="iconHover"  src={data.iconHover} />
                                        {data.title}
                                   </Nav.Link>
                                   ))}
                                    <Nav.Link onClick={Logout} className="reverse last" >
                                        <img className="icon"  variant="top"  src="./assets/logout-gray.svg" />
                                        <img  className="iconHover"  src="./assets/logout-hover.svg" />
                                        Se déconnecter
                                   </Nav.Link>
                         </Nav>
                  
                                        
         </>
     
    );
  }


export default VerticalHeader;