// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .card-content.mobil .row > div {
    padding-left: 5px;
    padding-right: 5px;
  }
  .toggle {
    width: 21%;
    margin-bottom: 0px;
  }
  .right-container {
    border: unset;
  }
  .switch {
    margin-bottom: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/vs-mobil/Forms.scss"],"names":[],"mappings":"AAAA;EAEA;IACI,iBAAA;IACA,kBAAA;EAAF;EAEF;IACI,UAAA;IACA,kBAAA;EAAF;EAEF;IACI,aAAA;EAAF;EAEF;IACI,kBAAA;EAAF;AACF","sourcesContent":["@media only screen and (max-width: 600px) {\n\n.card-content.mobil .row > div  {\n    padding-left: 5px;\n    padding-right: 5px;\n}\n.toggle{\n    width: 21%;\n    margin-bottom: 0px;\n}\n.right-container{\n    border:unset;\n}\n.switch{\n    margin-bottom:0px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
