import React, { useState } from "react";

import './Wizard.scss';
  
function Wizard({pending,valideted,Status}) {
  return (
    <>
        <div className="wizard-progress">
            <div className={`step in-progress ${Status === "Created" || Status === "WaitingPortalApproval" || 
                 Status === "Canceled" || Status === "Close" || Status === "Declared" || Status === "InDelay"  ?
             valideted : "pending"}`}>
                 <span className={`title-wizard ${Status === "Created" || Status === "WaitingPortalApproval" || 
                 Status === "Canceled" || Status === "Close" || Status === "Declared" || Status === "InDelay" ? valideted 
                 : "pending"}`}>Créé</span>  
                 <div className={`node ${Status === "Created" || Status === "WaitingPortalApproval" || 
                 Status === "Canceled" || Status === "Close" || Status === "Declared" || Status === "InDelay" ? valideted : "pending"}`}> 
                 </div>
            </div>
            <div className={`step in-progress ${Status === "WaitingPortalApproval"
             || Status === "Canceled" || Status === "Close" || Status === "Declared"   
              ? valideted : "pending"}`}>

                 <span className={`title-wizard ${Status === "WaitingPortalApproval" || 
                 Status === "Canceled" || Status === "Close" || Status === "Declared"    ?
                  valideted : "pending"}`}> En attente  </span>

                <div className={`node  ${Status === "WaitingPortalApproval" || Status === "Canceled" || Status === "Close"
                 || Status === "Declared"   
                 ? valideted : "pending"}`}>

                 </div>
            </div>
            <div className={`step in-progress ${Status === "Canceled" || Status === "Close"
                 || Status === "Declared"    
              ? valideted : "pending"}`}>
                 <span className={`title-wizard ${Status === "Canceled" || Status === "Close"
                 || Status === "Declared"    
              ? valideted : "pending"}`}>Validé</span> 
                <div className={`node ${Status === "Canceled" || Status === "Close"
                 || Status === "Declared"    
              ? valideted : "pending"}`}></div>
            </div>
        </div>
    </>
  );
}
export default Wizard;