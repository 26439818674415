import { Outlet, Navigate } from 'react-router-dom'
const Logged = () => {

    const token = localStorage.getItem('smmToken');
    const expirationDate = localStorage.getItem('expirationDate');

    return(
      
        (!token || !expirationDate || expirationDate < Math.floor(Date.now() / 1000)) ? <Outlet /> : <Navigate to='/Accueil'/>
      
    )
}
export default Logged