import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Tab, Tabs } from "react-bootstrap";
import { BsCheck, BsTrashFill } from "react-icons/bs";
import Wizard from "../../Components/Wizard";
import '../Forms/Forms.scss';
import '../vs-mobil/Forms.scss';
// import axios from "axios";
import { api } from '../../index';
  
function ImmatriculationView({RegistrationRowSelected}) {
    const [BirthPlaceId, setBirthPlaceId] = useState("");
    const [DocumentTypeId, setDocumentTypeId] = useState("");
    const [RegimeId,setRegimeId]=useState("");
    const [Function, setFunction] = useState("");

    const items = JSON.parse(localStorage.getItem('data'));
    const situation = {
        "Single" : "Célibataire",
        "Married" : "Marié(e)",
        "Divorced" : "Divorcé(e)",
        "Widowhood" : "Veuf (ve)"
    }
    useEffect(() => {

        api.get('positionTypes').then(async (response) => {
            response.data.data.forEach(index => {
                if (index.PositionTypeId == RegistrationRowSelected.Function) {
                    setFunction(index.Description)
                }
            })
        });
        api.get('birthPlaces').then(async (response) => {
            response.data.data.forEach(index => {
                if (index.BirthPlaceId == RegistrationRowSelected.BirthPlaceId) {
                    setBirthPlaceId(index.Description)
                }
            })
        });
        api.get('documentTypes').then(async (response) => {
            response.data.data.forEach(index => {
                if (index.DocumentTypeId == RegistrationRowSelected.DocumentTypeId) {
                    setDocumentTypeId(index.Description)
                }
            })
        });
        api.get('regimes').then(async (response) => {
            response.data.data.forEach(index => {
                if (index.RegimeId == RegistrationRowSelected.RegimeId) {
                    setRegimeId(index.Description)
                }
            })
        });

    }, []);

    return (
            <>
  
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 mt-4 Employeur-tabs w-100"
                    >
                        <Tab eventKey="home" title="immatriculation" >
                            <div>
                                <Form  className="mt-4" >
                                    <Row className="form-row">
                                        <h5 className="mb-2 c-primary">Informations personnelles </h5>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Nom" className="uppercase">
                                                    <Form.Control value={RegistrationRowSelected.Name ?  RegistrationRowSelected.Name : ""} type="text"  disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Nom de la mère " className="uppercase">
                                                    <Form.Control  type="text" value = {RegistrationRowSelected.MotherName ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Date de naissance " className="uppercase">
                                                    <Form.Control  type="text" value = {RegistrationRowSelected.BirthDate ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Lieu de naissance" className="uppercase">
                                                    <Form.Control  type="text" value = {BirthPlaceId} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Genre" className="uppercase">
                                                    <Form.Control  type="text" value = {(RegistrationRowSelected.Gender == "Male") ? "Homme" : "Femme"} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Nationalité" className="uppercase">
                                                    <Form.Control  type="text" value = {RegistrationRowSelected.Nationality ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Situation familiale" className="uppercase">
                                                    <Form.Control  type="text" value = {situation[RegistrationRowSelected.PersonMaritalStatus]} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <h5 className="mb-2 c-primary">Identité</h5>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Type de pièce d’identité " className="uppercase">
                                                    <Form.Control  type="text" value = {DocumentTypeId} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Numéro de la pièce d’identité " className="uppercase">
                                                    <Form.Control  type="text" value = {RegistrationRowSelected.DocumentNumber ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <h5 className="mb-2 c-primary">Contact</h5>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Adresse" className="uppercase">
                                                    <Form.Control  type="text" value = {RegistrationRowSelected.Address ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Ville" className="uppercase">
                                                     <Form.Control  type="text" value = {RegistrationRowSelected.City ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Téléphone " className="uppercase">
                                                    <Form.Control  type="text"  value = {RegistrationRowSelected.Phone ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="E-mail" className="uppercase">
                                                    <Form.Control  type="text" value = {RegistrationRowSelected.Email ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <h5 className="mb-2 c-primary">Informations d'embauche</h5>
                                        <Col sm={12} md={6}  className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Matricule externe" className="uppercase">
                                                    <Form.Control  type="text" value = {RegistrationRowSelected.EmployeeExternalNum ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                        <Form.Group className="mb-3">
                                            <FloatingLabel  label="Régime" className="uppercase">
                                                <Form.Control  type="text" value = {RegimeId} disabled  className="bg-light"/>                                            
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Date d’embauche" className="uppercase">
                                                    <Form.Control  type="text" value = {RegistrationRowSelected.HiringDate ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Salaire" className="uppercase">
                                                    <Form.Control  type="text" value = {RegistrationRowSelected.Salary ?? ""} disabled  className="bg-light"/>
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Fonction" className="uppercase">
                                                    <Form.Control  type="text" value = {Function} disabled  className="bg-light"/>                                                   
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                       
                                    </Row>
                                    <Row className="form-row">
                                        <Col sm={12} md={6} className="m-0">
                                            <Form.Group className="mb-3">
                                                <FloatingLabel  label="Motif du rejet" className="uppercase">
                                                    <Form.Control  as="textarea" value = {RegistrationRowSelected.Notes} disabled  className="bg-light textArea-height"/>                                                   
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                   
                                </Form>
                            </div>
                        </Tab>
                    </Tabs>
            </>
    );
}
export default ImmatriculationView;