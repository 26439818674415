import React, { useEffect, useState } from "react";
import { Alert, Button, Col, FloatingLabel, Form, Row, Tab, Table, Tabs } from "react-bootstrap";

import './Forms.scss';
import '../vs-mobil/Forms.scss';
import { BsTrashFill } from "react-icons/bs";
// import axios from "axios";
import { api } from '../../index';
  
function AddPayment({notPayedList, showSuccess, setShowSuccess}) {
    const [PaymDate, setPaymDate] = useState();
    const [PaymAmount, setPaymAmount] = useState();
    const [DeclarationData, setDeclarationData] = useState([]);
    const [ListAP, setListAP] = useState([]);
    const [Errors, setErrors] = useState("");

    const items = JSON.parse(localStorage.getItem('data'));
    useEffect(() => {
        api.get(`customer/${items.user.customer["CustomerAccount"]}/paymentcontributioncalls`).then(async (response) => {
            let assures = response.data.data;
            let DeclarationArray = [];

                assures.forEach(index => {
                    if(notPayedList){
                        if (notPayedList.includes(index["ContributionCallId"])) {
                            DeclarationArray.push(index)
                        } 
                    }else if (index["PaymentStatus"] === "NotPayed") {
                        DeclarationArray.push(index)
                    }    
                })
            setDeclarationData(DeclarationArray)  
        })
      },[]);

    const SelectItem = (event) => { 

        let arr = ListAP;
        var element = document.getElementsByName('chk');  
        var all = document.getElementById('all');
        if (event.target.id == "all") {
                for(var i=0; i<element.length; i++){  
                    if(element[i].type == 'checkbox')  {
                        if(all.checked){
                            element[i].checked=true; 
                            setListAP(notPayedList);
                        }else if(!all.checked){
                            element[i].checked=false; 
                            setListAP([]);
                        }
                    }  
                } 
        }else {
            if (event.target.checked) {
                    arr.push(event.target.value);
                }else{
                    all.checked=false;
                    const index = arr.indexOf(event.target.value);
                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                }
                setListAP(arr);
        }
    };

    const addPayment =()=>{
        if(ListAP.length > 0 ){
            api.post(`payment/add`,{
                CustomerAccount: items.user.customer["CustomerAccount"],PaymDate,PaymAmount,contributionCallIds : ListAP
            }).then(async (response) => {
                if(response.data.success){
                    // setSuccess(true);
                    setShowSuccess(true);
                }
            }, (error) => {
                setErrors(error.response.data.data.error);
            });
        }else{
             setErrors("Veuillez sélectionner au moins un AP")
        }
           

    }



  return (
    <>
  
                <Form  className={showSuccess?"hide":"padding-0"}>
                        <Alert  variant="danger" className={Errors ? "" : "hide"}>
                               {Errors}
                        </Alert>
                        <Row className="form-row">
                            <Col className="mb-2" sm={12} md={6}>
                                <Form.Group className="mb-3 input-mobil">
                                    <FloatingLabel  label="Montant du paiement  *" className="uppercase">
                                        <Form.Control  type="text"  className="bg-white" onChange={(e) => setPaymAmount(e.target.value)} required />
                                    </FloatingLabel> 
                                </Form.Group>
                            </Col>
                            <Col className="mb-2" sm={12} md={6}>
                                <Form.Group className="mb-3 input-mobil">
                                    <FloatingLabel  label="Date de paiement *" className="uppercase">
                                        <Form.Control  type="date"   className="bg-light" onChange={(e) => setPaymDate(e.target.value)} required/>
                                    </FloatingLabel> 
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-2  scrol-x-t" >
                            <Table className="table-adress mt-2 table-scroll declarations small-first-col" hover >
                                    <thead>
                                        <tr>
                                            <th>
                                            <div>
                                                <input type="checkbox" id="all" onChange={SelectItem}  className="check-input" />
                                            </div>
                                            </th>
                                            <th width="25%" >Appel de cotisation </th>
                                            <th>Période  </th>
                                            <th >Régime  </th>
                                            <th >Montant   </th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody className="body-half-screen fix-height">
                                        <div>
                                            {DeclarationData.map((data,i)=> (
                                                <tr>
                                                        <td>
                                                        <div>
                                                            <input type="checkbox" name="chk" value={data["ContributionCallId"]} data-ap={data["ContributionCallId"]} onChange={SelectItem} className="check-input" />
                                                        </div>
                                                        </td>
                                                        <td> {data.ContributionCallId}</td>
                                                        <td>{data.SSMCalendarPeriod_Name}</td>
                                                        <td>{data.SSMRegime_RegimeId}</td>
                                                        <td>{data.Total}</td>
                                                </tr>
                                            ))}
                                        </div>
                                    
                                    </tbody>
                            </Table>
                        </Row>
                        <Button variant="success"  onClick={addPayment} className="succes mt-4 uppercase xs align-right br-30">
                             <img variant="top" src="./assets/verify-white.svg" style={{paddingRight:5}} />  Valider 
                        </Button>
                </Form> 
                <div className={showSuccess?"right-grid success-alert align-center mt-4":"hide"}>
                        <h1 className="subtitle-form sm"> Votre paiement est ajouté avec succès.</h1>
                        <img src="./assets/success.svg" className="mt-4" />
                 </div>
                         
    </>
  );
}
export default AddPayment;